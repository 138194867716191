import { API_BASE_URL } from 'config'
/* eslint-disable max-len */
import { AxiosResponse } from 'axios'
import { http } from 'lib/http'
import { useCallback, useMemo } from 'react'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { ApiResponce, InfinityNewType, PaginationType2 } from 'types'
import { RootState, store } from 'store'

type ApiTypeForm = 'mainWeb' | 'crm' | 'chat'

type UseFetchPageRes<T> = {
 data?: InfiniteData<InfinityNewType<T>>
 isLoading: boolean
 fetchMore: () => void
 hasMore?: boolean
 refetch: () => void
 isRefetching?: boolean
 flatData?: T[]
}

type UseFetchPageProps = {
 params?: string
 body?: any
 method?: 'get' | 'post'
 enable?: boolean
 from?: ApiTypeForm
 baseURL?: string
}

const spllitUrl = (val: string): any => val.split('page=')?.[1]

// const getBaseApiUrl = (type?: ApiTypeForm) => {
//  switch (type) {
//   case 'mainWeb':
//    return `${LISTING_API}`
//   default:
//    return `${API_BASE_URL}/user`
//  }
// }

const fetchData = async (
 props: UseFetchPageProps & { url: string },
): Promise<InfinityNewType<any>> => {
 try {
  const { data }: AxiosResponse<ApiResponce & PaginationType2<any>> =
   props.method === 'post'
    ? await http.post(`${props.url}`, props.body, {
       //  baseURL: getBaseApiUrl(props.from),
       baseURL: props?.baseURL,
      })
    : await http.get(`${props.url}`, {
       //  baseURL: getBaseApiUrl(props.from),
       baseURL: props?.baseURL,
      })
  if (data && (data.status === 'success' || data.success === true)) {
   return {
    data: data.data ?? [],
    next: data?.links?.next ? spllitUrl(data?.links.next) : undefined,
   }
  }
  return {
   data: [],
   next: undefined,
  }
 } catch (error) {
  return {
   data: [],
   next: undefined,
  }
 }
}

const useFetchPage2 = <T>(
 key: string,
 options?: UseFetchPageProps,
): UseFetchPageRes<T> => {
 const LISTING_URL = (store.getState() as RootState).global.listing_url
 const fetchDataAll = useCallback(
  (page?: any) =>
   fetchData({
    from: options?.from ?? 'crm',
    method: options?.method || 'get',
    url: `${key}?paginate=20&page=${page}&${options?.params}`,
    body: options?.body,
    baseURL:
     options?.from === 'mainWeb' ? `${LISTING_URL}` : `${API_BASE_URL}/user`,
   }),
  [key, options?.body, options?.from, options?.method, options?.params],
 )

 const { data, isLoading, fetchNextPage, hasNextPage, refetch, isRefetching } =
  useInfiniteQuery<InfinityNewType<T>>(
   [`${key}`, options?.body, options?.params],
   ({ pageParam = 1 }) => fetchDataAll(pageParam),
   {
    getNextPageParam: (lastPage) =>
     lastPage && lastPage.next && lastPage.next !== null
      ? lastPage.next
      : false,
    enabled: options?.enable,
   },
  )

 const flatData = useMemo(
  () => (data && data.pages ? data?.pages.map((v) => v.data).flat() : []),
  [data],
 )
 return {
  data,
  isLoading,
  fetchMore: fetchNextPage,
  hasMore: hasNextPage,
  refetch,
  isRefetching,
  flatData: flatData as T[],
 }
}

export default useFetchPage2

// import BaseInfinityTable from 'components/shared/DataTable/BaseDataTable'
// import ActionPlanActiveModal from 'components/shared/Modals/ActionPlanActiveModal'
import useFetchPage from 'hooks/useFetchPage'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useMemo, useState } from 'react'
import ControlActionPlanModal from 'components/shared/Modals/ControlActionPlanModal'
import { Modal, Checkbox } from 'antd'
// import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import BuilkActions from '../components/BuilkActions'
import TableColumns from '../components/TableColumns'
import { TActionPlanList } from '../types'
import Add from './Add'
import View from './View'
import Edit from './Edit'

export type TControlAPModal = {
 type: 'Pause' | 'Stop' | 'Completed' | 'Running' | null
 show: boolean
 id: any
}

const List: FC = () => {
 const [searchKey, setSearchKey] = useState('')
 //  const [selectedRows, setSelectedRow] = useState<TActionPlanList[]>()
 //  const [showActiveModal, setShowActiveModal] = useState({ show: false, id: [] })
 const [showModal, setShowModal] = useState<TControlAPModal>({
  type: null,
  show: false,
  id: null,
 })
 const [apActionModal, setApActionModal] = useState<{
  show: 'view' | 'edit' | 'add' | false
  id: null | number
 }>({ id: null, show: false })
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  totalCount,
  isRefetching,
 } = useFetchPage<TActionPlanList>('get-action-plans', {
  params: searchKey ? `search=${searchKey}` : '',
 })

 const {
  selectedKeys,
  //   handleBody,
  onSelectRow,
  rowSelectionState,
  selectedRows,
  selectAll,
  unSelected,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }))

 //  const selectedKeys: any[] = useMemo(
 //   () => (selectedRows ? selectedRows?.map((v) => v.id) : []),
 //   [selectedRows],
 //  )

 //  const [selectAll, setSelectAll] = useState(false)
 //  const [unSelected, setUnSelected] = useState<any[]>([])

 //  const toggleSelectAll = (e: CheckboxChangeEvent): void => {
 //   if (e?.target?.checked) {
 //    setSelectAll(true)
 //    setUnSelected([])
 //   } else {
 //    setSelectAll(false)
 //    setSelectedRow([])
 //    setUnSelected([])
 //   }
 //  }
 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 //  const headerCheckbox = (
 //   <Checkbox
 //    checked={selectAll || flatData?.length === selectedRows?.length}
 //    onChange={toggleSelectAll}
 //   />
 //  )
 const commonModalProps = {
  onCancel: () => setApActionModal({ show: false, id: null }),
  visible: true,
  width: '100vw',
  centered: true,
  className: 'page-size-modal',
 }

 return (
  <>
   <ListContentLayout
    title="Action Plans"
    actions={
     <BuilkActions
      onClickAdd={() => setApActionModal({ show: 'add', id: null })}
      actionRefresh={refetch}
      selectedRows={selectedRows}
      selectAll={selectAll}
      unSelected={unSelected}
      filterInfo={JSON.stringify({ search: searchKey })}
     />
    }
   >
    {/* <BaseInfinityTable
     search={{
      placeholder: 'Enter Action Plan Name',
      onSearch: setSearchKey,
      show: true,
      searchKey,
     }}
     rowSelection={{
      onChange: (va, r: TActionPlanList[]) => {
       setSelectedRow(r)
      },
      columnWidth: 60,
      type: 'checkbox',
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedKeys,
      columnTitle: headerCheckbox,
      onSelect: (row, check) => {
       if (selectAll) {
        setUnSelected((prv) => {
         let array = prv ?? []
         if (check === false) {
          array = [...array, row.id]
         } else {
          array = prv.filter((m) => m !== row.id)
         }
         return [...array]
        })
       }
      },
     }}
     loading={isLoading}
     hasMore={hasMore}
     columns={TableColumns({
      selectedKeys,
      selectAll,
      unSelected,
      handleClickEdit: (id?: any) => setApActionModal({ id, show: 'edit' }),
      handleClickView: (id?: any) => setApActionModal({ id, show: 'view' }),
      clickActiveModal: (id?: any) =>
       setShowModal({ id, show: true, type: 'Active' }),
      handleControlAP: (type: 'Pause' | 'Stop' | 'Completed', id?: any) => {
       setShowModal({ id, type, show: true })
      },
     })}
     onFetchMore={fetchMore}
     data={flatData ?? []}
    /> */}

    <MDataTable
     totalData={totalCount || 1000}
     search={{
      placeholder: 'Enter Action Plan Name',
      onSearch: setSearchKey,
      show: true,
     }}
     onSelectRow={onSelectRow}
     rowSelectionState={{
      ...rowSelectionState,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={flatData}
     columns={TableColumns({
      selectedKeys,
      selectAll,
      unSelected,
      handleClickEdit: (id?: any) => setApActionModal({ id, show: 'edit' }),
      handleClickView: (id?: any) => setApActionModal({ id, show: 'view' }),
      clickActiveModal: (id?: any) =>
       setShowModal({ id, show: true, type: 'Running' }),
      handleControlAP: (type: 'Pause' | 'Stop' | 'Completed', id?: any) => {
       setShowModal({ id, type, show: true })
      },
     })}
    />
    {/* {showModal?.type === 'Active' && (
     <ActionPlanActiveModal
      visible
      onCancel={() => setShowModal({ id: null, show: false, type: null })}
      Id={showModal?.id}
     />
    )} */}
    {showModal?.show && (
     <ControlActionPlanModal
      ctrlAP={showModal}
      onCancel={() => setShowModal({ show: false, type: null, id: null })}
      refetch={refetch}
     />
    )}
   </ListContentLayout>
   {apActionModal?.show === 'add' && (
    <Modal title="Add Action Plan" {...commonModalProps}>
     <Add onCancel={() => setApActionModal({ show: false, id: null })} />
    </Modal>
   )}
   {apActionModal?.show === 'view' && (
    <Modal title="Action Plan" {...commonModalProps}>
     <View id={`${apActionModal?.id}`} />
    </Modal>
   )}
   {apActionModal?.show === 'edit' && (
    <Modal title="Edit Action Plan" {...commonModalProps}>
     <Edit
      id={`${apActionModal?.id}`}
      onCancel={() => setApActionModal({ show: false, id: null })}
     />
    </Modal>
   )}
  </>
 )
}

export default List

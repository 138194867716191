/* eslint-disable react/require-default-props */
import { Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import React, { FC } from 'react'
import { FCC } from 'types'

type NewBaseButtonProps = ButtonProps & {
 tooltipText?: string
 buttonType?:
  | 'primary'
  | 'success'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'danger'
  | 'alpha'
  | 'beta'
  | 'gamma'
 buttonStyle?: 'solid' | 'outline'
 customClass?: string
}

export const BaseButton: FCC<NewBaseButtonProps> = ({
 tooltipText,
 children,
 buttonType,
 buttonStyle,
 customClass,
 ...rest
}) => (
 <Tooltip title={tooltipText}>
  <Button
   className={`${
    buttonStyle === 'outline'
     ? `ant-btn-outline-${
        buttonType === 'danger' ? 'danger2' : buttonType ?? ''
       } `
     : `ant-btn-${buttonType === 'danger' ? 'danger2' : buttonType} `
   } ${customClass}`}
   {...rest}
  >
   {children}
  </Button>
 </Tooltip>
)

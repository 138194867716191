import { Spin } from 'antd'
import { useGetEmailTemplateByIdQuery } from 'features/settings/api'
import EmailTemplateForm from 'features/settings/components/EmailTemplateForm'
// import InnerContentLayout from 'layout/InnerContentLayout'
import React, { FC } from 'react'
// import { useNavigate, useParams } from 'react-router'

const Edit: FC<{ id: string; onCancel?: () => void }> = ({ id, onCancel }) => {
 //  const navigate = useNavigate()
 //  const params = useParams()
 const { data, isFetching } = useGetEmailTemplateByIdQuery({ id })
 return (
  //   <InnerContentLayout title="Edit Email Template">
  <Spin spinning={isFetching}>
   <EmailTemplateForm
    // onSuccess={() => navigate('/admin/settings/email-templates/')}
    onSuccess={onCancel}
    editData={data}
    mode="EDIT"
   />
  </Spin>
  //   </InnerContentLayout>
 )
}

export default Edit

import { Space } from 'antd'
// import { ColumnsType } from 'antd/lib/table'
import { BaseButton } from 'components/elements/BaseButton'
import { TSSBlockList } from 'components/shared/Common'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { TAlertFeedType, TGetAlerts } from 'features/alert/types'
// import { TMode } from 'types'

type TAlertProps = {
 // filter?: TGetLeadFilter
 handleClickEdit?: (data?: TGetAlerts) => void
 //  handleClickView?: (id?: any) => void
 // sonFIlter?: (body: TLeadFilterBody) => void
 //  onClickLead?: (id?: any) => void
 type?: TAlertFeedType
}

const AlertTableColumns = ({
 handleClickEdit,
 type,
}: TAlertProps): MDataTableColumns<TGetAlerts>[] => [
 {
  title: 'Name',
  dataIndex: 'name',
  size: 200,
 },
 {
  title: 'Area',
  // dataIndex: 'area',
  size: 200,
  render: (_e, r) => <div>{r.area.join(',')}</div>,
 },
 {
  title: 'Frequency',
  dataIndex: 'frequency',
  size: 200,
 },
 {
  title: 'Tags',
  dataIndex: 'tags',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList typeId={r.id} mode="tag" type="alert" data={r.tags} closable />
  ),
 },
 {
  title: 'Stages',
  // dataIndex: 'stage',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    mode="stage"
    type="alert"
    data={r.stages}
    closable
   />
  ),
 },
 {
  title: 'Action',
  size: 70,
  fixed: 'right',
  render: (_, record) => (
   <Space>
    {/* <BaseButton
          onClick={() => handleClickView?.(record.id)}
          tooltipText="View Action Plan"
          shape="circle"
          size="small"
          buttonType="primary"
          icon={<i className="fas fa-eye" />}
        /> */}
    <BaseButton
     onClick={() => handleClickEdit?.(record)}
     tooltipText={
      type === 'forsale' ? 'Edit New Listing Alert' : 'Edit Neighbourhood Alert'
     }
     shape="circle"
     size="small"
     buttonType="info"
     icon={<i className="fas fa-edit" />}
    />
   </Space>
  ),
 },
]

export default AlertTableColumns

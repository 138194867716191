import { Col, Row } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm, globalToaster } from 'components/elements/global'
import { AddTaskModal } from 'components/shared/Modals'
import {
 leadApi,
 useDeleteLeadEventMutation,
 useTaskCompletedMutation,
} from 'features/leads/api'
import { TAddLeadEventBody, TTaskType } from 'features/leads/types'
import { useAppDispatch } from 'hooks'
import { FC, useState } from 'react'
import { TModalState } from 'types'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

const TasksBlock: FC<{
 tasks?: TTaskType[]
 leadId?: any
 isTransferred?: boolean
}> = ({ tasks, leadId, isTransferred }) => {
 const [showTaskModal, setShowTaskModal] = useState<
  TModalState<TAddLeadEventBody>
 >({
  show: false,
  mode: 'ADD',
 })

 const [taskCompleted] = useTaskCompletedMutation()

 const dispatch: any = useAppDispatch()

 const refreshLead = () => {
  dispatch(leadApi.util.invalidateTags(['LEAD']))
 }

 const [deleteLeadEvent] = useDeleteLeadEventMutation()

 const HandleCompleteTask = async (id: string[]) => {
  globalConfirm({
   content: 'Are you sure want to complete this task?',
   onOk: async () => {
    await taskCompleted({ id }).unwrap()
   },
  })
 }

 const HandleDeleteLeadTask = async (id?: any) => {
  globalConfirm({
   content: 'Are you sure you want to delete the task?',
   onOk: async () => {
    await deleteLeadEvent({ id, feed: 'task' }).unwrap()
    refreshLead()
   },
  })
 }
 return (
  <div>
   <ProInnerCard
    emptyText="Tasks is Empty"
    isEmpty={!tasks || tasks?.length === 0}
    onCLickAdd={
     isTransferred
      ? () => setShowTaskModal({ show: true, mode: 'ADD' })
      : undefined
    }
    title="Tasks"
   >
    <Row gutter={[15, 15]}>
     {tasks &&
      tasks.map((item, i) => (
       <Col span={24} key={i}>
        <SimpleBlockItem
         actions={
          isTransferred
           ? [
              item.status !== 'Done' && (
               <BaseButton
                key={1}
                buttonStyle="outline"
                onClick={() => {
                 setShowTaskModal({
                  show: true,
                  data: {
                   reminder: item.reminder,
                   feed: 'task',
                   type: item.type,
                   scheduled_at: item.scheduled_at,
                   date: item.date,
                   time: item.time,
                   title: item.name,
                   lead_id: item.lead_id,
                   id: item.id,
                  },
                  mode: 'EDIT',
                 })
                }}
                size="small"
                buttonType="primary"
                icon={<i className="fas fa-edit" />}
               />
              ),
              <BaseButton
               key={2}
               buttonStyle="outline"
               onClick={() => {
                if (item.status !== 'Done') {
                 HandleCompleteTask?.([item.id])
                } else {
                 globalToaster({
                  type: 'error',
                  content: 'Already Completed Task',
                 })
                }
               }}
               size="small"
               buttonType={item.status === 'Done' ? 'success' : 'danger'}
               icon={<i className="fas fa-check" />}
              />,
              <BaseButton
               key={3}
               buttonStyle="outline"
               onClick={() => HandleDeleteLeadTask?.(item.id)}
               size="small"
               buttonType="danger"
               icon={<i className="fas fa-trash" />}
              />,
             ]
           : undefined
         }
         text={item.name}
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   {showTaskModal.show && (
    <AddTaskModal
     editData={showTaskModal.data}
     mode={showTaskModal.mode}
     leadId={leadId}
     onCancel={() => setShowTaskModal({ show: false, mode: 'ADD' })}
     visible={showTaskModal.show}
    />
   )}
  </div>
 )
}

export default TasksBlock

import { useLazyRecentActivityDetailQuery } from 'features/leads/api'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TMainActivityTypes, TUserAllActivity } from 'features/leads/types'
import useFetchPageNew from 'hooks/useFetchpageNew'
import { FC } from 'react'
import ActivityLayout from './components/ActivityLayout'

export type TRecentAcitivityReq = {
 type?: TMainActivityTypes
 activity_id?: number
 lead_id?: number
}

const AllActivity: FC = () => {
 const { leadId } = useLeadDetails()

 const {
  flatData,
  isLoading,
  fetchMore,
  hasMore,
  isRefetching,
  refetch,
  total,
 } = useFetchPageNew<TUserAllActivity>(`lead/profile/${leadId}`, {
  body: { type: 'all' },
  method: 'post',
 })

 const [
  recentActivityDetail,
  { data: detailData, isLoading: detailLoading, isFetching: detailFetching },
 ] = useLazyRecentActivityDetailQuery()

 const fetchDetail = async (val: TRecentAcitivityReq & { index?: number }) => {
  //   delete val?.index
  const { index, lead_id, ...values } = val
  await recentActivityDetail({ ...values, lead_id: leadId }).unwrap()
 }

 return (
  <ActivityLayout
   refetechList={refetch}
   totalCount={total}
   totalTitle="ALL"
   detailLoading={detailFetching || detailLoading}
   fetchDetail={fetchDetail}
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => ({
    title: val?.title,
    description: val?.description,
    updated: val?.updated,
   })}
  />
 )
}

export default AllActivity

import { ItemType } from 'antd/lib/menu/hooks/useItems'
import {
 MyBrokerageIcon,
 SvgAlertIcon,
 SvgAutomationIcon,
 SvgCrmIcon,
 SvgNewListingIcon,
 SvgSettingsIcon,
} from 'components/icons'
import { APP_PREFIX_PATH } from 'config'

export type SidebarLinksType = ItemType

const SidebarLinks: SidebarLinksType[] = [
 {
  icon: <i className="fas fa-chart-line" />,
  key: `${APP_PREFIX_PATH}/dashboard`,
  label: 'Dashboard',
 },
 {
  icon: (
   <span>
    <SvgCrmIcon />
   </span>
  ),
  key: `${APP_PREFIX_PATH}/leads`,
  label: 'CRM',
 },
 {
  icon: (
   <span>
    <SvgNewListingIcon />
   </span>
  ),
  key: `${APP_PREFIX_PATH}/listing`,
  label: 'Listings',
  children: [
   {
    label: 'Property Listings',
    key: `${APP_PREFIX_PATH}/listing/property-listing`,
   },
   {
    label: 'Pre-Construction Listings',
    key: `${APP_PREFIX_PATH}/listing/pre-con-listing`,
   },
  ],
 },
 {
  icon: (
   <span>
    <SvgAlertIcon />
   </span>
  ),
  key: `${APP_PREFIX_PATH}/alert`,
  label: 'Alert',
  children: [
   {
    label: ' New Listing Alert',
    key: `${APP_PREFIX_PATH}/alert/new-listing`,
   },
   {
    label: 'Neighbourhood Alert',
    key: `${APP_PREFIX_PATH}/alert/sold-listing`,
   },
  ],
 },
 {
  icon: (
   <span>
    <SvgAutomationIcon />
   </span>
  ),
  key: `${APP_PREFIX_PATH}/action-plan`,
  label: 'Action Plan',
 },

 {
  icon: <i className="fas fa-users" />,
  label: 'My Realtors',
  key: `${APP_PREFIX_PATH}/realtors`,
 },
 {
  icon: (
   <span>
    <MyBrokerageIcon />
   </span>
  ),
  label: 'My Brokerage',
  key: `${APP_PREFIX_PATH}/my-brokerage`,
 },
 {
  icon: (
   <span>
    <SvgSettingsIcon />
   </span>
  ),
  key: `${APP_PREFIX_PATH}/settings`,
  label: 'Settings',
  children: [
   {
    label: 'Tags | Stage | Source',
    key: `${APP_PREFIX_PATH}/settings/tags-stages-sources`,
   },
   {
    label: 'Signature',
    key: `${APP_PREFIX_PATH}/settings/signature`,
   },
   {
    label: 'SMS Templates',
    key: `${APP_PREFIX_PATH}/settings/text-templates`,
   },
   {
    label: 'Email Templates',
    key: `${APP_PREFIX_PATH}/settings/email-templates`,
   },
   {
    label: 'Birthday Wishes',
    key: `${APP_PREFIX_PATH}/settings/birthday-wishes`,
   },
   {
    label: 'Listing Settings',
    key: `${APP_PREFIX_PATH}/settings/listing-settings`,
   },
  ],
 },
 {
  label: 'Integration',
  key: `${APP_PREFIX_PATH}/integration`,
  icon: <i className="fas fa-link" />,
 },
 {
  icon: <i className="fas fa-chart-pie" />,
  key: `${APP_PREFIX_PATH}/reports`,
  label: 'Report',
  children: [
   {
    label: 'Email Reports',
    key: `${APP_PREFIX_PATH}/reports/email-reports`,
   },
   {
    label: 'SMS Reports',
    key: `${APP_PREFIX_PATH}/reports/sms-reports`,
   },
  ],
 },
 {
  icon: <i className="fas fa-solid fa-globe" />,
  key: 'manage-website',
  label: 'Manage Website',
 },
]

export default SidebarLinks

import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import PreConListing from './PreConListing'
import ProListing from './ProListing'
import ProListingProvider from '../provider/ProListingProvider'

export const PropertyRoutes: FC = () => {
 const { pathname } = useLocation()
 return (
  <ProListingProvider
   from={
    pathname?.split('/')?.at(-1) === 'pre-con-listing'
     ? 'preconstruction'
     : 'listing'
   }
  >
   <Routes>
    <Route path="" element={<Navigate to="property-listing" />} />
    <Route path="property-listing" element={<ProListing />} />
    <Route path="pre-con-listing" element={<PreConListing />} />
   </Routes>
  </ProListingProvider>
 )
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Input, Modal, Space } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { BaseButton } from 'components/elements/BaseButton'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import {
 TActionPlanLeads,
 useControlActionPlanMutation,
 useWithdrawActionplanMutation,
} from 'features/action-plan'
import useFetchPage from 'hooks/useFetchPage'
import { queryClient } from 'lib/react-query'
import React, { FC, useMemo, useState } from 'react'
import { TControlAPModal } from 'features/action-plan/routes/List'
import { CheckAction } from './ActionPlanActiveModal'

const ControlActionPlanModal: FC<{
 onCancel?: () => void
 ctrlAP: TControlAPModal
 refetch?: () => void
}> = ({ onCancel, ctrlAP: { id, show, type }, refetch }) => {
 const [Ids, setIds] = useState<any[]>([])
 const [apActionType, setApActionType] = useState<'stop' | null>(null)

 const [searchKey, setSearchKey] = useState('')
 const { flatData, isLoading, hasMore, fetchMore } =
  useFetchPage<TActionPlanLeads>('get-action-plan-leads', {
   method: 'post',
   body: useMemo(
    () => ({
     id: [id],
     type: [type],
     search: searchKey,
    }),
    [id, searchKey],
   ),
  })

 const [controlAction, { isLoading: controlActionLoading }] =
  useControlActionPlanMutation()

 const [withdrawAP, { isLoading: withdrawAPLdng }] =
  useWithdrawActionplanMutation()
 const HandleControlAP = async (
  actionType: 'Resume' | 'Restart' | 'Pause' | 'Stop',
  planId?: any,
  leadId?: any[],
 ) => {
  await controlAction({
   action: actionType,
   plan_id: planId,
   lead_id: leadId,
  }).unwrap()
  queryClient.invalidateQueries('get-action-plan-leads')
  queryClient.invalidateQueries('get-action-plans')
  setApActionType(null)
  onCancel?.()
  refetch?.()
 }

 const handleDeleteAP = async (planId?: any, leadID?: any[]) => {
  await withdrawAP({
   actionplan_id: planId,
   lead_id: leadID,
  }).unwrap()

  queryClient.invalidateQueries('get-action-plan-leads')
  queryClient.invalidateQueries('get-action-plans')
  onCancel?.()
  refetch?.()
 }

 const title = {
  Running: 'Active',
  Pause: 'Paused',
  Completed: 'Completed',
  Stop: 'Stopped',
 }

 return (
  <Modal
   visible={show}
   onCancel={onCancel}
   footer={false}
   title={type ? title[type] : ''}
  >
   <div style={{ marginBottom: '12px' }}>
    <Input
     value={searchKey}
     onChange={(e) => setSearchKey(e.target.value)}
     placeholder="Enter your search"
    />
   </div>
   {isLoading && <BlockLoader />}
   <LoeadMorePagination
    loading={isLoading}
    hasMore={hasMore}
    fethMore={fetchMore}
   >
    {flatData &&
     flatData.length > 0 &&
     flatData.map((item, index) => (
      <AvatarStatus
       key={index}
       title={item.name}
       className="action-active-leads "
       action={
        <CheckAction
         onClick={() => {
          setIds((prv) => {
           const find = prv.find((m) => m === item.id)
           if (find) {
            const filter = prv.filter((x) => x !== item.id)
            return [...filter]
           }
           return [...prv, item.id]
          })
         }}
         checked={!!Ids.find((x) => x === item.id)}
        />
       }
      />
     ))}
   </LoeadMorePagination>
   {Ids && Ids.length > 0 && (
    <Space>
     <BaseButton
      buttonType="primary"
      loading={apActionType === null && controlActionLoading}
      onClick={() =>
       HandleControlAP?.(
        type === 'Running' ? 'Pause' : type === 'Pause' ? 'Resume' : 'Restart',
        id,
        Ids,
       )
      }
     >
      {type === 'Running' ? 'Pause' : type === 'Pause' ? 'Resume' : 'Restart'} (
      {Ids && Ids.length})
     </BaseButton>
     {type === 'Running' && (
      <BaseButton
       buttonType="primary"
       loading={apActionType === 'stop' && controlActionLoading}
       onClick={() => {
        setApActionType('stop')
        HandleControlAP?.('Stop', id, Ids)
       }}
      >
       Stop ({Ids && Ids.length})
      </BaseButton>
     )}
     <BaseButton
      loading={withdrawAPLdng}
      buttonType="danger"
      onClick={() => handleDeleteAP?.(id, Ids)}
     >
      Delete ({Ids && Ids.length})
     </BaseButton>
    </Space>
   )}
  </Modal>
 )
}

export default ControlActionPlanModal

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Modal } from 'antd'
import { BlockLoader } from 'components/elements/Loader'
import { TMainActivityTypes } from 'features/leads/types'
import ActivityEmail from './ActivityEmail'

export const buildDetailModal = (
 onCancel: () => void,
 showModal: boolean,
 type?: TMainActivityTypes,
 refetch?: () => void,
 loading?: boolean,
 data?: any,
) => {
 const modalBody = () => {
  switch (type) {
   case 'note':
    return <p>{data?.ncontent}</p>
   case 'text':
    return (
     <div style={{ display: 'flex' }}>
      <div
       dangerouslySetInnerHTML={{
        __html: `<span style="font-weight:bold;">Content : </span> ${data?.sbody}`,
       }}
      />
     </div>
    )
   case 'call':
    return data?.ccontent === null && Number(data?.cduration) === 0 ? (
     <h3>No Details Found ! !</h3>
    ) : (
     <div>
      {data?.cduration && Number(data.cduration) !== 0 && (
       <div>
        <audio controls>
         <track src={data?.crecord} kind="captions" />
        </audio>
       </div>
      )}
      {data?.ccontent !== null && <div>{data?.ccontent}</div>}
     </div>
    )
   case 'web':
    return (
     <div style={{ display: 'flex' }}>
      <div>
       <ul>
        {data?.web?.map((i: any, n: any) => (
         <div key={n}>
          {i?.wurl ? (
           <li style={{ marginBottom: '1em' }}>
            <a
             target="_blank"
             href={i?.wurl}
             rel="noreferrer"
             style={{ color: 'blue' }}
            >
             {i?.Wcontent}
            </a>
           </li>
          ) : (
           <li>
            <p>{i?.Wcontent}</p>
           </li>
          )}
         </div>
        ))}
       </ul>
      </div>
     </div>
    )
   case 'email':
    return <ActivityEmail onCancel={onCancel} refetch={refetch} data={data} />

   default:
    return ''
  }
 }
 return (
  <Modal
   width={1200}
   visible={showModal}
   onCancel={onCancel}
   footer={null}
   title={`${
    (type ?? '').charAt(0).toUpperCase() + (type ?? '').slice(1)
   } Preview`}
  >
   {' '}
   {loading ? <BlockLoader /> : modalBody()}
  </Modal>
 )
}

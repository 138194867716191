import { Col, Row } from 'antd'
import AssignActionPlan from 'components/shared/Modals/AssignActionPlan'
import { leadApi } from 'features/leads/api'
import { TPlanSimple } from 'features/leads/types'
import { useAppDispatch } from 'hooks'
import { FC, useState } from 'react'
import ActionPlanBlockItem from '../../ActionPlanBlockItem'
import ProInnerCard from '../../ProInnerCard'

const ActionPlanBlockBox: FC<{
 plans?: TPlanSimple[]
 leadId?: any
 isTransferred?: boolean
}> = ({ plans, leadId, isTransferred }) => {
 const [showActionPlan, setShowActionPlan] = useState<boolean>(false)
 const d = useAppDispatch()
 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))
 return (
  <div>
   <ProInnerCard
    emptyText="Action Plans is Empty"
    isEmpty={!plans || plans?.length === 0}
    onCLickAdd={isTransferred ? () => setShowActionPlan(true) : undefined}
    title="Action Plans"
   >
    <Row gutter={[15, 15]}>
     {plans &&
      plans.map((item, i) => (
       <Col span={24} key={i}>
        <ActionPlanBlockItem
         leadId={leadId}
         status={item.status}
         id={item.action_plan.id}
         text={`${item.action_plan.name} (${item?.action_plan?.owner})`}
         isTransferred={isTransferred}
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   {showActionPlan && (
    <AssignActionPlan
     onSuccess={refreshLead}
     activeLeadId={leadId}
     lead_id={[leadId]}
     onCancel={() => setShowActionPlan(false)}
     visible={showActionPlan}
    />
   )}
  </div>
 )
}

export default ActionPlanBlockBox

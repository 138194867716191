/* eslint-disable no-nested-ternary */
import { FC, ReactNode, useEffect, useState } from 'react'
import ListContentLayout from 'layout/ListContentLayout'
import ActionBlockRealtorDetail from 'features/realtors/components/ActionBlockRealtorDetail'
import RealtorDetailTail from 'features/realtors/components/RealtorDetailTail'
import useAuth from 'hooks/useAuth'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import { Dropdown, Menu, Checkbox } from 'antd'
import LeadOverlaySection from './LeadOverlaySection'
import { useLeadFilter } from '../slice/leadSlice'
import {
 TGetLeads,
 TLeadCustomSelectAll,
 TLeadFilterBody,
 TLeadTabType,
} from '../types'
import { useGetLoadFiltersQuery } from '../api'
import LeadTableColums from './tableColums'
import BuilkActionBlock from './BuilkActionBlock'
import LeadTileBlock from './LeadTileBlock'
import { convertLeadFilters, handleChangeLeadFilter } from '../functions'

type TLeadList = {
 leads?: TGetLeads[]
 isLoading: boolean
 isRefetching?: boolean
 refetch: () => void
 fetchMore: () => void
 hasMore?: boolean
 totalCount?: number
 title: ReactNode
 from: 'lead' | 'transfer-lead'
 realtorId?: string
 relatorName?: string
 selectedLeadsType?: 'all-leads' | 'my-leads'
}

const LeadList: FC<TLeadList> = ({
 leads,
 isLoading,
 refetch,
 fetchMore,
 hasMore,
 totalCount,
 title,
 from,
 realtorId,
 relatorName,
 selectedLeadsType,
 isRefetching,
}) => {
 const {
  filter,
  filterParams,
  updateLeadFilter,
  updateSearchKey,
  clearLeadFilter,
 } = useLeadFilter()
 const [leadIndex, setLeadIndex] = useState<number | null>(null)
 const [tileKey, setTileKey] = useState<TLeadTabType>('totallead')

 const { user } = useAuth()

 const { data: filterKeys } = useGetLoadFiltersQuery(null, {
  skip: !leads?.length,
 })

 const {
  onSelectRow,
  rowSelectionState,
  selectAll,
  unSelected,
  setSelectAll,
  setUnSelected,
  setSelectedRow,
  selectedRows,
  selectAllType,
  setSelectAllType,
 } = useSelectAll(filterParams)

 const clearSelectAll = () => {
  setSelectAll(false)
  setSelectedRow([])
  setUnSelected([])
  setSelectAllType(null)
 }

 //  console.log({ selectedRows })

 useEffect(() => {
  if (from === 'lead') {
   if (selectAllType !== null && leads && leads?.length > 0) {
    const newunSelected = new Set(unSelected)
    const result = leads.filter((obj: any, index) => {
     if (selectAllType === 'all') {
      return !newunSelected.has(obj.id)
     }
     if (['50', '100', '200', '400', '500', '1000']?.includes(selectAllType)) {
      return !newunSelected.has(obj.id) && index < Number(selectAllType)
     }
     return []
    })
    setSelectedRow(result)
   }
  } else if (from === 'transfer-lead') {
   if (selectAll && leads && leads?.length > 0) {
    const newunSelected = new Set(unSelected)
    const result = leads.filter((obj: any) => !newunSelected.has(obj.id))
    setSelectedRow(result as any)
   }
  }
 }, [leads, selectAllType, unSelected, selectAll])

 const commonActionBlockProps = {
  clearAllKeys: clearSelectAll,
  selectAll,
  unSelected,
  filterInfo: JSON.stringify(filterParams),
  selectedRows,
  totalCount,
  selectAllType,
  onRemoveSelectedKey: (id: any) => {
   setSelectedRow((prv) => {
    let oldArray = prv
    oldArray = oldArray?.filter((v) => v.id !== id)
    return [...(oldArray as any)]
   })
   if (selectAll) {
    setUnSelected((prv) => [...prv, id])
   }
  },
 }

 const onSelectDropDown = (v: TLeadCustomSelectAll) => {
  if (from === 'lead') {
   setUnSelected([])
   setSelectAllType(v)
   if (v === null) {
    setSelectedRow([])
   }
  }
 }

 return (
  <>
   <LeadOverlaySection
    leadIndex={leadIndex}
    setLeadIndex={setLeadIndex}
    fetchMore={fetchMore}
    hasMore={hasMore}
    leads={leads}
    isRealtor={from === 'transfer-lead'}
    refetchList={refetch}
   />
   <ListContentLayout
    bodyClass={from === 'lead' ? 'lead-list-body' : 'relator-body-height'}
    title={title}
    actions={
     from === 'lead' ? (
      <BuilkActionBlock
       hasMore={hasMore}
       leadRefresh={refetch}
       selectedLeadsType={selectedLeadsType}
       leads={leads}
       {...commonActionBlockProps}
      />
     ) : (
      <ActionBlockRealtorDetail
       realtorId={realtorId}
       onSuccess={refetch}
       hasMore={hasMore}
       {...commonActionBlockProps}
      />
     )
    }
   >
    {from !== 'lead' && (
     <RealtorDetailTail name={relatorName} id={`${realtorId}`} />
    )}
    <MDataTable
     currentFilter={convertLeadFilters(filter)}
     excludeResetButtonValues={[
      { id: 'email_address', value: 'All' },
      { id: 'phone_number', value: 'All' },
      { id: 'created_at', value: 'All' },
     ]}
     extraHeaderDom={
      from === 'lead' && (
       <LeadTileBlock
        selectedLeadsType={selectedLeadsType}
        onChangeTile={clearSelectAll}
        selectedKey={tileKey}
        setKey={setTileKey}
       />
      )
     }
     onReset={() => {
      setTileKey('totallead')
      clearLeadFilter()
     }}
     onChangeFilter={(v) => {
      if (v) {
       updateLeadFilter?.(handleChangeLeadFilter(v))
      }
     }}
     totalData={totalCount}
     search={{
      placeholder: 'Name | Phone | Email',
      onSearch: updateSearchKey,
      show: true,
     }}
     onSelectRow={(data: any, checked?: boolean): void => {
      if (from === 'transfer-lead') {
       onSelectRow(data, checked)
      } else if (from === 'lead') {
       if (selectAllType !== null) {
        setUnSelected((prv) => {
         let array = prv ?? []
         if (checked === false) {
          array = [...array, data?.id]
         } else {
          array = prv.filter((m) => m !== data?.id)
         }
         return [...array]
        })
       } else {
        setUnSelected([])
       }
      }
     }}
     rowSelectionState={{
      ...rowSelectionState,
      ...(from === 'lead' ? { dropDownValue: selectAllType } : {}),
      onSelectedAll:
       from === 'transfer-lead'
        ? (b) => {
           setUnSelected([])
           setSelectAll?.(b)
           if (b === false) {
            setSelectedRow([])
           }
          }
        : undefined,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={leads}
     columns={LeadTableColums({
      activeFilter: filter,
      onClickLead: (index) => setLeadIndex(index),
      filter: filterKeys,
      selectedLeadsType,
      account_type: user?.account_type,
      from,
      onSubmitCustomDate: ({ value, range }) => {
       const newData: TLeadFilterBody = {
        created: value,
       }
       newData.from_date =
        value === 'Custom' ? range?.from_date || '' : undefined
       newData.to_date = value === 'Custom' ? range?.to_date || '' : undefined
       updateLeadFilter?.(newData)
      },
      tileKey,
     })}
     selectAllCustomNode={
      <div className="">
       <Checkbox
        onChange={({ target }) => {
         if (from === 'lead') {
          onSelectDropDown(target?.checked ? 'all' : null)
         } else {
          setUnSelected([])
          setSelectAll(target?.checked)
          if (target?.checked === false) {
           setSelectedRow([])
          }
         }
        }}
        checked={from === 'lead' ? selectAllType === 'all' : selectAll}
        indeterminate={selectAllType !== null && selectAllType !== 'all'}
       />
       {from === 'lead' && (
        <Dropdown
         overlay={
          <Menu
           items={[
            ...((selectedRows?.length ?? 0) > 0
             ? [
                {
                 key: 'clear',
                 label: 'Clear',
                 onClick: () => clearSelectAll(),
                },
               ]
             : []),
            {
             key: 'all',
             label: 'Select All Leads',
             onClick: () => onSelectDropDown('all'),
            },

            //   ...[50, 100, 200, 400, 500, 1000]?.map((item, n) => {
            //    return []
            //   }),

            //   ...[50, 100, 200, 400, 500, 1000]?.map((item, n) =>
            //    (totalCount ?? 0) >= item
            //     ? [
            //        {
            //         key: '50',
            //         label: 'Select First 50 Leads',
            //         onClick: () => onSelectDropDown('50'),
            //        },
            //       ]
            //     : [],
            //   ),

            ...((totalCount ?? 0) >= 50
             ? [
                {
                 key: '50',
                 label: 'Select First 50 Leads',
                 onClick: () => onSelectDropDown('50'),
                },
               ]
             : []),
            ...((totalCount ?? 0) >= 100
             ? [
                {
                 key: '100',
                 label: 'Select First 100 Leads',
                 onClick: () => onSelectDropDown('100'),
                },
               ]
             : []),
            ...((totalCount ?? 0) >= 200
             ? [
                {
                 key: '200',
                 label: 'Select First 200 Leads',
                 onClick: () => onSelectDropDown('200'),
                },
               ]
             : []),
            ...((totalCount ?? 0) >= 400
             ? [
                {
                 key: '400',
                 label: 'Select First 400 Leads',
                 onClick: () => onSelectDropDown('400'),
                },
               ]
             : []),
            ...((totalCount ?? 0) >= 500
             ? [
                {
                 key: '500',
                 label: 'Select First 500 Leads',
                 onClick: () => onSelectDropDown('500'),
                },
               ]
             : []),
            ...((totalCount ?? 0) >= 1000
             ? [
                {
                 key: '1000',
                 label: 'Select First 1000 Leads',
                 onClick: () => onSelectDropDown('1000'),
                },
               ]
             : []),
           ]}
          />
         }
        >
         <i className="fas fa-chevron-down ps-1 table-select-all-arrow" />
        </Dropdown>
       )}
      </div>
     }
    />
   </ListContentLayout>
  </>
 )
}

export default LeadList

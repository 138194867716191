import { Col, Modal, Row } from 'antd'
import { BaseImage } from 'components/elements/Image'
import { TGetEmailTemplate } from 'features/settings'
import React, { FC } from 'react'

const EmailTemplateViewModal: FC<{
 visible?: boolean
 onCancel?: () => void
 data?: TGetEmailTemplate
}> = ({ visible, onCancel, data }) => (
 <Modal
  visible={visible}
  onCancel={onCancel}
  title="Email Template Preview"
  footer={false}
 >
  {data?.name && (
   <h3>
    <strong>Name: </strong>
    {data?.name}
   </h3>
  )}
  {data?.subject && (
   <h3>
    <strong>Subject: </strong>
    {data?.subject}
   </h3>
  )}
  {data?.body && <h3 dangerouslySetInnerHTML={{ __html: `${data?.body}` }} />}
  <Row gutter={[15, 25]}>
   {data?.attachment &&
    data.attachment.length > 0 &&
    data.attachment.map((item, index) => (
     <Col span={8} key={index}>
      <BaseImage src={item} ratio="1x1" />
     </Col>
    ))}
  </Row>
 </Modal>
)

export default EmailTemplateViewModal

import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import Navbar from 'components/layout-modules/Navbar/Navbar'
import Sidebar from 'components/layout-modules/Sidebar/Sidebar'
import { NoticationModule } from 'components/layout-modules/NoticationModule'
import { useGlobal } from 'raducer/global'
import { FCC } from 'types'
import SidebarLinks from 'static-data/SidebarLinks'
import { useProfileQuery } from 'features/auth'
import useAuth from 'hooks/useAuth'
import { useGlobalSettingsQuery } from 'api/baseApi'
import { FullLoader } from 'components/elements/Loader'
import OverlayContextWrapper from './OverlayContextWrapper'
import Footer from './Footer'
import AdminControlPanel from './AdminControlPanel'

const { Content } = Layout

const AdminLayout: FCC = () => {
 const { global, toggleNotification } = useGlobal()
 const { user } = useAuth()

 const { isLoading } = useProfileQuery()
 const { isLoading: globalLoading } = useGlobalSettingsQuery()

 if (isLoading || globalLoading) {
  return <FullLoader />
 }

 const ifListingKeyNull = (sidebar: any) => {
  if (global?.listing_key === null) {
   return sidebar
    .filter((item: any) => item?.label !== 'Listings')
    .map((it: any) => {
     if (it?.children) {
      it.children = it?.children?.filter?.(
       (child: any) => child.label !== 'Listing Settings',
      )
     }
     return it
    })
  }
  if (global?.listing_plan === 'Pre-Condo') {
   return sidebar.map((item: any) => {
    if (item?.label === 'Listings') {
     item.children = item?.children?.filter?.(
      (child: any) => child.label !== 'Property Listings',
     )
    }
    return item
   })
  }
  return sidebar
 }

 const withoutNeighbourhoodAlert = (sidebar: any) => {
  if (!global?.user_type_sold) {
   const updatedArray = sidebar.map((item: any) => {
    if (item?.label === 'Alert' && item?.children) {
     const filteredChildrens = item?.children?.filter(
      (child: any) => child?.label !== 'Neighbourhood Alert',
     )
     return { ...item, children: filteredChildrens }
    }
    return item
   })
   return updatedArray
  }
  return sidebar
 }

 const handleSideBar = () => {
  const { account_type } = user || {}
  const { user_type_sold, listing_plan } = global || {}
  let sideBar =
   listing_plan === 'Pre-Condo'
    ? SidebarLinks?.filter((i: any) => i?.label !== 'Alert')
    : SidebarLinks

  if (account_type === 'Brokerage' || account_type === 'Realtor') {
   sideBar = ifListingKeyNull(
    sideBar?.filter(
     (item: any) =>
      item?.label !==
      (account_type === 'Brokerage' ? 'My Brokerage' : 'My Realtors'),
    ),
   )
  }
  return !user_type_sold ? withoutNeighbourhoodAlert(sideBar) : sideBar
 }

 return (
  <Layout className="admin-layout" style={{ minHeight: '100vh' }}>
   <Sidebar sidebarLInk={handleSideBar()} />
   <Layout className="crm-wrapper-area">
    <Navbar />
    <Content className="crm-content-area">
     <OverlayContextWrapper />
     <Outlet />
    </Content>
    <Footer />
   </Layout>

   {global.notification && (
    <NoticationModule
     visible={global.notification}
     onCancel={() => toggleNotification()}
    />
   )}
   <AdminControlPanel />
  </Layout>
 )
}

export default AdminLayout

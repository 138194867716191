import { Space } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import { SvgDeleteIcon, SvgStageIcon, SvgTagsIcon } from 'components/icons'
import { AssignToLeadModal } from 'components/shared/Modals'
import AssignToSourceModal from 'components/shared/Modals/AssignToSourceModal'
import { FC, useMemo, useState } from 'react'
import AssignTagsStagesModal from 'components/shared/Modals/AssignTagsStagesModal'
import { TSelectAllP } from 'features/leads'
import useAuth from 'hooks/useAuth'
import { useDeleteBulkActionPlanMutation } from '../api'
import { TActionPlanList } from '../types'
import ShareAP from './ShareAP'

const BuilkActions: FC<
 TSelectAllP & {
  actionRefresh?: () => void
  selectedRows?: TActionPlanList[]
  onClickAdd?: () => void
 }
> = ({
 selectedRows,
 actionRefresh,
 onClickAdd,
 filterInfo,
 selectAll,
 unSelected,
}) => {
 const [deleteActionplans] = useDeleteBulkActionPlanMutation()
 const [showModal, setShowModal] = useState<
  'lead' | 'tag' | 'stage' | 'source' | 'share' | false
 >(false)
 const { user } = useAuth()
 const selectedKeys: any[] = useMemo(
  () => (selectedRows ? selectedRows?.map((v) => v.id) : []),
  [selectedRows],
 )

 const handleDeleteActionplan = async () => {
  globalConfirm({
   content: 'Are you sure want to delete selected items?',
   onOk: async () => {
    await deleteActionplans({ id: selectedKeys }).unwrap()
    actionRefresh?.()
   },
  })
 }

 const buttons = [
  {
   tooltipText: 'Add New',
   onClick: onClickAdd,
   buttonType: 'gamma',
   icon: <i className="far fa-plus" />,
   disabled: false,
  },
  {
   tooltipText: 'Assign to Lead',
   onClick: () => setShowModal('lead'),
   buttonType: 'alpha',
   icon: <i className="far fa-user" />,
  },
  {
   tooltipText: 'Assign to Tag',
   onClick: () => setShowModal('tag'),
   buttonType: 'alpha',
   icon: <SvgTagsIcon />,
  },
  {
   tooltipText: 'Assign to Stage',
   onClick: () => setShowModal('stage'),
   buttonType: 'alpha',
   icon: <SvgStageIcon />,
  },
  {
   tooltipText: 'Assign to Source',
   onClick: () => setShowModal('source'),
   buttonType: 'alpha',
   icon: <i className="far fa-book-spells" />,
  },
  ...(user?.account_type === 'Brokerage'
   ? [
      {
       tooltipText: 'Share',
       onClick: () => setShowModal('share'),
       buttonType: 'alpha',
       icon: <i className="far fa-share" />,
      },
     ]
   : []),
  {
   tooltipText: 'Delete',
   onClick: handleDeleteActionplan,
   buttonType: 'beta',
   icon: <SvgDeleteIcon />,
  },
 ]

 const commonModalProps = {
  onCancel: () => setShowModal(false),
  type: 'actionPlan',
  visible: true,
  selectedKeys,
  selectAll,
  unSelected,
  filterInfo,
 }

 return (
  <>
   <Space>
    {buttons?.map(({ disabled = !selectedKeys?.length, ...props }, i) => (
     <BaseButton key={i} disabled={disabled} {...(props as any)} />
    ))}
   </Space>

   {showModal === 'lead' && (
    <AssignToLeadModal {...(commonModalProps as any)} />
   )}

   {showModal === 'share' && <ShareAP {...(commonModalProps as any)} />}

   {(showModal === 'tag' || showModal === 'stage') && (
    <AssignTagsStagesModal
     refetch={actionRefresh}
     assignType={showModal}
     {...(commonModalProps as any)}
    />
   )}
   {showModal === 'source' && (
    <AssignToSourceModal
     refetch={actionRefresh}
     {...(commonModalProps as any)}
    />
   )}
  </>
 )
}

export default BuilkActions

import { createApi } from '@reduxjs/toolkit/query/react'
import { globalToaster } from 'components/elements/global'
import {
 ReadNotifyFromCache,
 removeNotificationFromCache,
} from 'components/layout-modules/NoticationModule/functions'
import { TSendTextBody } from 'features/leads'
import { queryClient } from 'lib/react-query'
import { ApiResponce, TDashboardResponce, TGlobalTypes, V1V2City } from 'types'
import axiosBaseQuery from './axiosBaseQuery'
import { secondAPI } from './secondApi'

export const baseTransformResponse = (resp: ApiResponce) => {
 if (resp.status === 'error') {
  globalToaster({ type: 'error', content: resp.message })
 } else {
  globalToaster({ type: 'success', content: resp.message })
 }
}

export const baseTransformResponse2 = (resp: ApiResponce): any => {
 if (resp.status === 'error') {
  globalToaster({ type: 'error', content: resp.message })
  return false
 }
 globalToaster({ type: 'success', content: resp.message })
 return resp.data
}
export const baseTransformResponse3 = (resp: ApiResponce): any => {
 if (resp.status === 'error') {
  globalToaster({ type: 'error', content: resp.message })
  return false
 }
 return resp.data
}

export const baseTransformResponse4 = (resp: ApiResponce): any => {
 if (resp.status === 'error') {
  globalToaster({ type: 'error', content: resp.message })
 }
}

export const baseAPI = createApi({
 reducerPath: 'baseApi',
 baseQuery: axiosBaseQuery(),
 tagTypes: [
  'LEAD',
  'ACTIONPLAN',
  'GROUP_LIST',
  'SIGNATURE',
  'EMAIL_TEMP',
  'TEXT_TEMP',
  'TRANSFERRED_LEAD_TILE',
  'LEAD-TILE',
 ],
 refetchOnMountOrArgChange: false,
 refetchOnFocus: false,
 endpoints: ({ query, mutation }) => ({
  GetProvince: query<string[], void>({
   query: () => ({
    url: 'user/get-province',
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),

  sendListingMail: mutation<void, any>({
   query: (body) => ({
    url: 'user/send-listing-mail',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendListingEmail: mutation<void, any>({
   query: (body) => ({
    url: 'user/send-listing-email',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendListingSms: mutation<void, TSendTextBody>({
   query: (body) => ({
    url: 'user/send-listing-sms',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  sendListingMessage: mutation<void, TSendTextBody>({
   query: (body) => ({
    url: 'user/send-listing-message',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  globalSettings: query<TGlobalTypes, void>({
   query: () => ({
    method: 'post',
    url: 'user/global-settings',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  getCityV1: query<V1V2City, void>({
   query: () => ({
    url: 'user/get-city-v1',
    method: 'get',
   }),
   transformResponse: (data: ApiResponce) => data.data,
  }),
  getCityV2: query<V1V2City, void>({
   query: () => ({
    url: 'user/get-city-v2',
    method: 'get',
   }),
   transformResponse: (data: ApiResponce) => data.data,
  }),

  readNotification: mutation<void, { id?: string }>({
   query: (body) => ({
    url: 'user/read-notification',
    method: 'POST',
    body,
   }),
   async onQueryStarted({ id }, { queryFulfilled }) {
    ReadNotifyFromCache(id)
    try {
     // eslint-disable-next-line no-empty-pattern
     const {} = await queryFulfilled
     queryClient.invalidateQueries('all-notifications')
    } catch {
     queryClient.invalidateQueries('all-notifications')
    }
   },
  }),
  deletenotification: mutation<void, { id?: any[] }>({
   query: (body) => ({
    url: 'user/delete-notification',
    method: 'POST',
    body,
   }),
   transformResponse: baseTransformResponse,
   async onQueryStarted({ id }, { queryFulfilled }) {
    removeNotificationFromCache(id)
    try {
     // eslint-disable-next-line no-empty-pattern
     const {} = await queryFulfilled
     queryClient.invalidateQueries('all-notifications')
    } catch {
     queryClient.invalidateQueries('all-notifications')
    }
   },
  }),
  getDashBoardData: query<TDashboardResponce, void>({
   query: () => ({
    url: 'user',
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  manageWebsite: mutation<any, void>({
   query: () => ({
    url: 'user/manage-website',
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => res,
  }),
 }),
})

export const {
 useGetProvinceQuery,
 useGetCityV1Query,
 useGetCityV2Query,
 useSendListingMailMutation,
 useSendListingEmailMutation,
 useSendListingSmsMutation,
 useSendListingMessageMutation,
 useGlobalSettingsQuery,
 useLazyGlobalSettingsQuery,
 useReadNotificationMutation,
 useDeletenotificationMutation,
 useGetDashBoardDataQuery,
 useManageWebsiteMutation,
} = baseAPI

export const baseSecondAPI = secondAPI.injectEndpoints({
 endpoints: ({ query }) => ({
  getCityList: query<string[], void>({
   query: () => ({
    url: 'api/get-city-list',
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
 }),
})

export const { useGetCityListQuery } = baseSecondAPI

import { Modal, Checkbox, Row, Col, Spin, Radio } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { useAssignToActionPlanBulkMutation } from 'features/action-plan'
import { useAssignAlertBulkMutation } from 'features/alert'
import {
 TLeadCustomSelectAll,
 TSelectAllB,
 TSelectAllP,
 useAssignGroupToLeadBulkMutation,
} from 'features/leads'
import { useGetGroupsQuery } from 'features/settings'
import TSSFormModal from 'features/settings/components/TSSFormModal'
import { FC, useState } from 'react'
import { capitalizeFirstLetter } from 'utils/functions'

type AddTagsProp = TSelectAllP & {
 type?: 'lead' | 'actionPlan' | 'alert'
 selectedKeys?: any[]
 visible: boolean
 onCancel: () => void
 refetch?: () => void
 assignType: 'tag' | 'stage'
 extraBody?: any
 customtSelectAll?: TLeadCustomSelectAll
}

const AssignTagsStagesModal: FC<AddTagsProp> = ({
 type = 'lead',
 visible,
 onCancel,
 selectedKeys,
 refetch: refresh,
 filterInfo,
 selectAll,
 unSelected,
 assignType,
 extraBody,
 customtSelectAll,
}) => {
 const { data, refetch, isLoading } = useGetGroupsQuery({ type: [assignType] })
 const [ids, setIds] = useState<string[]>([])
 const [showAdd, setAddModal] = useState(false)

 const [assignToActionPlanBulk, { isLoading: actionLoading }] =
  useAssignToActionPlanBulkMutation()
 const [assignAlertBulk, { isLoading: tagLoading }] =
  useAssignAlertBulkMutation()
 const [assignToLead, { isLoading: leadLoading }] =
  useAssignGroupToLeadBulkMutation()

 const handleClose = () => {
  onCancel?.()
  setIds([])
 }

 const handleSubmit = async () => {
  const body: TSelectAllB & {
   customtSelectAll?: TLeadCustomSelectAll
   type: 'tag' | 'stage'
  } = { type: assignType }
  if (selectAll || customtSelectAll) {
   if (selectAll) {
    body.select_all = selectAll
   } else if (customtSelectAll) {
    body.customtSelectAll = customtSelectAll
   }
   body.unselected = unSelected
   body.filter_info = filterInfo
  }

  if (type === 'actionPlan') {
   await assignToActionPlanBulk({
    ...body,
    type_id: ids,
    actionplan_id: selectAll || customtSelectAll ? [] : selectedKeys,
    ...extraBody,
   }).unwrap()
  } else if (type === 'alert') {
   await assignAlertBulk({
    ...body,
    type_id: ids,
    alert_id: selectAll || customtSelectAll ? [] : selectedKeys,
    ...extraBody,
   }).unwrap()
  } else {
   await assignToLead({
    ...body,
    group_id: ids,
    lead_id: selectAll || customtSelectAll ? [] : selectedKeys,
    ...extraBody,
   }).unwrap()
  }
  handleClose?.()
  refresh?.()
 }

 const options = assignType === 'stage' ? data?.stage : data?.tag
 const Component = assignType === 'stage' ? Radio.Group : Checkbox.Group

 return (
  <Modal
   forceRender
   confirmLoading={
    type === 'actionPlan'
     ? actionLoading
     : type === 'alert'
     ? tagLoading
     : leadLoading
   }
   okButtonProps={{ disabled: ids?.length === 0 }}
   onOk={handleSubmit}
   okText="Submit"
   onCancel={handleClose}
   visible={visible}
   title={`Assign ${capitalizeFirstLetter(assignType)}`}
  >
   <Spin className="w-100 text-center" spinning={isLoading}>
    {options && options?.length > 0 && (
     <Component
      value={assignType === 'tag' ? ids : (ids?.[0] as any)}
      style={{ width: '100%' }}
      onChange={(e: any) => {
       setIds(assignType === 'tag' ? e : [e?.target?.value])
      }}
     >
      <Row gutter={[10, 10]}>
       <Col span={24}>
        <BaseButton
         onClick={() => setAddModal(true)}
         block
         buttonType="primary"
        >
         <i className="fas fa-plus me-2" /> Add{' '}
         {capitalizeFirstLetter(assignType)}
        </BaseButton>
       </Col>
       {options?.map((v) => (
        <Col flex={1} key={v.id}>
         {assignType === 'tag' ? (
          <Checkbox className="btn-checkbox" value={v.id}>
           {/* {v.name} */}
           <span style={{ whiteSpace: 'normal' }}>{v.name}</span>
          </Checkbox>
         ) : (
          <Radio className="btn-checkbox" value={v.id}>
           {/* {v.name} */}
           <span style={{ whiteSpace: 'normal' }}>{v.name}</span>
          </Radio>
         )}
        </Col>
       ))}
      </Row>
     </Component>
    )}
   </Spin>

   {showAdd && (
    <TSSFormModal
     onSuccess={refetch}
     type={assignType}
     onCancel={() => setAddModal(false)}
     visible={showAdd}
    />
   )}
  </Modal>
 )
}

export default AssignTagsStagesModal

// import { ADMIN_PREFIX_PATH } from 'config'
import React, { FC, useContext, useMemo } from 'react'
// import { useParams } from 'react-router'
import { FCC } from 'types'

type LeadProviderType = {
 leadId?: any
 //  route?: string
 from?: 'lead' | 'other'
 isTransferred?: boolean
}

export const LeadDetailsContext = React.createContext<LeadProviderType>({})

const LeadDetailsProvider: FCC<LeadProviderType> = ({
 children,
 from,
 leadId,
 isTransferred,
}) => {
 const { Provider } = LeadDetailsContext

 return (
  <Provider
   value={{
    leadId,
    // route,
    from,
    isTransferred,
   }}
  >
   {children}
  </Provider>
 )
}

export default LeadDetailsProvider

export const useLeadDetails = () => useContext(LeadDetailsContext)

import { Col, Row } from 'antd'
import { FC } from 'react'
import { useParams } from 'react-router'

import { useTileForTransferredLeadsQuery } from '../api'

const RealtorDetailTail: FC<{ id: string; name?: string }> = ({ id, name }) => {
 //  const params = useParams()
 const { data } = useTileForTransferredLeadsQuery({
  realtor_id: id,
 })

 const { activeLeads, lead_count, totalemail, totalsms, user } = data || {}

 const valid = (v: any) => v !== undefined && v !== null && v !== 0

 return (
  <Row className="relator-detail-tail" style={{ minHeight: '75px' }}>
   <Col span={4} className="item">
    <div>
     <i className="fa fa-user me-2" /> {name ?? user}
    </div>
   </Col>
   {valid(lead_count) && (
    <Col span={4} className="item">
     {' '}
     <div>
      Lead Count
      <span className="count">{` - ${lead_count}`}</span>
     </div>
    </Col>
   )}
   {valid(totalemail) && (
    <Col span={4} className="item">
     <div>
      Total Email
      <span className="count">{` - ${totalemail}`}</span>
     </div>
    </Col>
   )}
   {valid(totalsms) && (
    <Col span={4} className="item">
     {' '}
     <div>
      Total SMS
      <span className="count">{` - ${totalsms}`}</span>
     </div>
    </Col>
   )}
   {valid(activeLeads) && (
    <Col span={4} className="item">
     <div>
      Active Leads
      <span className="count">{` - ${activeLeads}`}</span>
     </div>
    </Col>
   )}
  </Row>
 )
}

export default RealtorDetailTail

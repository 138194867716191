/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC } from 'react'
import { Menu, Dropdown, Layout, Badge, Row, Col } from 'antd'
import { BtnAnch, globalConfirm } from 'components/elements/global'
import useAuth from 'hooks/useAuth'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { useGlobal } from 'raducer/global'
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from 'config'
import { NavLink, useNavigate } from 'react-router-dom'
import {
 MyBrokerageIcon,
 SvgCalenderIcon,
 SvgInbox,
 SvgNotificationICon,
} from 'components/icons'
import { useLogoutMutation } from 'features/auth'
import style from './Navbar.module.scss'

const Navbar: FC = () => {
 const { toggleNotification, global } = useGlobal()
 const { user } = useAuth()
 const [logout] = useLogoutMutation()
 const navigate = useNavigate()

 const handlelogout = (): void => {
  globalConfirm({
   content: 'Are you sure you want to logout?',
   onOk: async () => {
    await logout().unwrap()
   },
  })
 }

 const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void,
  type?: 'group',
 ) => ({ key, label, icon, type, onClick })

 const items = [
  getItem('Profile', 'profile', <i className="fas fa-user" />, () =>
   navigate(`${ADMIN_PREFIX_PATH}/my-profile`),
  ),
  getItem('Signature', 'signature', <i className="fas fa-signature" />, () =>
   navigate(`${ADMIN_PREFIX_PATH}/settings/signature`),
  ),
  getItem('Reset Password', 'reset-pass', <i className="fas fa-key" />, () =>
   navigate(`${ADMIN_PREFIX_PATH}/settings/reset-password`),
  ),
  user?.account_type === 'Realtor'
   ? getItem(
      'My Brokerage',
      'my-brokerage',
      <div className={style.brokerageIcon}>
       <MyBrokerageIcon />
      </div>,
      () => navigate(`${APP_PREFIX_PATH}/my-brokerage`),
     )
   : getItem('My Realtors', 'realtors', <i className="fas fa-users" />, () =>
      navigate(`${APP_PREFIX_PATH}/realtors`),
     ),
  getItem('Logout', 'logout', <i className=" fas fa-sign-out-alt" />, () =>
   handlelogout(),
  ),
 ]

 return (
  <Layout.Header className="base-navbar">
   <Row align="middle" className="w-100" justify="space-between">
    <Col>
     <ul className="nav-links-header">
      <li className={style.inboxcalander}>
       <NavLink to={`${ADMIN_PREFIX_PATH}/inbox`}>
        <SvgInbox /> Inbox
       </NavLink>
      </li>
      <li className={style.inboxcalander}>
       <NavLink to={`${ADMIN_PREFIX_PATH}/calendar`}>
        <SvgCalenderIcon /> Calendar
       </NavLink>
      </li>
     </ul>
    </Col>
    <Col>
     <ul className="nav-links-header">
      <li>
       <Badge overflowCount={99999} offset={[-6, -5]} count={global.unreadnoti}>
        <BtnAnch
         onClick={() => toggleNotification()}
         tooltipText="Notification"
         className={style.notification}
        >
         <SvgNotificationICon />
        </BtnAnch>
       </Badge>
      </li>
      <li>
       <Dropdown arrow placement="bottomRight" overlay={<Menu items={items} />}>
        <a onClick={(e) => e.preventDefault()}>
         <AvatarStatus
          avatar={{ size: 30 }}
          theme="light"
          title={user?.name ?? ''}
          titleClassName="w-100"
          subTitle={user?.account_type ?? ''}
         />
         <i className="far fa-angle-down ms-2" />
        </a>
       </Dropdown>
      </li>
     </ul>
    </Col>
   </Row>
  </Layout.Header>
 )
}

export default Navbar

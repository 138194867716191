import { Col, Row, Modal } from 'antd'
import BaseTDPicker from 'components/elements/BaseComponents/BaseTDPicker'
import { BtnAnch } from 'components/elements/global'
import { TSendMailBody, TSendSmsBody, TSendTextBody } from 'features/leads'
import { baseTime } from 'lib'

import { FC, useCallback, useEffect, useState } from 'react'

type TScheduleModalProp = {
 onSubmitSchedule?: (time: any, date?: any, id?: any) => void
 visible: boolean
 onCancel: () => void
 type?: 'EMAIL' | 'SMS'
 sendData?: TSendMailBody | TSendSmsBody | TSendTextBody
 isLoading?: boolean
 defaultValue?: string | null
}

const ScheduleModal: FC<TScheduleModalProp> = ({
 visible,
 onCancel,
 type,
 onSubmitSchedule,
 isLoading,
 //  defaultValue = baseTime().format('YYYY-MM-DD hh:mm A'),
 defaultValue,
}) => {
 const [dateTIme, setDateTime] = useState<string | undefined>()
 const tomorrow = () => baseTime().add(1, 'days')
 const tomorrowMrng = baseTime(
  `${tomorrow().format('YYYY-MM-DD')} 09:00:00`,
 ).format()
 const tomorrowNoon = baseTime(
  `${tomorrow().format('YYYY-MM-DD')} 13:00:00`,
 ).format()

 const isActive = (d?: string) => baseTime(d).isSame(baseTime(dateTIme))
 useEffect(() => {
  if (defaultValue) {
   setDateTime(
    baseTime(defaultValue).isBefore()
     ? baseTime().format('MM-DD-YYYY hh:mm A')
     : defaultValue,
   )
  }
 }, [defaultValue])

 return (
  <Modal
   confirmLoading={isLoading}
   okButtonProps={{ disabled: !dateTIme }}
   onOk={() => {
    onSubmitSchedule?.(
     baseTime(dateTIme).format('hh:mm A'),
     baseTime(dateTIme).format('DD/MM/YYYY'),
    )
   }}
   width={400}
   className="shedl-email"
   okText="Submit"
   onCancel={onCancel}
   visible={visible}
   title={`Schedule ${type === 'EMAIL' ? 'Email' : 'SMS'}`}
  >
   <Row gutter={[15, 15]}>
    <Col span={12}>
     <BtnAnch
      onClick={() => {
       setDateTime(tomorrowMrng)
      }}
      className={`card ${isActive(tomorrowMrng) ? 'active' : ''}`}
     >
      <i className="fas fa-clock" />
      <h5>Tomorrow Morning</h5>
      <p>{baseTime(tomorrowMrng).format('MMM-DD, h:mm A')}</p>
     </BtnAnch>
    </Col>
    <Col span={12}>
     <BtnAnch
      onClick={() => {
       setDateTime(tomorrowNoon)
      }}
      className={`card ${isActive(tomorrowNoon) ? 'active' : ''}`}
     >
      <i className="fas fa-clock" />
      <h5>
       Tomorrow <br /> After Noon
      </h5>
      <p>{baseTime(tomorrowNoon).format('MMM-DD, h:mm A')}</p>
     </BtnAnch>
    </Col>
    <Col span={24}>
     <BaseTDPicker
      disablePastTime
      value={dateTIme ? baseTime(dateTIme) : undefined}
      onChange={(e) => setDateTime(e?.format())}
      placeholder="Select Date and Time"
      format="DD-MM-YYYY h:mm A"
     />
    </Col>
   </Row>
  </Modal>
 )
}

export default ScheduleModal

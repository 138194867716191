import { Modal, Input, Radio, Row, Spin, Empty, Card, Col } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { LoadMoreSelect } from 'components/elements/Form'
import { globalConfirm } from 'components/elements/global'
import { useLeadTransferMutation } from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import { FC, useEffect, useMemo, useState } from 'react'

type AssignAlertProp = {
 lead_id?: number[]
 visible: boolean
 onCancel: () => void
 onSuccess?: () => void
 isTransferred?: boolean
}

const LeadTransferModal: FC<AssignAlertProp> = ({
 visible,
 onCancel,
 lead_id,
 onSuccess,
 isTransferred,
}) => {
 const [transferLead, { isLoading: apLoading }] = useLeadTransferMutation()
 const [selectedAP, setAP] = useState<any>()
 const [searchKey, setSearch] = useState('')
 const { flatData, isLoading, hasMore, fetchMore, isRefetching } =
  useFetchPage<{ text?: string; id?: any }>('search-realtor', {
   method: 'post',
   body: useMemo(() => ({ search: searchKey }), [searchKey]),
  })

 const callLeadTransfer = async () => {
  await transferLead({
   id: JSON.stringify(lead_id),
   realtor: selectedAP,
  }).unwrap()
  handleClose()
  onSuccess?.()
 }
 const handleSubmit = async (): Promise<void> => {
  if (!isTransferred) {
   globalConfirm({
    content:
     // eslint-disable-next-line quotes
     "The Tag/Stage/Source/ Action Plans assigned by the realtor won't be copied to the new realtor",
    onOk: callLeadTransfer,
   })
  } else {
   callLeadTransfer()
  }
 }

 useEffect(
  () => () => {
   setAP('')
  },
  [],
 )

 const handleClose = () => {
  onCancel?.()
  setAP('')
 }

 return (
  <Modal
   okButtonProps={{ disabled: !selectedAP }}
   width={400}
   confirmLoading={apLoading}
   onOk={handleSubmit}
   okText="Submit"
   onCancel={handleClose}
   visible={visible}
   title="Lead Transfer"
  >
   <LoadMoreSelect
    hasMore={hasMore}
    loading={isLoading}
    fetchMore={fetchMore}
    onSearch={setSearch}
    onChange={(val) => setAP(val as any)}
    placeholder="Select a Realtor"
    data={
     flatData ? flatData?.map((v) => ({ label: v.text, value: v.id })) : []
    }
   />
  </Modal>
 )
}

export default LeadTransferModal

/* eslint-disable react/require-default-props */
import { Checkbox, Input, Radio, Spin } from 'antd'
import classNames from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useDebounce } from 'react-use'
import { AvatarStatus } from '../AvatarStatus'
import { BtnAnch } from '../global'

export type BaseSelectorOption<RecordType = any> = {
 title?: any
 subTitle?: any
 id?: any
 extra?: RecordType
}

type BaseSelectorProps<RecordType> = {
 type?: 'single' | 'multiple'
 value?: BaseSelectorOption<RecordType>[]
 options?: BaseSelectorOption<RecordType>[]
 isLoading?: boolean
 onSearch?: (key?: any) => void
 searchPlaceholder?: string
 showSearch?: boolean
 fetchMore?: () => void
 hasMore?: boolean
 onChange?: (records: BaseSelectorOption<RecordType>[]) => void
 showAvatar?: boolean
 valueBy?: 'id' | 'name'
}

const InternalBaseSelector = <RecordType extends object = any>(
 props: BaseSelectorProps<RecordType>,
 ref: React.ForwardedRef<HTMLDivElement>,
) => {
 const {
  onSearch,
  showSearch = true,
  isLoading,
  hasMore,
  fetchMore,
  searchPlaceholder = 'Search',
  options,
  onChange,
  type = 'multiple',
  value,
  showAvatar = true,
  valueBy = 'id',
 } = props

 const [selected, setSelected] = useState<BaseSelectorOption[]>([])

 const [searchKey, setSearchKey] = useState('')

 useDebounce(
  () => {
   if (searchKey.trim().length > 2) {
    onSearch?.(searchKey)
   } else {
    onSearch?.('')
   }
  },
  200,
  [searchKey],
 )

 useEffect(() => {
  if (value) {
   setSelected(value)
  }
 }, [value])

 const isActive = useCallback(
  (id?: any) => {
   return !!selected?.find?.((v) =>
    valueBy === 'id' ? v?.id === id : v === id,
   )
  },
  [selected],
 )

 return (
  <div className="base-selecter-blk">
   {showSearch && (
    <div className="selector-search px-3 py-1">
     <Input.Search
      value={searchKey}
      onChange={(e) => {
       setSearchKey(e.target.value)
      }}
      placeholder={searchPlaceholder}
     />
    </div>
   )}
   {isLoading ? (
    <div className="py-4 text-center">
     <Spin />
    </div>
   ) : (
    <ul>
     <InfiniteScroll
      useWindow={false}
      loader={
       <div className="text-center py-2">
        <Spin />
       </div>
      }
      hasMore={hasMore || false}
      loadMore={() => fetchMore?.()}
     >
      {options?.map((item, i) => (
       <li key={i}>
        <BtnAnch
         className={classNames({
          active: isActive(valueBy === 'id' ? item?.id : item?.subTitle),
         })}
         onClick={() =>
          setSelected((prv) => {
           let newData: BaseSelectorOption[] = prv
           if (type === 'multiple') {
            if (
             newData &&
             newData.length > 0 &&
             (valueBy === 'id'
              ? newData?.find?.((v) => v?.id === item?.id)
              : newData?.find?.((v) => v === item?.subTitle))
            ) {
             newData =
              valueBy === 'id'
               ? newData?.filter?.((v) => v?.id !== item?.id)
               : newData?.filter?.((v) => v !== item?.subTitle)
            } else if (valueBy === 'name') {
             newData?.push?.(item?.subTitle)
            } else {
             newData?.push?.(item)
            }
           } else {
            newData = [item]
           }
           onChange?.(newData)
           return [...newData]
          })
         }
        >
         <AvatarStatus
          showAvatar={showAvatar}
          action={
           type === 'multiple' ? (
            <Checkbox
             checked={isActive(valueBy === 'id' ? item?.id : item?.subTitle)}
            />
           ) : (
            <Radio
             checked={isActive(valueBy === 'id' ? item?.id : item?.subTitle)}
            />
           )
          }
          title={item?.title}
          subTitle={item?.subTitle}
         />
        </BtnAnch>
       </li>
      ))}
     </InfiniteScroll>
    </ul>
   )}
  </div>
 )
}

const BaseSelector = React.forwardRef(InternalBaseSelector) as <
 RecordType extends object = any,
>(
 props: React.PropsWithChildren<BaseSelectorProps<RecordType>> & {
  ref?: React.Ref<HTMLDivElement>
 },
) => React.ReactElement

export default BaseSelector

import { Form, Modal, Checkbox } from 'antd'
// import BaseInfinityTable from 'components/shared/DataTable/BaseDataTable'
import useFetchPage from 'hooks/useFetchPage'
import ListContentLayout from 'layout/ListContentLayout'
import { FC, useEffect, useMemo, useState } from 'react'
import { TModalState } from 'types'
// import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MDataTable } from 'components/shared/MDataTable'
import useSelectAll from 'hooks/useSelectAll'
import { TAlertFeedType, TGetAlerts, TPutAlertType } from '../types'
import AlertBulkActionBlock from './AlertBulkActionBlock'
import AlertFormSection from './AlertFormSection'
import AlertTableColumns from './AlerttableColumns'

const AlertList: FC<{ type?: TAlertFeedType }> = ({ type = 'forsale' }) => {
 const [modalState, setModal] = useState<TModalState<TPutAlertType>>({
  show: false,
  mode: 'ADD',
  data: {
   fead: 'forsale',
  },
 })
 const [searchKey, setSearchKey] = useState('')
 //  const [selectedRows, setSelectedRow] = useState<TGetAlerts[]>()
 const {
  isLoading,
  hasMore,
  fetchMore,
  refetch,
  flatData,
  totalCount,
  isRefetching,
 } = useFetchPage<TGetAlerts>('get-alerts', {
  method: 'post',
  body: useMemo(() => ({ fead: [type], search: searchKey }), [type, searchKey]),
 })

 const {
  selectedKeys,
  onSelectRow,
  rowSelectionState,
  selectAll,
  unSelected,
  setSelectAll,
  setUnSelected,
  setSelectedRow,
 } = useSelectAll(JSON.stringify({ search: searchKey }))

 //  const [selectAll, setSelectAll] = useState(false)
 //  const [unSelected, setUnSelected] = useState<any[]>([])

 //  const toggleSelectAll = (e: CheckboxChangeEvent): void => {
 //   //   setSelectAllCheckBox(e?.target?.checked ?? false)
 //   if (e?.target?.checked) {
 //    setSelectAll(true)
 //    //    setSelectedRow([])
 //    setUnSelected([])
 //   } else {
 //    setSelectAll(false)
 //    setSelectedRow([])
 //    setUnSelected([])
 //   }
 //  }

 //  const selectedKeys: any[] = useMemo(
 //   () => (selectedRows ? selectedRows?.map((v) => v.id) : []),
 //   [selectedRows],
 //  )

 useEffect(() => {
  if (selectAll && flatData && flatData?.length > 0) {
   const newunSelected = new Set(unSelected)
   const result = flatData?.filter((obj: any) => !newunSelected.has(obj.id))
   setSelectedRow(result as any)
  }
 }, [flatData, selectAll, unSelected])

 useEffect(() => {
  setSelectAll(false)
  setUnSelected([])
  setSearchKey('')
  setSelectedRow([])
  //   setSelectAllCheckBox(false)
 }, [type])

 const [form] = Form.useForm<
  TPutAlertType & { priceRange?: { min?: any; max?: any } }
 >()

 //  const headerCheckbox = (
 //   <Checkbox
 //    checked={selectAll || flatData?.length === selectedRows?.length}
 //    onChange={toggleSelectAll}
 //   />
 //  )
 return (
  <>
   <ListContentLayout
    title={type === 'forsale' ? 'New Listing Alert' : 'Neighbourhood Alert'}
    actions={
     <AlertBulkActionBlock
      selectedKeys={selectedKeys}
      onClickAdd={() => setModal({ show: true, mode: 'ADD' })}
      alertRefresh={refetch}
      selectAll={selectAll}
      unSelected={unSelected}
      filterInfo={JSON.stringify({ search: searchKey, fead: type })}
      type={type}
     />
    }
   >
    <MDataTable
     totalData={totalCount || 1000}
     search={{
      placeholder: 'Enter Alert Name',
      onSearch: setSearchKey,
      show: true,
     }}
     onSelectRow={onSelectRow}
     rowSelectionState={{
      ...rowSelectionState,
     }}
     loading={isLoading}
     isFetching={isRefetching}
     hasMore={hasMore}
     fetchMore={() => {
      fetchMore()
     }}
     data={flatData}
     columns={AlertTableColumns({
      handleClickEdit: (val) =>
       setModal({ show: true, mode: 'EDIT', data: val }),
      type,
     })}
    />
    {/* <BaseInfinityTable
     search={{
      placeholder: 'Enter Alert Name',
      onSearch: setSearchKey,
      show: true,
      searchKey,
     }}
     rowSelection={{
      onChange: (va, r: TGetAlerts[]) => {
       setSelectedRow(r)
      },
      columnWidth: 60,
      type: 'checkbox',
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedKeys,
      columnTitle: headerCheckbox,
      onSelect: (row, check) => {
       if (selectAll) {
        setUnSelected((prv) => {
         let array = prv ?? []
         if (check === false) {
          array = [...array, row.id]
         } else {
          array = prv.filter((m) => m !== row.id)
         }
         return [...array]
        })
       }
      },
     }}
     loading={isLoading}
     hasMore={hasMore}
     columns={AlertTableColumns({
      handleClickEdit: (val) =>
       setModal({ show: true, mode: 'EDIT', data: val }),
      type,
     })}
     onFetchMore={fetchMore}
     data={flatData || []}
    /> */}
   </ListContentLayout>
   <Modal
    footer={null}
    width={1100}
    title={`${modalState.mode === 'EDIT' ? 'Edit' : 'Add '} Alert`}
    onCancel={() => {
     setModal({ show: false })
     form.resetFields()
    }}
    visible={modalState.show}
   >
    {modalState.show && (
     <AlertFormSection
      show={modalState.show}
      form={form}
      type={type}
      mode={modalState.mode}
      editData={modalState.data as any}
      onCancel={() => {
       setModal({ show: false })
       form.resetFields()
      }}
      refresh={refetch}
     />
    )}
   </Modal>
  </>
 )
}

export default AlertList

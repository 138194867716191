import { preConAPI } from 'api/secondApi'
import { baseTransformResponse3 } from 'api/baseApi'
import { TPreConDetail, TSearchSuggType } from '../types'

export const preconAPIs = preConAPI.injectEndpoints({
 endpoints: ({ query }) => ({
  getPreconDetail: query<TPreConDetail, { slug: string }>({
   query: ({ slug }) => ({
    method: 'post',
    url: `api/get-condo-detail?api_version=v3&view_type=crm&slug=${slug}`,
   }),
   transformResponse: baseTransformResponse3,
  }),
  getPreconFilters: query<{ key: string; value: string[] }[], any>({
   query: () => ({
    method: 'get',
    url: 'api/get-filters?api_version=v3',
   }),
   transformResponse: baseTransformResponse3,
  }),
  getSearchPreCon: query<TSearchSuggType[], { search?: string }>({
   query: ({ search }) => ({
    method: 'get',
    url: `api/filter-search-pre-condo?api_version=v3&val=${search}`,
   }),
   transformResponse: baseTransformResponse3,
  }),
 }),
})

export const {
 useGetPreconDetailQuery,
 useGetPreconFiltersQuery,
 useGetSearchPreConQuery,
} = preconAPIs

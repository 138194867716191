import {
 baseAPI,
 baseTransformResponse,
 baseTransformResponse4,
} from 'api/baseApi'
import { secondAPI } from 'api/secondApi'
import { ApiResponce, TGroupName } from 'types'
import { TSelectAllB } from 'features/leads'
import {
 TAlertFeedType,
 TGetAlertName,
 TGetAlertNameBody,
 TPutAlertType,
} from '../types'

export const alertApi = baseAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  AddNewAlert: mutation<void, TPutAlertType>({
   query: (body) => ({
    url: 'user/put-alert',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  EditNewAlert: mutation<void, TPutAlertType>({
   query: ({ id, ...body }) => ({
    url: `user/update-alert/${id}`,
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
   invalidatesTags: ['LEAD'],
  }),
  getAlertNames: query<TGetAlertName[], TGetAlertNameBody>({
   query: (body) => ({
    url: 'user/get-alert-name',
    method: 'post',
    body,
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
  deleteBulkAlert: mutation<
   void,
   TSelectAllB & { id?: any[]; feed?: TAlertFeedType }
  >({
   query: (body) => ({
    url: 'user/delete-alerts',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  withdrawBulkAlert: mutation<
   void,
   { type?: TGroupName; type_id?: any; alert_id?: any }
  >({
   query: (body) => ({
    url: 'user/withdraw-alert-bulk',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),

  assignAlertBulk: mutation<
   void,
   { type?: TGroupName; type_id?: any[]; alert_id?: any[] }
  >({
   query: (body) => ({
    url: 'user/assign-alert-bulk',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
  assignToActionPlanLead: mutation<
   any,
   {
    lead_id: any[]
    actionplan_id?: any[]
    type?: 'action-plan'
   }
  >({
   query: (body) => ({
    url: 'user/assign-action-plan-lead',
    method: 'post',
    body,
   }),
   transformResponse: (res: any) => {
    baseTransformResponse4(res)
    return res
   },
  }),
  assignToLeadAlert: mutation<
   void,
   { lead_id: any[]; alert_id?: any[]; feed?: TAlertFeedType }
  >({
   query: (body) => ({
    url: 'user/assign-alert-lead',
    method: 'post',
    body,
   }),
   transformResponse: baseTransformResponse,
  }),
 }),
})

export const {
 useAssignAlertBulkMutation,
 useAssignToLeadAlertMutation,
 useAssignToActionPlanLeadMutation,
 useAddNewAlertMutation,
 useEditNewAlertMutation,
 useGetAlertNamesQuery,
 useDeleteBulkAlertMutation,
 useWithdrawBulkAlertMutation,
} = alertApi

export const alertApi1 = secondAPI.injectEndpoints({
 endpoints: ({ query, mutation }) => ({
  getAlertFilters: query<
   {
    building_type?: string[]
    listing_status?: string[]
    listing_type?: string[]
   },
   { val?: string }
  >({
   query: ({ val }) => ({
    url: `api/get-alert-filters${val}`,
    method: 'get',
   }),
   transformResponse: (res: ApiResponce) => res.data,
  }),
 }),
})

export const { useGetAlertFiltersQuery } = alertApi1

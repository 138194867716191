/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Modal } from 'antd'
import {
 TForwardData,
 TReplyData,
 TSendMailPro,
 TSendType,
} from 'features/leads'
import { TPropertyListing } from 'features/property'

import React, { FC } from 'react'
import { NewListingSlug, TMsgMode } from 'types'
import { SendEmailForm } from '../SendEmailForm'

export type ModalTypes = 'normal' | 'listing'

const SendEmailModal: FC<{
 sendType?: TSendType
 to?: any
 body?: string
 visible?: boolean
 onSendSuccess?: () => void
 onCancel?: () => void
 mode?: TMsgMode
 propertyData?: TSendMailPro[] | any
 replyData?: TReplyData
 forwardData?: TForwardData
 listingType?: NewListingSlug
 allData?: TPropertyListing[]
 isAttachment?: boolean
 modalTypes?: ModalTypes
 propFullInfo?: TPropertyListing[]
 updateSelectedProp?: (val?: any) => void
 removeTemplateDropdown?: boolean
 selectAll?: boolean
 unSelected?: number[]
 filterInfo?: string
 totalCount?: number
 disableBody?: boolean
 showMoreLabel?: boolean
 extraBody?: any
 rmMergeFields?: string[]
}> = ({
 forwardData,
 visible,
 onCancel,
 to,
 body,
 onSendSuccess,
 replyData,
 mode,
 propertyData,
 sendType,
 listingType,
 allData,
 isAttachment = true,
 modalTypes = 'normal',
 propFullInfo,
 updateSelectedProp,
 removeTemplateDropdown,
 filterInfo,
 selectAll,
 unSelected,
 totalCount,
 disableBody,
 showMoreLabel,
 extraBody,
 rmMergeFields,
}) => (
 <Modal
  transitionName="fade"
  centered
  forceRender
  footer={null}
  title={`${
   mode === 'REPLY' ? 'Reply' : mode === 'FORWARD' ? 'Forward' : 'Send'
  } Email`}
  width="100%"
  onCancel={onCancel}
  visible={visible}
 >
  <SendEmailForm
   propFullInfo={propFullInfo}
   forwardData={forwardData}
   sendType={sendType}
   mode={mode}
   replyData={replyData}
   to={to}
   propertyData={propertyData}
   onSendSuccess={onSendSuccess}
   listingType={listingType}
   allData={allData}
   isAttachment={isAttachment}
   modalType={modalTypes}
   updateSelectedProp={(val) => updateSelectedProp?.(val)}
   removeTemplateDropdown={removeTemplateDropdown}
   selectAll={selectAll}
   unSelected={unSelected}
   filterInfo={filterInfo}
   totalCount={totalCount}
   body={body}
   disableBody={disableBody}
   showMoreLabel={showMoreLabel}
   extraBody={extraBody}
   rmMergeFields={rmMergeFields}
  />
 </Modal>
)

export default SendEmailModal

import { Button } from 'antd'
import { SendEmailModal } from 'components/shared/Modals'
import { TUserActivityEmailLog } from 'features/leads/types'
import useAppControl from 'hooks/useAppControl'
import React, { FC, useState } from 'react'

const ActivityEmailActions: FC<{
 datas?: TUserActivityEmailLog
 onClickReOrFor?: () => void
}> = ({ datas, onClickReOrFor }) => {
 //  const [showEmailModal, setShowEmailModal] = useState(false)
 //  const [emailMode, setEmailMode] = useState<'REPLY' | 'FORWARD'>('REPLY')
 const { updateSendEmailWidget } = useAppControl()

 const onClickMail = (
  val: 'REPLY' | 'FORWARD',
  data?: TUserActivityEmailLog,
 ) => {
  updateSendEmailWidget({
   replyData: {
    date: data?.eupdated_at,
    subject: `${!data?.esubject?.startsWith('Re:') ? 'Re: ' : ''}${
     data?.esubject
    }`,
    to:
     data?.etype === 'Send' || (data?.etype as any) === 'send'
      ? data?.eto
      : data?.efrom,
    toName: data?.efrom_name,
    body: data?.ebody,
   },
   forwardData: {
    body: data?.ebody,
    from: data?.efrom,
    subject: `${!data?.esubject?.startsWith('Fwd:') ? 'Fwd: ' : ''} ${
     data?.esubject
    }`,
    to: data?.eto,
    date: data?.eupdated_at,
    fromName: data?.efrom_name,
    toName: data?.eto_name,
    attachment: data?.attachment,
   },
   show: true,
   mode: val,
   widgetFrom: 'lead',
  })
  onClickReOrFor?.()
 }
 return (
  <div className="d-flex justify-content-end">
   <Button
    size="small"
    onClick={() => {
     onClickMail('REPLY', datas)
    }}
    className="reply-i me-1"
    title="Reply"
   >
    <i className="far fa-reply" />
   </Button>
   <Button
    size="small"
    style={{ marginRight: '5px' }}
    onClick={() => {
     onClickMail('FORWARD', datas)
    }}
    title="Forward"
    className="forward-i"
   >
    <i className="far fa-share" />
   </Button>
  </div>
 )
}

export default ActivityEmailActions

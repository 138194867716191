import { BASE_URL } from 'config'
import { TSelectAllP } from 'features/leads'
import { baseTime } from 'lib'
import moment from 'moment'
import { SelectType } from 'types'

export const setLocalStore = (key: string, value: string): void => {
 localStorage.setItem(key, value)
}

export const getLocalStore = (key: string): string | null =>
 localStorage.getItem(key)

export const removeLocalStore = (key: string): void =>
 localStorage.removeItem(key)

type ImagePrefixRes = {
 url?: string
 name?: string
 ext?: string
}

export const imagePrefix = (image?: string): ImagePrefixRes | null => {
 const imagePath = BASE_URL
 if (image) {
  return {
   ext: image.split('.').pop() ?? '',
   name: image.split('/').pop() ?? '',
   url: `${image}`,
  }
 }
 return null
}

export const formatDate = (date?: string) => {
 let result

 if (moment(date).isSame(moment(), 'day')) {
  result = `Today ${moment(date).format('hh:mm A')}`
 } else if (moment(date).isSame(moment().subtract(1, 'day'), 'day')) {
  result = `Yesterday ${moment(date).format('hh:mm A')}`
 } else {
  result = moment(date).format('DD-MM-YYYY hh:mm A')
 }

 return result
}
export const eventReminder = (date?: any): SelectType[] => {
 const currentTime = baseTime()
 const selectedTime = baseTime(date)
 const diffM = selectedTime.diff(currentTime, 'minute')
 if (date) {
  if (date.isSameOrBefore(currentTime) || diffM < 11) {
   return []
  }
  if (diffM > 10 && diffM < 31) {
   return [
    {
     value: 10,
     label: '10 min before',
    },
   ]
  }
  if (diffM > 31 && diffM < 61) {
   return [
    {
     value: 10,
     label: '10 min before',
    },
    {
     value: 30,
     label: '30 min before',
    },
   ]
  }
  if (diffM > 61 && diffM < 1441) {
   return [
    {
     value: 10,
     label: '10 min before',
    },
    {
     value: 30,
     label: '30 min before',
    },
    {
     value: 60,
     label: '1 hour before',
    },
   ]
  }
  if (diffM > 1441 && diffM < 10081) {
   return [
    {
     value: 10,
     label: '10 min before',
    },
    {
     value: 30,
     label: '30 min before',
    },
    {
     value: 60,
     label: '1 hour before',
    },
    {
     value: 1440,
     label: '1 day before',
    },
   ]
  }
  if (diffM > 10081) {
   return [
    {
     value: 10,
     label: '10 min before',
    },
    {
     value: 30,
     label: '30 min before',
    },
    {
     value: 60,
     label: '1 hour before',
    },
    {
     value: 1440,
     label: '1 day before',
    },
    {
     value: 10080,
     label: '1 week before',
    },
   ]
  }
  return []
 }
 return []
}

export const spllitUrl = (val: string): any => val.split('page=')?.[1]

export const getImgData = (
 url: string,
): {
 url: string
 name: string
 ext: string
} => {
 return {
  ext: url.split('.').pop() ?? '',
  name: url.split('/').pop() ?? '',
  url: `${url}`,
 }
}

export const listingPriceRangerStep = (mode?: any, val?: any): any => {
 if (mode === 'rent' || mode === 'leased') {
  if (val > 5000) {
   return 100
  }
  return 250
 }
 if (val > 1000000) {
  return 50000
 }
 return 100000
}

export const convertPriceToPercentage = (
 value: number,
 mode: 'Sale' | 'Lease' = 'Sale',
): number => {
 if (mode === 'Lease') {
  if (value === 0) {
   return 0
  }
  if (value === 10000) {
   return 70
  }
  if (value < 5000) {
   return value / 100
  }
  return 50 + (value - 5000) / 250
 }
 if (value == 0) {
  return 0
 }
 if (value == 5000000) {
  return 40
 }
 if (value <= 1000000) {
  return value / 50000
 }
 if (value <= 1500000) {
  return 20 + (value - 1000000) / 100000
 }
 return 25 + (value - 1500000) / 250000
}

export const convertPercentageToPrice = (
 value: number,
 mode: 'Sale' | 'Lease' = 'Sale',
) => {
 if (mode === 'Lease') {
  if (value === 0) {
   return 0
  }
  if (value === 70) {
   return 10000
  }
  if (value <= 50) {
   return value * 100
  }
  return 5000 + (value - 50) * 250
 }
 if (value == 0) {
  return 0
 }
 if (value == 40) {
  return 5000000
 }
 if (value <= 20) {
  return value * 50000
 }
 if (value <= 25) {
  return 1000000 + (value - 20) * 100000
 }
 return 1500000 + (value - 25) * 250000
}

export const checkEmptyNullZeroUndefine = (value?: any): boolean => {
 if (
  value === undefined ||
  value === '' ||
  value === 0 ||
  value === null ||
  value.length === 0
 ) {
  return true
  //   return false
 }
 return false
 //  return true
}
export const capitalizeFirstLetter = (str: any) => {
 return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isEmptyFilter = (filters?: any) => {
 const { listing_type, price_range, ...values } = filters
 return !Object.values(values).every((value) =>
  checkEmptyNullZeroUndefine(value),
 )
}

export const rangeText = (v1?: any, v2?: any, preFix?: any) => {
 if (v1 !== null && v2 !== null) {
  if (Number(v1 || 0) === 0 && Number(v2 || 0) === 0) {
   return ''
  }
  if (Number(v1 || 0) === 0 || Number(v2 || 0) === 0) {
   return `${v1 || 0}${preFix ?? '-'}${v2 || 0}`
  }
  return `${v1}${preFix ?? '-'}${v2}`
 }
 if (v1 !== null || v2 !== null) {
  return v1 ?? v2
 }
 return ''
}

export function areArraysEqual(A: any, B: any): boolean {
 // Check if arrays have the same length
 if (A.length !== B.length) {
  return false
 }

 // Iterate over each element in array A
 for (let i = 0; i < A.length; i++) {
  const elementA = A[i]
  const elementB = B[i]

  // Check if the elements are not equal
  if (elementA !== elementB) {
   return false
  }
 }

 return true
}

export function mergeArrays(A: any[], B: any[]): any[] {
 const mergedArray: any[] = []

 // Iterate over each object in array A
 for (let i = 0; i < A.length; i++) {
  const objA: any = A[i]

  // Check if the object exists in array B
  const existsInB: boolean = B.some((objB) => objB === objA) // Modify the condition according to your object's unique identifier

  // If it doesn't exist, add it to mergedArray
  if (!existsInB) {
   mergedArray.push(objA)
  }
 }

 // Iterate over each object in array B
 for (let i = 0; i < B.length; i++) {
  mergedArray.push(B[i])
 }

 return mergedArray
}

export const handleSelectAllBody = (
 values: any,
 selectAllVal?: TSelectAllP,
): any => {
 if (!selectAllVal?.selectAll) {
  return values
 }

 return {
  unselected: selectAllVal?.unSelected,
  select_all: selectAllVal?.selectAll,
  filter_info: selectAllVal?.filterInfo,
 }
}

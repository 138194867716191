/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */
// import {
//  //  Tooltip,
//  //  Popover,
//  Checkbox,
//  Select,
//  //  Badge,
//  Menu,
//  Radio,
//  Button,
//  DatePicker,
//  Space,
// } from 'antd'
// import { CheckboxValueType } from 'antd/lib/checkbox/Group'
// import { ColumnsType } from 'antd/lib/table'
import { AvatarStatus } from 'components/elements/AvatarStatus'
// import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import { TSSBlockList } from 'components/shared/Common'
import moment from 'moment'
// import {
//  Dispatch,
//  FC,
//  SetStateAction,
//  useEffect,
//  useMemo,
//  //  useRef,
//  useState,
// } from 'react'
// import { SvgEmailIcon } from 'components/icons'
import { baseTime } from 'lib'
// import { useClickAway } from 'react-use'
import Utils from 'utils/helper'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { Tooltip } from 'antd'
import {
 TLeadFilterBody,
 TGetLeads,
 TGetLeadFilter,
 TLeadTabType,
} from '../types'
import CustomDateFilter from './CustomDateFilter'

type LeadTableProps = {
 activeFilter?: TLeadFilterBody
 filter?: TGetLeadFilter
 onFIlter?: (body: TLeadFilterBody) => void
 onClickLead?: (id?: any) => void
 selectedLeadsType?: 'all-leads' | 'my-leads'
 account_type?: 'Brokerage' | 'Realtor'
 from: 'lead' | 'transfer-lead'
 onSubmitCustomDate?: (val: {
  value: any
  range?: TRangeDate | undefined
 }) => void
 tileKey: TLeadTabType
}
type TRangeDate = {
 from_date?: string
 to_date?: string
}
export type CustomDateSubmitData = {
 value: any
 range?: TRangeDate
}

const LeadTableColums = ({
 filter,
 onFIlter,
 onClickLead,
 activeFilter,
 selectedLeadsType,
 account_type,
 from,
 onSubmitCustomDate,
 tileKey,
}: LeadTableProps): MDataTableColumns<TGetLeads>[] => {
 //  const [sectdFiltrDate, setSelctdFiltrDate] = useState(
 //   activeFilter?.created ?? 'All',
 //  )
 //  const [sectdFiltrDateRange, setSelctdFiltrDateRange] = useState<
 //   [any, any] | null
 //  >(null)

 //  useEffect(() => {
 //   setSelctdFiltrDate(activeFilter?.created ?? 'All')
 //   if (activeFilter?.created !== 'Custom') {
 //    setSelctdFiltrDateRange(null)
 //   } else if (activeFilter?.from_date && activeFilter?.to_date) {
 //    setSelctdFiltrDateRange([
 //     baseTime(activeFilter?.from_date),
 //     baseTime(activeFilter?.to_date),
 //    ])
 //   }
 //  }, [activeFilter?.created, activeFilter])

 //  const onCancelDateFilter = () => {
 //   setSelctdFiltrDate(activeFilter?.created ?? 'All')
 //   if (activeFilter?.created === 'Custom') {
 //    setSelctdFiltrDateRange([
 //     baseTime(activeFilter?.from_date),
 //     baseTime(activeFilter?.to_date),
 //    ])
 //   } else {
 //    setSelctdFiltrDateRange(null)
 //   }
 //  }

 const convertFIlterOption = (
  filters?: {
   id?: number
   name?: string
  }[],
  key?: 'id' | 'name',
  format?: (label?: any) => string,
 ): { label?: string; value?: any }[] => {
  if (!filters) return []
  return filters?.map((v) => ({
   label: format ? format(v.name) : v.name,
   value: `${v[key ?? 'id'] || v.name}`,
  }))
 }

 return [
  {
   title: 'Name',
   dataIndex: 'name',
   size: 250,
   fixed: 'left',
   render: (val, record, index) => (
    <BtnAnch onClick={() => onClickLead?.(index)}>
     <AvatarStatus
      title={`${val} ${
       from === 'lead'
        ? record?.transferowner
          ? `(${account_type === 'Brokerage' ? 'Assigned To' : 'Assigned By'} ${
             record?.transferowner
            })`
          : ''
        : ''
      }`}
      showTooltip
      // avatarIconValue={Utils.shortName(val || '')}
     />
    </BtnAnch>
   ),
  },
  {
   title: 'Phone',
   dataIndex: 'phone_number',
   //  filterId: 'phone',
   size: 200,
   render: (val, r) => (
    <div className={r.phone_number?.label === 'Bad' ? 'bad-label' : ''}>
     {val?.number}
    </div>
   ),
   filter: {
    mode: 'single',
    options: convertFIlterOption(filter?.phone_filter, 'name'),
    showFilteCustomDot: true,
    filterDotExcludeValues: ['All'],
   },
   //  filterIcon: () => (
   //   <Badge dot={activeFilter?.phone}>
   //    <i className="fal fa-filter" />
   //   </Badge>
   //  ),
   //  filterDropdown: ({ confirm }) => {
   //   return (
   //    <FilterSingle
   //     value={activeFilter?.phone ?? 'All'}
   //     onFilter={(ph) => {
   //      onFIlter?.({ phone: ph === 'All' ? null : ph })
   //     }}
   //     filters={filter?.phone_filter?.map((v) => ({
   //      label: v.name,
   //      value: v.id,
   //     }))}
   //     onClose={() => confirm({ closeDropdown: true })}
   //    />
   //   )
   //  },
  },
  {
   title: 'Email',
   dataIndex: 'email_address',
   size: 200,
   ellipsis: true,
   filter: {
    mode: 'single',
    options: convertFIlterOption(filter?.email_filter, 'name'),
    showFilteCustomDot: true,
    filterDotExcludeValues: ['All'],
   },
   //  ellipsis: {
   //   showTitle: false,
   //  },

   //  filterDropdown: ({ confirm }) => (
   //   <FilterSingle
   //    value={activeFilter?.email ?? 'All'}
   //    onFilter={(ph) => onFIlter?.({ email: ph === 'All' ? null : ph })}
   //    filters={filter?.email_filter?.map((v) => ({
   //     label: v.name,
   //     value: v.id,
   //    }))}
   //    onClose={() => {
   //     confirm({ closeDropdown: true })
   //    }}
   //   />
   //  ),
   render: (val, r) => (
    // <Tooltip placement="topLeft" title={val?.email}>
    <div className={r?.email_address?.label === 'Bad' ? 'bad-label' : ''}>
     {val?.email ?? ''}
    </div>
    // </Tooltip>
   ),
   //  filterIcon: () => (
   //   <Badge dot={activeFilter?.email}>
   //    <i className="fal fa-filter" />
   //   </Badge>
   //  ),
  },
  {
   title: 'Activity',
   dataIndex: 'activity',
   size: 200,
   ellipsis: true,
   // sorter: true,
   //  key: 'activity',
   //  ellipsis: {
   //   showTitle: false,
   //  },
   //  render: (activity) => (
   //   <Tooltip placement="topLeft" title={activity}>
   //    {activity}
   //   </Tooltip>
   //  ),
  },
  ...(((from === 'lead' && selectedLeadsType === 'all-leads') ||
   from === 'transfer-lead') &&
  account_type === 'Brokerage'
   ? [
      {
       title: 'Transferred on',
       size: 130,
       ellipsis: true,
       render: (v: any, r: TGetLeads) => (
        <div>
         {r?.transferowner
          ? moment(r?.transaction?.created_at).format('DD MMM YYYY')
          : ''}
        </div>
       ),
      },
     ]
   : []),
  {
   title: 'Created',
   dataIndex: 'created_at',
   size: 140,
   ...(tileKey !== 'weeklead'
    ? {
       filter: {
        mode: 'single',
        customNode: (cProps) => (
         <CustomDateFilter
          value={activeFilter?.created}
          rangeValue={{
           from_date: activeFilter?.from_date,
           to_date: activeFilter?.to_date,
          }}
          onHide={cProps?.onHide}
          onClickSubmit={onSubmitCustomDate}
          data={convertFIlterOption(filter?.day_filter)}
         />
        ),
        showFilteCustomDot: true,
        filterDotExcludeValues: ['All'],
       },
      }
    : {}),
   //  onFilterDropdownVisibleChange: (e) => {
   //   if (!e) {
   //    onCancelDateFilter()
   //   }
   //  },

   //  filterDropdown: ({ confirm, visible, clearFilters }) => {
   //   return (
   //    <FilterDate
   //     onFilter={({ value, range }) => {
   //      onFIlter?.({
   //       created: value === 'All' ? null : value,
   //       from_date: value === 'All' ? null : range?.from_date || '',
   //       to_date: value === 'All' ? null : range?.to_date || '',
   //      })
   //     }}
   //     filters={filter?.day_filter?.map((v) => ({
   //      label: v.name,
   //      value: v.id,
   //     }))}
   //     onClose={() => {
   //      confirm({ closeDropdown: true })
   //     }}
   //     setRange={setSelctdFiltrDateRange}
   //     setSelected={setSelctdFiltrDate}
   //     selected={sectdFiltrDate}
   //     selectedRange={sectdFiltrDateRange}
   //    />
   //   )
   //  },
   render: (val) => moment(val).format('DD MMM YYYY'),
   //  filterIcon: () => (
   //   <Badge dot={activeFilter?.created}>
   //    <i className="fal fa-filter" />
   //   </Badge>
   //  ),
  },
  ...((from === 'lead' && selectedLeadsType === 'my-leads') ||
  account_type === 'Realtor'
   ? [
      {
       title: 'Tags',
       dataIndex: 'tags',
       size: 250,

       render: (v: any, r: TGetLeads) => (
        <TSSBlockList
         //  closable={
         //   // (selectedLeadsType !== 'all-leads' && from !== 'transfer-lead') ||
         //   // account_type === 'Realtor'
         //  }
         closable
         type="lead"
         mode="tag"
         typeId={r.id ?? ''}
         data={r.tags}
         tooltiOptions={{
          getPopupContainer: (e) =>
           e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
            ?.parentNode as any,
         }}
        />
       ),
       filter: {
        searchPlaceholder: 'Search Tags',
        showSearch: true,
        showSelectAll: true,
        mode: 'multiple' as const,
        options: convertFIlterOption(filter?.tag_filter),
       },
       //  filterIcon: () => (
       //   <Badge dot={activeFilter?.tag as any}>
       //    <i className="fal fa-filter" />
       //   </Badge>
       //  ),
       //  filterDropdown: ({ confirm }) => (
       //   <FilterBlock
       //    onFilter={(st) => onFIlter?.({ tag: st })}
       //    filters={filter?.tag_filter?.map((v) => ({
       //     label: v.name,
       //     value: v.id,
       //    }))}
       //    onClose={() => confirm({ closeDropdown: true })}
       //    activeFilter={activeFilter?.tag}
       //   />
       //  ),
      },
      {
       title: 'Stage',
       dataIndex: 'stages',
       size: 200,
       render: (v: any, r: TGetLeads) =>
        r.stages && (
         <TSSBlockList
          type="lead"
          mode="stage"
          typeId={r.id ?? ''}
          data={[r.stages]}
          tooltiOptions={{
           getPopupContainer: (e) =>
            e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
             ?.parentNode as any,
          }}
         />
        ),
       filter: {
        searchPlaceholder: 'Search Stages',
        showSearch: true,
        showSelectAll: true,
        mode: 'multiple' as const,
        options: convertFIlterOption(filter?.stage_filter),
       },

       //  filterIcon: () => (
       //   <Badge dot={activeFilter?.stage as any}>
       //    <i className="fal fa-filter" />
       //   </Badge>
       //  ),
       //  filterDropdown: ({ confirm }) => (
       //   <FilterBlock
       //    onFilter={(st) => onFIlter?.({ stage: st })}
       //    filters={filter?.stage_filter?.map((v) => ({
       //     label: v.name,
       //     value: v.id,
       //    }))}
       //    onClose={() => confirm({ closeDropdown: true })}
       //    activeFilter={activeFilter?.stage}
       //   />
       //  ),
      },
     ]
   : []),

  {
   title: 'Source',
   dataIndex: 'sources',
   size: 200,
   ellipsis: false,

   render: (v, r) => {
    if (r.sources) {
     return (
      <span className="line-clamp-1">
       <Tooltip
        // placement="topLeft"
        title={r.sources?.name}
        getPopupContainer={(e) =>
         e?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
          ?.parentNode as any
        }
       >
        {r.sources?.name}
       </Tooltip>
      </span>
     )
    }
   },
   filter: {
    searchPlaceholder: 'Search Sources',
    showSearch: true,
    showSelectAll: true,
    mode: 'multiple',
    options: convertFIlterOption(filter?.source_filter),
   },
   //  filterIcon: () => (
   //   <Badge dot={activeFilter?.source as any}>
   //    <i className="fal fa-filter" />
   //   </Badge>
   //  ),
   //  filterDropdown: ({ confirm }) => (
   //   <FilterBlock
   //    onFilter={(st) => onFIlter?.({ source: st })}
   //    filters={filter?.source_filter?.map((v) => ({
   //     label: v.name,
   //     value: v.id,
   //    }))}
   //    onClose={() => {
   //     confirm({ closeDropdown: true })
   //    }}
   //    activeFilter={activeFilter?.source}
   //   />
   //  ),
  },
 ]
}

export default LeadTableColums

// export const FilterBlock: FC<{
//  filters?: { value?: any; label: any }[]
//  onClose?: () => void
//  onFilter?: (val?: any) => void
//  activeFilter?: null | any[]
// }> = ({ onClose, filters, onFilter, activeFilter }) => {
//  const [filterVal, setFilterVal] = useState<CheckboxValueType[]>([])

//  useEffect(() => {
//   setFilterVal(activeFilter ?? [])
//  }, [activeFilter])

//  if (!filters || (filters && filters.length === 0)) {
//   return null
//  }

//  return (
//   <div className="filter-table-custom">
//    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
//     <Checkbox.Group value={filterVal} onChange={(e) => setFilterVal(e)}>
//      {filters.map((v) => (
//       <div key={v.value} className="filter-menu">
//        <Checkbox className="me-1" value={v.value}>
//         {v.label}
//        </Checkbox>
//       </div>
//      ))}
//     </Checkbox.Group>
//    </div>
//    <div className="footer-button">
//     <BaseButton
//      onClick={() => {
//       setFilterVal([])
//       onFilter?.(null)
//       onClose?.()
//      }}
//      size="small"
//     >
//      {' '}
//      Reset
//     </BaseButton>
//     <BaseButton
//      onClick={() => {
//       onFilter?.(filterVal && filterVal.length === 0 ? null : filterVal)
//       onClose?.()
//      }}
//      buttonType="primary"
//      size="small"
//     >
//      Submit
//     </BaseButton>
//    </div>
//   </div>
//  )
// }

// export const FilterDate: FC<{
//  //  value?: any
//  filters?: { value?: any; label: any }[]
//  onClose?: () => void
//  onFilter?: (val?: any) => void
//  //  activeFilters?: TLeadFilterBody
//  //  visible?: boolean
//  setRange: Dispatch<SetStateAction<[any, any] | null>>
//  setSelected: Dispatch<any>
//  selected: any
//  selectedRange: [any, any] | null
//  //  selected
// }> = ({
//  onClose,
//  filters,
//  onFilter,
//  selected,
//  selectedRange,
//  setSelected,
//  setRange,
// }) => {
//  const disabledBtn = useMemo(() => {
//   if (selected) {
//    if (selected === 'Custom') {
//     return !(selectedRange && selectedRange.length === 2)
//    }
//    return false
//   }
//   return true
//  }, [selected, selectedRange])

//  if (!filters || (filters && filters.length === 0)) {
//   return null
//  }

//  const handleSubmit = () => {
//   const submitData: CustomDateSubmitData = {
//    value: selected,
//   }
//   if (selected === 'Custom' && selectedRange && selectedRange.length > 0) {
//    const fromDate = baseTime(selectedRange?.[0]).startOf('day').format()
//    const toDate = baseTime(selectedRange?.[1]).endOf('day').format()
//    submitData.range = {
//     from_date: baseTime.utc(fromDate).format(),
//     to_date: baseTime.utc(toDate).format(),
//    }
//   }

//   onFilter?.(submitData)
//   onClose?.()
//  }

//  return (
//   <div
//    // ref={ref}
//    className="filter-table-custom p-2"
//    style={{ width: '280px' }}
//   >
//    <Menu
//     className="h-100"
//     style={{ maxHeight: '100%' }}
//     onClick={(e) => {
//      setSelected(e.key)
//     }}
//     selectable={false}
//     selectedKeys={[selected]}
//    >
//     {filters.map?.((v) => (
//      <Menu.Item
//       key={v.label}
//       icon={<Radio checked={selected === v.label} type="" />}
//      >
//       {v.label === 'All'
//        ? 'All Days'
//        : v.value === 1
//        ? `Within ${v.label} Day`
//        : v.label === 'Custom'
//        ? 'Custom Days'
//        : `Within ${v.label} Days`}
//      </Menu.Item>
//     ))}
//     {selected === 'Custom' && (
//      <li className="ant-menu-item">
//       <DatePicker.RangePicker
//        clearIcon={false}
//        format="DD-MM-YYYY"
//        value={selectedRange}
//        onChange={(date) => {
//         if (date && date.length > 0) {
//          setRange(date)
//         }
//        }}
//        disabledDate={(cur) => cur && cur.valueOf() > Date.now()}
//        inputReadOnly
//       />
//      </li>
//     )}
//    </Menu>
//    <div>
//     <Space>
//      <Button
//       onClick={() => {
//        onClose?.()
//        //  setSelected(value)
//        //  if (value === 'Custom') {
//        //   setRange([
//        //    baseTime(activeFilters?.from_date),
//        //    baseTime(activeFilters?.to_date),
//        //   ])
//        //  } else {
//        //   setRange(null)
//        //  }
//       }}
//      >
//       Cancel
//      </Button>
//      <Button type="primary" onClick={handleSubmit} disabled={disabledBtn}>
//       Submit
//      </Button>
//     </Space>
//    </div>
//   </div>
//  )
// }

// export const FilterSingle: FC<{
//  value?: any
//  filters?: { value?: any; label: any }[]
//  onClose?: () => void
//  onFilter?: (val?: any) => void
// }> = ({ onClose, filters, onFilter, value = '' }) => {
//  if (!filters || (filters && filters.length === 0)) {
//   return null
//  }

//  return (
//   <div className="filter-table-custom p-2">
//    <Select
//     value={value}
//     placeholder="Select"
//     onChange={(e) => {
//      onFilter?.(e), onClose?.()
//     }}
//     style={{ width: '200px' }}
//    >
//     {filters.map((v) => (
//      <Select.Option key={v.value} value={v.label}>
//       {v.label}{' '}
//      </Select.Option>
//     ))}
//    </Select>
//   </div>
//  )
// }

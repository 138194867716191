import { Form, Input, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAddCityMutation } from 'features/leads'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TAddCity } from 'features/leads/routes/details/profile/Boxes/CityBlock'
import React, { FC, useEffect } from 'react'

const AddCityModal: FC<{
 visible?: boolean
 onCancel?: () => void
 city?: any
}> = ({ visible, onCancel, city }) => {
 const [addCity, { isLoading }] = useAddCityMutation()

 const { leadId } = useLeadDetails()

 const [form] = useForm<TAddCity>()

 const handleAddCity = async () => {
  const values = await form.validateFields()
  await addCity({ ...values, lead_id: leadId }).unwrap()
  form.resetFields()
  onCancel?.()
 }

 useEffect(() => {
  if (city) {
   form.setFieldsValue({
    city,
   })
  }
 }, [city])

 return (
  <Modal
   visible={visible}
   onCancel={onCancel}
   title={`${city ? 'Edit' : 'Add'} City`}
   onOk={handleAddCity}
   confirmLoading={isLoading}
  >
   <Form form={form}>
    <Form.Item name="city">
     <Input placeholder="Enter city" />
    </Form.Item>
   </Form>
  </Modal>
 )
}

export default AddCityModal

import { ColumnsType } from 'antd/lib/table'
import { Space } from 'antd'
import { TSSBlockList } from 'components/shared/Common'
import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import { MDataTableColumns } from 'components/shared/MDataTable/type'
import { TActionPlanList } from '../types'

type APTableProps = {
 handleClickEdit?: (id?: any) => void
 handleClickView?: (id?: any) => void
 clickActiveModal: (id?: any) => void
 handleControlAP?: (type: 'Pause' | 'Stop' | 'Completed', id?: any) => void
 selectedKeys?: any[]
 selectAll?: boolean
 unSelected?: any[]
}

const TableColumns = ({
 handleClickEdit,
 handleClickView,
 clickActiveModal,
 handleControlAP,
 selectAll,
 selectedKeys,
 unSelected,
}: APTableProps): MDataTableColumns<TActionPlanList>[] => [
 {
  title: 'Name',
  dataIndex: 'name',
  size: 310,
  fixed: 'left',
  // render: (_e, r) => `${r?.name} (${r?.owner})`,
 },
 {
  title: 'Steps',
  dataIndex: 'steps_count',
  size: 90,
 },
 {
  title: 'Active',
  dataIndex: 'active_leads_count',
  size: 90,
  render: (_e, r) =>
   r.active_leads_count > 0 ? (
    <BtnAnch className="colored-link" onClick={() => clickActiveModal?.(r.id)}>
     {r.active_leads_count}
    </BtnAnch>
   ) : (
    <a className="text-dark">{r.active_leads_count}</a>
   ),
 },
 {
  title: 'Paused',
  dataIndex: 'paused_leads_count',
  size: 90,
  render: (_e, r) =>
   r.paused_leads_count > 0 ? (
    <BtnAnch
     className="colored-link"
     onClick={() => handleControlAP?.('Pause', r.id)}
    >
     {r.paused_leads_count}
    </BtnAnch>
   ) : (
    <a className="text-dark">{r.paused_leads_count}</a>
   ),
 },
 {
  title: 'Stopped',
  dataIndex: 'stoped_leads_count',
  size: 90,
  render: (_e, r) =>
   r.stoped_leads_count > 0 ? (
    <BtnAnch
     className="colored-link"
     onClick={() => handleControlAP?.('Stop', r.id)}
    >
     {r.stoped_leads_count}
    </BtnAnch>
   ) : (
    <a className="text-dark">{r.stoped_leads_count}</a>
   ),
 },
 {
  title: 'Completed',
  dataIndex: 'completed_leads_count',
  size: 110,
  // key: 'Completed',
  render: (_e, r) =>
   r.completed_leads_count > 0 ? (
    <BtnAnch
     className="colored-link"
     onClick={() => handleControlAP?.('Completed', r.id)}
    >
     {r.completed_leads_count}
    </BtnAnch>
   ) : (
    <a className="text-dark">{r.completed_leads_count}</a>
   ),
 },
 {
  title: 'Created By',
  dataIndex: 'owner',
  size: 150,
 },
 {
  title: 'Tags',
  // ellipsis: true,
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    data={r.tags}
    mode="tag"
    type="actionPlan"
    closable
   />
  ),
 },
 {
  title: 'Stage',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    data={r.stages}
    mode="stage"
    type="actionPlan"
    closable
   />
  ),
 },
 {
  title: 'Source',
  dataIndex: 'completed_leads_count',
  size: 200,
  render: (_e, r) => (
   <TSSBlockList
    typeId={r.id}
    data={r.sources}
    mode="source"
    type="actionPlan"
    closable
   />
  ),
 },
 {
  title: 'Action',
  size: 80,
  fixed: 'right',
  render: (_, record) => (
   <Space>
    <BaseButton
     disabled={
      ((selectedKeys?.length ?? 0) > 1 && selectedKeys?.includes(record?.id)) ||
      (selectAll && !unSelected?.includes(record?.id))
     }
     onClick={() => handleClickView?.(record.id)}
     tooltipText="View"
     shape="circle"
     size="small"
     buttonType="danger"
     icon={<i className="fas fa-eye" />}
    />
    <BaseButton
     disabled={
      ((selectedKeys?.length ?? 0) > 1 && selectedKeys?.includes(record?.id)) ||
      (selectAll && !unSelected?.includes(record?.id))
     }
     onClick={() => handleClickEdit?.(record.id)}
     tooltipText="Edit"
     shape="circle"
     size="small"
     buttonType="info"
     icon={<i className="fas fa-edit" />}
    />
   </Space>
  ),
 },
]

export default TableColumns

import { Col, Row } from 'antd'
import AddCityModal from 'components/shared/Modals/AddCityModal'
import React, { FC, useState } from 'react'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

export type TAddCity = {
 city?: string
 lead_id?: any
}

const CityBlock: FC<{ city?: any; isTransferred?: boolean }> = ({
 city,
 isTransferred,
}) => {
 const [showModal, setShowModal] = useState(false)
 return (
  <div className="mb-3">
   <ProInnerCard
    title="City"
    onCLickAdd={city || !isTransferred ? undefined : () => setShowModal(true)}
    isEmpty={!city}
    emptyText="No City Added"
   >
    {city && (
     <Row gutter={[15, 15]}>
      <Col span={24}>
       <SimpleBlockItem
        text={city}
        onEdit={isTransferred ? () => setShowModal(true) : undefined}
       />
      </Col>
     </Row>
    )}
   </ProInnerCard>
   {showModal && (
    <AddCityModal
     visible={showModal}
     onCancel={() => setShowModal(false)}
     city={city}
    />
   )}
  </div>
 )
}

export default CityBlock

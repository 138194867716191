import { Col, Drawer, Form, InputNumber, Radio, Row, Select } from 'antd'
import PropertyPriceRanger from 'components/shared/Property/PropertyPriceRanger'
import { FC, useEffect, useMemo } from 'react'
import { BaseButton } from 'components/elements/BaseButton'
import {
 FilterBedLists,
 FilterDaysOnMarket,
 FilterSoldLeasedDOM,
} from 'static-data'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import BaseNumberInput from 'components/elements/BaseNumberInput'
import { BtnAnch } from 'components/elements/global'
import {
 proFilterIntialState,
 useProListing,
} from '../provider/ProListingProvider'
import { useGetCrmFiltersQuery } from '../api'
import { TProFilterState } from '../types'
import { useGetPreconFiltersQuery } from '../api/precon'

const ProFilterBlock: FC<{ onHide: () => void }> = ({ onHide }) => {
 const {
  changeListingType,
  filterState,
  type,
  setFilterState,
  searchType,
  setSearch,
  setSearchType,
  selectedCity,
 } = useProListing()

 const selectedType = filterState.listing_type

 const [form] = useForm<
  TProFilterState & {
   price_range: { min: any; max: any }
  }
 >()

 const propertyType = Form.useWatch('property_type', form)

 const filterQuery = useMemo(() => {
  return `${
   propertyType && propertyType?.length !== 0
    ? `?type=${JSON.stringify(propertyType)}`
    : ''
  }${
   ['open-house', 'sold', 'leased']?.includes(selectedType)
    ? `${
       (propertyType && propertyType?.length === 0) ||
       propertyType === undefined ||
       propertyType === null
        ? '?'
        : '&'
      }${selectedType === 'open-house' ? 'openhouse=true' : 'listing=sold'}`
    : ''
  }
  `
 }, [propertyType])

 const { data: propertyFilterData } = useGetCrmFiltersQuery(
  {
   ...(filterQuery ? { val: filterQuery } : {}),
  },
  {
   skip: type === 'preconstruction',
  },
 )

 const { data: preConFiltersData } = useGetPreconFiltersQuery(
  {},
  { skip: type === 'listing' },
 )

 const filtersData = useMemo(
  () => (type === 'preconstruction' ? preConFiltersData : propertyFilterData),
  [type, propertyFilterData, preConFiltersData],
 )

 const handleSubmit = async () => {
  const values: any = await form.validateFields()
  const finalValues: any = values
  finalValues.listing_type = selectedType
  finalValues.min_price = values?.price_range.min
  finalValues.max_price = values?.price_range.max

  if (
   (selectedType === 'rent' || selectedType === 'leased') &&
   finalValues?.max_price === 10000
  ) {
   finalValues.max_price = ''
  } else if (finalValues?.max_price === 5000000) {
   finalValues.max_price = ''
  }
  delete finalValues.price_range

  setFilterState(finalValues)
  onHide?.()
 }

 const onChangePropertyType = () => {
  const oldValues: any = form.getFieldsValue()
  Object.entries(oldValues).forEach(([key, value]) => {
   if (key === 'baths' || key === 'beds') {
    oldValues[key] = ''
   } else if (key === 'building_type') {
    oldValues[key] = []
   } else if (key === 'ownership_type' || key === 'building_style') {
    oldValues[key] = null
   } else if (
    key === 'price_range' ||
    key === 'property_type' ||
    key === 'd_o_m' ||
    key === 'listing_type'
   ) {
    oldValues[key] = value
   } else {
    oldValues[key] = null
   }
  })
  form.setFieldsValue(oldValues)
 }

 useEffect(() => {
  if (filterState) {
   const values: any = filterState
   values.price_range = {
    min: filterState.min_price ?? 0,
    max: filterState.max_price ?? '',
   }
   values.property_type = filterState.property_type
   values.building_type = filterState.building_type
   delete values?.min_price
   delete values?.max_price
   form.setFieldsValue(values)
  }
 }, [filterState])

 // eslint-disable-next-line no-nested-ternary
 const domLabel = ['sale', 'rent', 'open-house'].includes(selectedType)
  ? 'Days on Market'
  : selectedType === 'sold'
  ? 'Sold On'
  : 'Leased On'

 return (
  <Drawer visible onClose={onHide} width={500} title="Filter">
   <Form
    initialValues={{
     ...proFilterIntialState,
     price_range:
      selectedType === 'leased' || selectedType === 'rent'
       ? { min: 0, max: 10000 }
       : { min: 0, max: 5000000 },
    }}
    form={form}
    layout="vertical"
   >
    <Row gutter={[15, 15]}>
     {(searchType === 'city' || searchType === 'community') && (
      <Col span={24}>
       <div
        className="d-flex align-items-center justify-content-between"
        style={{
         border: '1px solid #ccc',
         padding: '10px',
         borderRadius: '8px',
         fontSize: '13px',
        }}
       >
        <div className="d-flex align-items-center">
         <div>
          <i className="fas fa-search" style={{ fontSize: '18px' }} />
         </div>
         <div style={{ marginLeft: '20px' }}>
          <span className="d-block" style={{ fontWeight: '500' }}>
           {selectedCity}
          </span>
          <span className="d-block" style={{ color: 'gray' }}>
           {searchType}
          </span>
         </div>
        </div>{' '}
        <div>
         <BtnAnch
          onClick={() => {
           setSearch('')
           setSearchType(null)
          }}
         >
          <i
           className="fas fa-times"
           style={{ fontSize: '15px', marginRight: '5px' }}
          />
         </BtnAnch>
        </div>
       </div>
      </Col>
     )}
     <Col span={24}>
      <Form.Item name="price_range" label="Price Range">
       <PropertyPriceRanger
        min={0}
        max={5000000}
        rangeMode={
         filterState?.listing_type === 'rent' ||
         filterState?.listing_type === 'leased'
          ? 'Lease'
          : 'Sale'
        }
       />
      </Form.Item>
     </Col>

     {filtersData?.map?.((i, n) => (
      <Col span={24} key={n}>
       <Form.Item
        name={
         i.key === 'Property Category'
          ? 'property_type'
          : i?.key?.toLowerCase()?.split(' ').join('_')
        }
        label={i.key}
        getValueFromEvent={(e) => {
         if (i.key === 'Property Category') {
          onChangePropertyType?.()
         }
         return e
        }}
       >
        <Select
         allowClear
         placeholder={`Select ${i?.key}`}
         options={i.value?.map((j, k) => ({ label: j, value: j }))}
         getPopupContainer={(trigger) => trigger.parentNode}
         {...((i?.key === 'Property Category' || i?.key === 'Building Type') &&
         type !== 'preconstruction'
          ? {
             mode: 'multiple',
             maxTagCount: 3,
            }
          : {})}
        />
       </Form.Item>
      </Col>
     ))}

     <Col span={24}>
      <Form.Item name="beds" label="Bedrooms">
       <RadioFormItem />
      </Form.Item>
     </Col>

     {type !== 'preconstruction' && (
      <Col span={24}>
       <Form.Item name="baths" label="Bathrooms">
        <RadioFormItem />
       </Form.Item>
      </Col>
     )}
     {type !== 'preconstruction' && (
      <Col span={24}>
       <Form.Item name="d_o_m" label={domLabel}>
        <Select
         placeholder={domLabel}
         allowClear
         options={
          selectedType === 'sale' ||
          selectedType === 'rent' ||
          selectedType === 'open-house'
           ? FilterDaysOnMarket
           : FilterSoldLeasedDOM
         }
         getPopupContainer={(trigger) => trigger.parentNode}
        />
       </Form.Item>
      </Col>
     )}

     {type === 'preconstruction' && (
      <Col span={24}>
       <Form.Item name="completion_year" label="Completion Year">
        <Select
         placeholder="Select Completion Year"
         allowClear
         options={Array.from({ length: 4 }, (_, index) => {
          const year = moment().year() + index
          return {
           label: index < 3 ? `${year}` : `${year}+`,
           value: index < 3 ? `${year}` : 'Later',
          }
         })}
         getPopupContainer={(trigger) => trigger.parentNode}
        />
       </Form.Item>
      </Col>
     )}

     {type === 'preconstruction' && (
      <Col span={24}>
       <label>Sqft</label>
       <div className="d-flex pt-2">
        <Form.Item name="min_sqft" className="pe-3">
         <BaseNumberInput className="w-100" placeholder="Min Sqft" />
        </Form.Item>
        <Form.Item name="max_sqft" className="pl-3">
         <BaseNumberInput className="w-100" placeholder="Max Sqft" />
        </Form.Item>
       </div>
      </Col>
     )}

     <Col span={12}>
      <BaseButton
       customClass="secondary-btn"
       buttonType="secondary"
       onClick={() => {
        onHide()
        changeListingType(selectedType)
       }}
       block
      >
       Reset
      </BaseButton>
     </Col>
     <Col span={12}>
      <BaseButton buttonType="primary" onClick={handleSubmit} block>
       Apply
      </BaseButton>
     </Col>
    </Row>
   </Form>
  </Drawer>
 )
}

export default ProFilterBlock

const RadioFormItem: FC<{
 value?: string
 onChange?: (val?: string) => void
}> = ({ value, onChange }) => {
 return (
  <Radio.Group
   buttonStyle="solid"
   size="large"
   className="radio-filter"
   value={value}
  >
   {FilterBedLists?.map?.((i, k) => (
    <Radio.Button
     onClick={(e: any) => {
      if (value === e?.target?.value) {
       onChange?.(undefined)
      } else {
       onChange?.(e?.target?.value)
      }
     }}
     className="radio-filter"
     key={k}
     value={i.value}
    >
     {i.label}
    </Radio.Button>
   ))}
  </Radio.Group>
 )
}

import { Col, Row } from 'antd'
import { globalConfirm } from 'components/elements/global'
import AssignAlertModal from 'components/shared/Modals/AssignAlertModal'
import { leadApi, useWithdrawAlertLeadMutation } from 'features/leads/api'
import { TALertSimple } from 'features/leads/types'
import { useAppDispatch } from 'hooks'
import { FC, useState } from 'react'
import ProInnerCard from '../../ProInnerCard'
import SimpleBlockItem from '../../SimpleBlockItem'

const NewListingAlertBox: FC<{
 nla?: TALertSimple[]
 leadId?: any
 isTransferred?: boolean
}> = ({ nla, leadId, isTransferred }) => {
 const [showAssignAlert, setShowAssignAlert] = useState<{
  type?: 'new' | 'sold'
  show?: boolean
 }>({ show: false, type: 'new' })

 const d = useAppDispatch()

 const [withdrawAlertLead] = useWithdrawAlertLeadMutation()

 const refreshLead = () => d(leadApi.util.invalidateTags(['LEAD']))

 const handleRemoveAlert = (id: any) => {
  globalConfirm({
   content: 'Are you sure want remove Alert from this Lead?',
   onOk: async () => {
    await withdrawAlertLead({ lead_id: leadId, alert_id: id }).unwrap()
    refreshLead()
   },
  })
 }

 return (
  <div>
   <ProInnerCard
    emptyText="New Listing Alert is Empty"
    isEmpty={!nla || nla?.length === 0}
    onCLickAdd={
     isTransferred
      ? () =>
         setShowAssignAlert({
          show: true,
          type: 'new',
         })
      : undefined
    }
    title="New Listing Alert"
   >
    <Row gutter={[15, 15]}>
     {nla &&
      nla.map((item, i) => (
       <Col span={24} key={i}>
        <SimpleBlockItem
         onClose={isTransferred ? () => handleRemoveAlert(item.id) : undefined}
         text={item.name}
        />
       </Col>
      ))}
    </Row>
   </ProInnerCard>
   {showAssignAlert.show && (
    <AssignAlertModal
     type={showAssignAlert.type}
     leadId={leadId}
     onSuccess={refreshLead}
     //  selectedKeys={[leadId]}
     onCancel={() =>
      setShowAssignAlert({
       show: false,
       type: 'new',
      })
     }
     visible={showAssignAlert.show}
    />
   )}
  </div>
 )
}

export default NewListingAlertBox

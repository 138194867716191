import {
 Input,
 InputRef,
 message,
 Modal,
 ModalFuncProps,
 notification,
 Select,
 Tooltip,
} from 'antd'
import { IconType } from 'antd/lib/notification'
import {
 CSSProperties,
 FC,
 ReactNode,
 useEffect,
 useRef,
 useState,
} from 'react'
import { MergeFieldData } from 'static-data'
import { FCC } from 'types'

interface globalToasterProps {
 content?: string
 type?: 'info' | 'success' | 'error' | 'warning' | 'loading'
}

// const disableClass:CSSProperties = {}

export const globalConfirm = ({
 onOk,
 onCancel,
 content,
 okText = 'Confirm',
 cancelText = 'Cancel',
 ...res
}: ModalFuncProps): void => {
 Modal.confirm({
  ...res,
  okText,
  cancelText,
  className: 'mts-confirm',
  centered: true,
  title: 'Are you sure?',
  icon: (
   <div className="con-icon-block">
    <i className="far fa-exclamation-triangle" />
   </div>
  ),
  onOk,
  onCancel,
  content,
 })
}

export const globalToaster = ({
 content,
 type = 'info',
}: globalToasterProps): void => {
 message.open({
  duration: 2,
  content,
  type,
 })
}

type globalAlertProps = {
 type?: IconType
 content: string
}

export const globalAlert = ({
 content,
 type = 'info',
}: globalAlertProps): void => {
 notification.open({ type, message: content })
}

export const BtnAnch: FCC<{
 tooltipText?: string
 icon?: ReactNode
 loading?: boolean
 style?: CSSProperties
 link?: string
 className?: string
 onClick?: () => void
 title?: string
 authRequired?: boolean
}> = ({
 children,
 className,
 onClick,
 title,
 style,
 loading = false,
 icon,
 tooltipText,
}) => {
 const loader = <i className="fa fa-spin fa-spinner" />
 return (
  <Tooltip overlay={tooltipText ?? null}>
   <a
    style={style}
    title={title}
    href="#"
    className={` ${className ?? ''}`}
    onClick={(e) => {
     e.preventDefault()
     onClick?.()
    }}
   >
    {icon ? (
     <>{loading ? loader : icon}</>
    ) : (
     <>
      {children}
      {loading && loader}
     </>
    )}
   </a>
  </Tooltip>
 )
}

export const SubjectInput: FC<{
 type?: 'page' | 'inner'
 onChange?: (val?: string) => void
 value?: string
}> = ({ type, onChange, value }) => {
 const inputRef = useRef<InputRef>(null)
 const [uValue, setValue] = useState(value ?? '')
 // const [cursorPosi, setCursorPosi] = useState<number>()

 useEffect(() => {
  onChange?.(uValue)
 }, [onChange, uValue])

 useEffect(() => {
  if (value) {
   setValue(value)
  }
 }, [value])

 return (
  <Input
   ref={inputRef}
   onChange={(e) => setValue(e.target.value)}
   value={uValue}
   size={type === 'page' ? 'middle' : 'small'}
   addonAfter={
    <Select
     value="Merge Field"
     onChange={(val) => {
      const ref = inputRef?.current
      ref?.focus()
      const start = uValue.substring(0, ref?.input?.selectionStart || 0)
      const end = uValue.substring(ref?.input?.selectionStart || 0)
      const text = start + val + end
      setValue(text)
      // setCursorPosi((start?.length || 0) + `${val}`.length)
     }}
     options={MergeFieldData}
     placeholder="Merge Field"
     style={{ width: 200 }}
    />
   }
  />
 )
}

export const FaIcon: FC<{
 type?: 'fas' | 'fal' | 'far' | 'fab'
 className?: string
 icon?: string
}> = ({ type = 'fas', icon = '', className = '' }) =>
 icon ? <i className={`${type} ${icon} ${className}`} /> : null

FaIcon.defaultProps = {
 className: '',
 icon: '',
 type: 'fas',
}

import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import useAuth from 'hooks/useAuth'
import RealtorsList from './RealtorsList'
import RealtorDetail from './RealtorDetail'

export const RealtorsRoutes: FC = () => {
 const { user } = useAuth()
 if (user?.account_type === 'Realtor') {
  return <Navigate replace to="/admin/" />
 }
 return (
  <Routes>
   <Route path="" element={<RealtorsList />} />
   {/* <Route path="details/:id" element={<RealtorDetail />} /> */}
  </Routes>
 )
}

import { Editor } from '@tinymce/tinymce-react'
import { Spin } from 'antd'
import {
 useGetEmailTemplateNamesQuery,
 useUploadTinyFileMutation,
} from 'features/settings'
import { useGlobal } from 'raducer/global'
import React from 'react'

type EMailTextEditorProps = {
 value?: string
 onChange?: (val: any) => void
 mergfield?: boolean
 emailTemplate?: boolean
 onSelectTemplate?: (id?: string) => void
 precondisable?: boolean
}

const APIKEY = process.env.REACT_APP_EDITOR_API || ''
const toolbar = `undo redo | mergfield | templates | bold italic underline | fontsizeselect | formatselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | link  | fontselect |  numlist bullist checklist  |  code image fullpage 
  
   `

const InternalTextEditor: React.ForwardRefRenderFunction<
 Editor,
 EMailTextEditorProps
> = (
 { onChange, value, mergfield, emailTemplate, onSelectTemplate, precondisable },
 ref,
) => {
 const [uploadImg] = useUploadTinyFileMutation()
 const { global } = useGlobal()
 const { data, isLoading } = useGetEmailTemplateNamesQuery(null, {
  skip: !emailTemplate,
 })

 if ((emailTemplate && isLoading) || global.body_mergefield?.length === 0) {
  return (
   <div className="full-center">
    <Spin size="small" />
   </div>
  )
 }

 return (
  <Editor
   key="Editor-email2"
   disabled={precondisable && true}
   value={value}
   onEditorChange={onChange}
   ref={ref}
   apiKey={APIKEY}
   init={{
    content_css: '/css/content.min.css',
    plugins: ['code', 'image', 'link', 'fullpage'],

    menubar: false,
    toolbar,
    toolbar_location: 'bottom',
    images_upload_handler: async (blobInfo, success, failure) => {
     const formData = new FormData()
     formData.append('file', blobInfo.blob(), blobInfo.filename())
     try {
      const data = await uploadImg(formData).unwrap()
      success(data)
     } catch (error) {
      failure('Upload Image Error')
     }
    },
    setup: (editor) => {
     if (mergfield) {
      editor.ui.registry.addMenuButton('mergfield', {
       type: 'menubutton',

       tooltip: 'Merge Field',
       text: 'Merge Field',
       fetch: (callback) => {
        callback(
         global && global.body_mergefield && global.body_mergefield.length > 0
          ? global?.body_mergefield.map((v) => ({
             onAction: () =>
              editor.editorCommands.execCommand(
               'mceInsertContent',
               false,
               v.id,
              ),
             type: 'menuitem',
             value: `${v.id}`,
             text: v.name,
            }))
          : [],
        )
       },
      })
     }
     if (emailTemplate && data && data?.length > 0) {
      editor.ui.registry.addMenuButton('templates', {
       type: 'menubutton',
       tooltip: 'Templates',
       text: 'Templates',

       fetch: (callback) => {
        callback(
         data?.map((v) => ({
          onAction: () => {
           onSelectTemplate?.(v.id)
          },
          text: v.name,
          value: v.id,
          type: 'menuitem',
         })),
        )
       },
      })
     }
    },
   }}
  />
 )
}

const EmailTextEditor = React.forwardRef<Editor, EMailTextEditorProps>(
 InternalTextEditor,
)
EmailTextEditor.defaultProps = {
 mergfield: true,
 emailTemplate: true,
 precondisable: false,
}
export default EmailTextEditor

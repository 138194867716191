import { Col, Empty, Row } from 'antd'
import { InfinityPagination } from 'components/elements/BaseInfinityScroll'
import { BlockLoader } from 'components/elements/Loader'
import { TPropertyListing } from 'features/property'
import { FC } from 'react'
import { useProListing } from 'features/property/provider/ProListingProvider'
import { globalToaster } from 'components/elements/global'
import PropertyCard from '../PropertyCard'

const PropertyList: FC<{
 selection?: boolean
 data?: TPropertyListing[]
 isLoading?: boolean
 hasMore?: boolean
 fetchMore?: () => void
 isPreCon?: boolean
}> = ({ data, fetchMore, hasMore, isLoading, selection, isPreCon = false }) => {
 const { selectedPro, setSelectedPro } = useProListing()
 if (isLoading) {
  return <BlockLoader />
 }
 if (!data || (data && data?.length === 0)) {
  return <Empty description="Property not found" />
 }

 const onSelectPro = (value: any[]) => {
  if (value && value.length > 20) {
   globalToaster({
    type: 'error',
    content: 'You can select maximum 20 items at a time',
   })
  } else {
   setSelectedPro(value ?? [])
  }
 }

 return (
  <div className="pro-listing-block" style={{ overflow: 'auto' }}>
   <InfinityPagination useWindow={false} loadMore={fetchMore} hasMore={hasMore}>
    <Row gutter={[15, 15]}>
     {data?.length > 0 &&
      data?.map((item, i) => (
       <Col span={12} key={i}>
        <PropertyCard
         checked={
          selectedPro &&
          selectedPro.length > 0 &&
          !!selectedPro.find((v) => v.ml_num === item.ml_num)
         }
         selection={selection}
         item={{
          ...item,
          ...(isPreCon ? { listing_type: 'preconstruction' } : {}),
         }}
         onChange={() => {
          if (
           selectedPro &&
           selectedPro.length > 0 &&
           selectedPro.find((v) => v.ml_num === item.ml_num)
          ) {
           onSelectPro?.(selectedPro.filter((v) => v.ml_num !== item.ml_num))
          } else {
           onSelectPro?.([
            ...(selectedPro || []),
            {
             ...item,
             ...(isPreCon ? { listing_type: 'preconstruction' } : {}),
            },
           ])
          }
         }}
        />
       </Col>
      ))}
    </Row>
   </InfinityPagination>
  </div>
 )
}

export default PropertyList

import { Card, Collapse, Empty, Space } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import { SvgIncomingCall } from 'components/icons'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TContentCall, TUserActivityCall } from 'features/leads/types'
import useFetchPage from 'hooks/useFetchPage'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { formatDate } from 'utils/functions'
import useFetchPageNew from 'hooks/useFetchpageNew'
import AllActivity from './AllActivity'
import ActivityLayout from './components/ActivityLayout'

const CallActivity: FC = () => {
 const { leadId } = useLeadDetails()
 const [detailData, setDetailData] = useState({})

 const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
  useFetchPageNew<TUserActivityCall>(`lead/profile/${leadId}`, {
   body: { type: 'calllog' },
   method: 'post',
  })
 return (
  <ActivityLayout
   fetchDetail={(val) =>
    setDetailData({
     ccontent: flatData?.[val?.index]?.ccontent,
     cduration: flatData?.[val?.index]?.cduration,
     crecord: flatData?.[val?.index]?.crecord,
    })
   }
   totalCount={total}
   totalTitle="Call Log"
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => ({
    titleNode: (
     <h3 className="mb-0 mt-0">
      {val?.cfrom_name} <i className="fal fa-angle-right" /> {val?.cto_name}
     </h3>
    ),
    updated: val?.updated,
   })}
  />
 )
}
export default CallActivity
// const { Panel } = Collapse
// const CallActivity: FC = () => {
//  const { leadId } = useLeadDetails()
//  const {
//   flatData,
//   isLoading,
//   fetchMore,
//   hasMore,
//   isRefetching,
//   refetch,
//   total,
//  } = useFetchPageNew<TContentCall>(`lead/profile/${leadId}`, {
//   body: { type: 'calllog' },
//   method: 'post',
//  })

//  if (isLoading) {
//   return <BlockLoader />
//  }

//  if (!flatData || flatData.length === 0) {
//   return (
//    <div className="no-data-empty-compo">
//     <Empty
//      image={<i className="fal fa-phone-alt no-data-image" />}
//      description={<span className="no-data-heading">No Call History</span>}
//     >
//      <p className="no-data-description">
//       No results found related to Call history
//      </p>
//     </Empty>
//    </div>
//   )
//  }

//  const ConvertTime = (n: any) => {
//   const num = n
//   const hours = num / 60
//   const rhours = Math.floor(hours)
//   const minutes = (hours - rhours) * 60
//   const rminutes = Math.round(minutes)
//   return num < 60
//    ? `${rminutes}minutes`
//    : `${rhours}hours and${rminutes}minutes`
//  }

//  return (
//   <Card
//    //  title="Calls"
//    //  title={`Calls ${flatData?.length > 0 ? `(${flatData?.length})` : ''}`}
//    title={`Calls (${total})`}
//    loading={isLoading}
//   >
//    <LoeadMorePagination
//     fethMore={fetchMore}
//     hasMore={hasMore}
//     loading={isRefetching}
//    >
//     <Collapse className="mts-collapse activity-collaps">
//      {flatData &&
//       flatData.map((item, i) => (
//        <Panel
//         showArrow={false}
//         key={i}
//         header={
//          <AvatarStatus
//           titleNode={
//            <h5 className="text-capitalize">
//             {item.cfrom_name}
//             <i className="fal fa-angle-right" /> {item.cto_name}
//            </h5>
//           }
//           avatar={{
//            size: 40,
//            icon: <SvgIncomingCall />,
//           }}
//           content={
//            item.cupdated_at ? (
//             <Space>
//              <span className="text-muted">
//               {' '}
//               {/* {item.cupdated_at ? moment(item.cupdated_at).fromNow() : ''} */}
//               {/* {moment(item.cupdated_at).format('DD-MM-YYYY hh:mm A')} */}
//               {/* {formatDate?.(item.cupdated_at)} */}
//               {formatDate?.(item.updated)}
//              </span>
//              {item.cduration && Number(item.cduration) !== 0 && (
//               <span className="text-muted">{ConvertTime(item.cduration)} </span>
//              )}
//             </Space>
//            ) : (
//             ''
//            )
//           }
//          />
//         }
//        >
//         <Space>
//          <InsidePanelForCall data={item} />
//         </Space>
//        </Panel>
//       ))}
//     </Collapse>
//    </LoeadMorePagination>
//   </Card>
//  )
// }

// export default CallActivity

// const InsidePanelForCall: FC<{ data?: TContentCall }> = ({ data }) => {
//  if (data?.ccontent === null && Number(data?.cduration) === 0) {
//   return <h3>No Details Found ! !</h3>
//  }

//  return (
//   <div>
//    {data?.cduration && Number(data.cduration) !== 0 && (
//     <div>
//      <audio controls>
//       <track src={data?.crecord} kind="captions" />
//       {/* <source src={data?.crecord} /> */}
//      </audio>
//     </div>
//    )}
//    {data?.ccontent !== null && <div>{data?.ccontent}</div>}
//   </div>
//  )
// }

import { Spin } from 'antd'
import React, { FC } from 'react'
import { useParams } from 'react-router'
import { useGetActionPlanByIdQuery } from '../api'
import ActionPlanForm from '../components/ActionPlanForm'
import ActionFormProvider from '../providers/ActionFormProvider'

const Edit: FC<{ onCancel: () => void; id: string }> = ({ onCancel, id }) => {
 const { data, isFetching } = useGetActionPlanByIdQuery(
  {
   id,
  },
  { skip: !id, refetchOnFocus: true },
 )
 return (
  <Spin spinning={isFetching}>
   <ActionFormProvider
    editId={id}
    editData={
     !isFetching
      ? {
         name: data?.name,
         reply_flag: data?.reply_flag === 'Yes',
         steps:
          data?.steps && data.steps.length > 0
           ? data.steps.map((v) => ({
              id: v.id,
              action: v.action_name,
              run_on: v.run_on ?? 0,
              param1: v.param_1 ?? '',
              param2: v.param_2 ?? '',
             }))
           : [],
        }
      : null
    }
    mode="EDIT"
   >
    <ActionPlanForm onCancel={onCancel} />
   </ActionFormProvider>
  </Spin>
 )
}

export default Edit

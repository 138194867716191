import { Card, Dropdown, Input } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import PropertyPriceRanger from './PropertyPriceRanger'

const PriceRangerDropDown: FC<{
 onChange?: (val?: { min?: any; max?: any }) => void
 value?: { min?: any; max?: any }
}> = ({ value, onChange }) => {
 const [show, setShow] = useState(false)
 const [inputVal, setInputVal] = useState<{ min?: any; max?: any }>()

 useEffect(() => {
  if (value && value.max && value.min !== undefined && value?.min !== null) {
   setInputVal(value)
  }
 }, [value])

 useEffect(() => {
  let mouted = true

  return () => {
   mouted = false
  }
 }, [inputVal])

 return (
  <Dropdown
   onVisibleChange={(v) => setShow(v)}
   overlayStyle={{ width: '400px' }}
   trigger={['click']}
   visible={show}
   overlay={
    <div>
     <Card className="p-2 w-100">
      <PropertyPriceRanger
       max={5000000}
       min={100}
       value={value}
       onChange={(v) => {
        onChange?.(v)
        setInputVal(v)
       }}
      />
     </Card>
    </div>
   }
  >
   <Input
    placeholder="Select Price Range"
    readOnly
    value={`${Number(inputVal?.min).toFixed()}-${Number(
     inputVal?.max,
    ).toFixed()}`}
   />
  </Dropdown>
 )
}

export default PriceRangerDropDown

import { Modal, Select } from 'antd'
import BaseSelector, {
 BaseSelectorOption,
} from 'components/elements/BaseSelector'
import { LoadMoreSelect } from 'components/elements/Form'
import { TGetAlertName, useGetAlertNamesQuery } from 'features/alert'
import { useAssignAlertLeadMutation } from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import { useMemo, FC, useState } from 'react'

// type AssignAlertProp = {
//  type?: 'new' | 'sold'
//  leadId?: any
//  onSuccess?: () => void
//  visible: boolean
//  onCancel: () => void
// }

// const AssignAlertModal: FC<AssignAlertProp> = ({
//  leadId,
//  onSuccess,
//  visible,
//  onCancel,
//  type = 'new',
// }) => {
//  const [searchLeadKey, setLeadKey] = useState('')
//  const [selected, setSelected] = useState<BaseSelectorOption[]>([])
//  const [selectedIds, setSelectedIds] = useState<BaseSelectorOption[]>([])

//  const { flatData, isLoading, hasMore, fetchMore, isRefetching } =
//   useFetchPage<TGetAlertName>('get-alert-name', {
//    method: 'post',
//    body: useMemo(
//     () => ({
//      search: searchLeadKey,
//      lead_id: leadId ?? '',
//      fead: [type === 'sold' ? 'sold' : 'forsale'],
//     }),
//     [leadId, searchLeadKey],
//    ),
//   })

//  const [assignAlertLead, { isLoading: submitLoading }] =
//   useAssignAlertLeadMutation()

//  const handleSubmit = async (): Promise<void> => {
//   selectedIds &&
//    (await assignAlertLead({
//     lead_id: [leadId],
//     alert_id: selectedIds,
//    }).unwrap())
//   handleClose?.()
//   onSuccess?.()
//  }

//  const handleClose = () => {
//   onCancel?.()
//   setSelected([])
//   setSelectedIds([])
//  }

//  return (
//   <Modal
//    okButtonProps={{ disabled: selectedIds?.length === 0 }}
//    confirmLoading={submitLoading}
//    onOk={handleSubmit}
//    forceRender
//    okText="Submit"
//    onCancel={onCancel}
//    visible={visible}
//    title="Assign Alert"
//   >
//    <BaseSelector
//     isLoading={isLoading}
//     hasMore={hasMore}
//     fetchMore={fetchMore}
//     value={selected}
//     showSearch
//     onChange={(val) => {
//      setSelected(val)
//      setSelectedIds(val?.map((i) => i.id))
//     }}
//     options={flatData?.map((v) => ({
//      id: v.id,

//      title: v.name,
//     }))}
//     onSearch={setLeadKey}
//    />
//   </Modal>
//  )
// }

// export default AssignAlertModal

type AssignAlertProp = {
 type?: 'new' | 'sold'
 leadId?: any
 onSuccess?: () => void
 selectedKeys?: any[]
 visible: boolean
 onCancel: () => void
}

const AssignAlertModal: FC<AssignAlertProp> = ({
 leadId,
 onSuccess,
 visible,
 onCancel,
 selectedKeys,
 type = 'new',
}) => {
 //  const { data, isLoading } = useGetAlertNamesQuery({
 //   lead_id: leadId ?? '',
 //   fead: [type === 'sold' ? 'sold' : 'forsale'],
 //  })

 //  const [selectedAP, setAP] = useState([])
 const [searchKey, setSearch] = useState('')
 const { flatData, isLoading, hasMore, fetchMore, isRefetching } =
  useFetchPage<TGetAlertName>('get-alert-name', {
   method: 'post',
   body: useMemo(
    () => ({
     search: searchKey,
     lead_id: leadId ?? '',
     fead: [type === 'sold' ? 'sold' : 'forsale'],
    }),
    [leadId, searchKey],
   ),
  })

 const [assignAlertLead, { isLoading: submitLoading }] =
  useAssignAlertLeadMutation()
 // const moutation = usePostData<AssignAlertLead>('assign-alert-lead', {
 //   getKey: 'get-leads',
 //   onSuccess: () => {
 //     handleClose?.()
 //   },
 // })

 const [selectedAlert, setAlert] = useState<any>(null)

 const handleSubmit = async (): Promise<void> => {
  selectedAlert &&
   (await assignAlertLead({
    lead_id: [leadId],
    alert_id: [selectedAlert],
   }).unwrap())
  handleClose?.()
  onSuccess?.()
 }

 const handleClose = () => {
  onCancel?.()
  setAlert(null)
  // selectedAP([])
 }

 return (
  <Modal
   okButtonProps={{ disabled: !selectedAlert }}
   confirmLoading={submitLoading}
   onOk={handleSubmit}
   forceRender
   okText="Submit"
   onCancel={onCancel}
   visible={visible}
   title="Assign Alert"
  >
   {/* <pre>{JSON.stringify(selectedAlert, null, 3)}</pre> */}
   {/* <Select
    maxTagCount={4}
    onChange={(val) => setAlert(val as any)}
    // mode="multiple"
    loading={isLoading}
    placeholder="Please select an alert"
    className="w-100"
   >
    {data &&
     data.length > 0 &&
     data.map((v) => (
      <Select.Option key={v.id} value={v.id}>
       {v.name}
      </Select.Option>
     ))}
   </Select> */}
   <LoadMoreSelect
    onChange={(val) => setAlert(val as any)}
    // mode="multiple"
    data={flatData?.map((v) => ({ value: v?.id, label: v?.name }))}
    onSearch={setSearch}
    placeholder="Please select an alert"
    hasMore={hasMore}
    fetchMore={fetchMore}
    loading={isLoading || isRefetching}
   />
  </Modal>
 )
}

export default AssignAlertModal

import { Card, Collapse, Empty } from 'antd'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BlockLoader } from 'components/elements/Loader'
import { SvgTextIcon } from 'components/icons'
import { useLeadDetails } from 'features/leads/providers/LeadDetailsProvider'
import { TContentText, TUserActivityTxtLog } from 'features/leads/types'
import useFetchPage from 'hooks/useFetchPage'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { formatDate } from 'utils/functions'
import useFetchPageNew from 'hooks/useFetchpageNew'
import AllActivity from './AllActivity'
import ActivityLayout from './components/ActivityLayout'

const TextActivity: FC = () => {
 const { leadId } = useLeadDetails()
 const [detailData, setDetailData] = useState({})

 const { flatData, isLoading, fetchMore, hasMore, isRefetching, total } =
  useFetchPageNew<TUserActivityTxtLog>(`lead/profile/${leadId}`, {
   body: { type: 'textlog' },
   method: 'post',
  })

 return (
  <ActivityLayout
   fetchDetail={(val) =>
    setDetailData({ sbody: flatData?.[val?.index]?.sbody })
   }
   totalCount={total}
   totalTitle="SMS"
   listFetchMore={fetchMore}
   listHasMore={hasMore}
   listisRefetching={isRefetching}
   flatData={flatData}
   listIsLoading={isLoading}
   detailData={detailData}
   setItemCard={(val: any) => ({
    titleNode: (
     <h3 className="mb-0 mt-0">
      {val?.sfrom_name} <i className="fal fa-angle-right" /> {val?.sto_name}
     </h3>
    ),
    updated: val?.updated,
    estatus: val?.sstatus,
   })}
  />
 )
}
export default TextActivity

// const { Panel } = Collapse
// const TextActivity: FC = () => {
//  const { leadId } = useLeadDetails()
//  //  const { fetchMore, isLoading, hasMore, isRefetching, flatData, total } =
//  //   useFetchPage<TContentText>(`lead/profile/react/${leadId}`, {
//  //    method: 'post',
//  //    body: { type: ['textlog'] },
//  //   })

//  const { isLoading, flatData, fetchMore, hasMore, isRefetching, total } =
//   useFetchPageNew<TContentText>(`lead/profile/${leadId}`, {
//    body: { type: 'textlog' },
//    method: 'post',
//   })

//  if (isLoading) {
//   return <BlockLoader />
//  }
//  if (!flatData || flatData.length === 0) {
//   return (
//    <div className="no-data-empty-compo">
//     <Empty
//      image={<i className="far fa-comment-alt no-data-image" />}
//      description={<span className="no-data-heading">No SMS History</span>}
//     >
//      <p className="no-data-description">
//       No results found related to SMS history
//      </p>
//     </Empty>
//    </div>
//   )
//  }

//  return (
//   <Card
//    //  title="Text"
//    //  title={`SMS ${flatData?.length > 0 ? `(${flatData?.length})` : ''}`}
//    title={`SMS (${total})`}
//    loading={isLoading}
//   >
//    <LoeadMorePagination
//     fethMore={fetchMore}
//     hasMore={hasMore}
//     loading={isRefetching}
//    >
//     <Collapse className="mts-collapse activity-collaps">
//      {flatData &&
//       flatData.map((item, i) => (
//        <Panel
//         showArrow={false}
//         key={i}
//         header={
//          <AvatarStatus
//           titleNode={
//            <h5 className="text-capitalize">
//             {item.sfrom_name} <i className="fal fa-angle-right" />{' '}
//             {item.sto_name}
//            </h5>
//           }
//           avatar={{
//            size: 40,
//            icon: <SvgTextIcon />,
//           }}
//           content={
//            item.supdated_at ? (
//             <span className="text-muted">
//              {' '}
//              {/* {item.supdated_at ? moment(item.supdated_at).fromNow() : ''} */}
//              {/* {moment(item.supdated_at).format('DD-MM-YYYY hh:mm A')} */}
//              {formatDate?.(item.supdated_at)}
//             </span>
//            ) : (
//             ''
//            )
//           }
//          />
//         }
//        >
//         <div style={{ display: 'flex' }}>
//          <div style={{ marginRight: '5px', fontWeight: '600' }}>Content </div>
//          <div dangerouslySetInnerHTML={{ __html: `${item.sbody}` }} />
//         </div>
//        </Panel>
//       ))}
//     </Collapse>
//    </LoeadMorePagination>
//   </Card>
//  )
// }

// export default TextActivity

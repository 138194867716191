import { Select, Input } from 'antd'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import { useGetTextTemplateNamesQuery } from 'features/settings'
import { useGlobal } from 'raducer/global'
import { FC, useRef, useState, useEffect } from 'react'

export const SMSInputArea: FC<{
 template?: boolean
 onChange?: (val?: any) => void
 value?: string
 onSelectTemp?: (val?: any) => void
}> = ({ value, onSelectTemp, onChange, template = false }) => {
 const { data: textTemplates, isLoading: tempLoading } =
  useGetTextTemplateNamesQuery(null, { skip: !template })
 const inputRef = useRef<TextAreaRef>(null)
 const { global } = useGlobal()

 //  useEffect(() => {
 //   if (uValue) {
 //    onChange?.(uValue)
 //   }
 //  }, [onChange, uValue])

 //  useEffect(() => {
 //   setValue(value ?? '')
 //  }, [value])

 return (
  <div className="sms-text-area">
   <ul className="sm-menubar">
    {template && (
     <li>
      {textTemplates && textTemplates.length > 0 && (
       <Select
        size="small"
        onChange={(val) => {
         onSelectTemp?.(val)
        }}
        loading={tempLoading}
        placeholder="Templates"
       >
        {textTemplates.map((v) => (
         <Select.Option key={v.id} value={v.id}>
          {' '}
          {v.name}
         </Select.Option>
        ))}
       </Select>
      )}
     </li>
    )}
    <li>
     <Select
      value="Merge Field"
      size="small"
      onChange={(val) => {
       const ref = inputRef?.current
       ref?.focus()
       const start = value?.substring(
        0,
        ref?.resizableTextArea?.textArea.selectionEnd || 0,
       )
       const end = value?.substring(
        ref?.resizableTextArea?.textArea.selectionEnd || 0,
       )
       const text = start + val + end
       onChange?.(text)
       // setCursorPosi((start?.length || 0) + `${val}`.length)
      }}
      placeholder="Templates"
     >
      {global && global.body_mergefield && global.body_mergefield.length > 0
       ? global.body_mergefield.map((v) => (
          <Select.Option key={v.id} value={v.id}>
           {' '}
           {v.name}
          </Select.Option>
         ))
       : []}
     </Select>
    </li>
   </ul>
   <Input.TextArea
    ref={inputRef}
    onChange={(e) => onChange?.(e.target.value)}
    value={value}
    rows={10}
   />
  </div>
 )
}

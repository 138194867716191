import { Checkbox, Col, Input, Modal, Row, Space } from 'antd'
import Item from 'antd/lib/list/Item'
import { AvatarStatus } from 'components/elements/AvatarStatus'
import { BaseButton } from 'components/elements/BaseButton'
import LoeadMorePagination from 'components/elements/BaseInfinityScroll/LoeadMorePagination'
import { BtnAnch } from 'components/elements/global'
import { BlockLoader } from 'components/elements/Loader'
import {
 TActionPlanLeads,
 useControlActionPlanMutation,
 useWithdrawActionplanMutation,
} from 'features/action-plan'
import useFetchPage from 'hooks/useFetchPage'
import { queryClient } from 'lib/react-query'
import React, { useState, FC, useMemo } from 'react'

const ActionPlanActiveModal: FC<{
 visible?: boolean
 onCancel?: () => void
 Id?: any
}> = ({ visible, onCancel, Id }) => {
 const [Ids, setIds] = useState<any[]>([])
 const [searchKey, setSearchKey] = useState('')
 const { flatData, isLoading, hasMore, fetchMore } =
  useFetchPage<TActionPlanLeads>('get-action-plan-leads', {
   method: 'post',
   body: useMemo(
    () => ({
     id: [Id],
     type: ['Running'],
     search: searchKey,
    }),
    [Id, searchKey],
   ),
  })

 const [controlAction, { isLoading: controlActionLoading }] =
  useControlActionPlanMutation()
 const [withdrawAP, { isLoading: withdrawAPLdng }] =
  useWithdrawActionplanMutation()

 const HandleControlActive = async (leadID: any[], planId?: any) => {
  await controlAction({
   action: 'Pause',
   plan_id: planId,
   lead_id: leadID,
  }).unwrap()
  queryClient.invalidateQueries('get-action-plans')
  queryClient.invalidateQueries('get-action-plan-leads')
  onCancel?.()
 }
 const HandleControlActiveStop = async (leadID: any[], planId?: any) => {
  await controlAction({
   action: 'Stop',
   plan_id: planId,
   lead_id: leadID,
  }).unwrap()
  queryClient.invalidateQueries('get-action-plans')
  queryClient.invalidateQueries('get-action-plan-leads')
  onCancel?.()
 }

 const handleDeleteAP = async (planId?: any, leadID?: any[]) => {
  await withdrawAP({
   actionplan_id: planId,
   lead_id: leadID,
  }).unwrap()

  queryClient.invalidateQueries('get-action-plan-leads')
  queryClient.invalidateQueries('get-action-plans')
  onCancel?.()
 }

 return (
  <Modal visible={visible} onCancel={onCancel} footer={false} title="Active">
   <div style={{ marginBottom: '12px' }}>
    <Input
     placeholder="Enter your search"
     value={searchKey}
     onChange={(e) => setSearchKey(e.target.value)}
    />
   </div>
   {isLoading && <BlockLoader />}
   <LoeadMorePagination
    loading={isLoading}
    hasMore={hasMore}
    fethMore={fetchMore}
   >
    {flatData &&
     flatData.length > 0 &&
     flatData.map((item, index) => (
      <AvatarStatus
       key={index}
       title={item.name}
       className="action-active-leads "
       action={
        <CheckAction
         onClick={() => {
          setIds((prv) => {
           const find = prv.find((m) => m === item.id)
           if (find) {
            const filter = prv.filter((x) => x !== item.id)
            return [...filter]
           }
           return [...prv, item.id]
          })
         }}
         checked={!!Ids.find((x) => x === item.id)}
        />
       }
      />
     ))}
   </LoeadMorePagination>
   {Ids && Ids.length > 0 && (
    <Space>
     <BaseButton
      buttonType="primary"
      onClick={() => HandleControlActive?.(Ids, Id)}
      loading={controlActionLoading}
     >
      Pause ({Ids && Ids.length})
     </BaseButton>
     <BaseButton
      buttonType="danger"
      onClick={() => HandleControlActiveStop?.(Ids, Id)}
     >
      Stop ({Ids && Ids.length})
     </BaseButton>

     <BaseButton
      buttonType="danger"
      loading={withdrawAPLdng}
      onClick={() => handleDeleteAP?.(Id, Ids)}
     >
      Delete ({Ids && Ids.length})
     </BaseButton>
    </Space>
   )}
  </Modal>
 )
}

export default ActionPlanActiveModal

export const CheckAction: FC<{
 onClick?: () => void
 checked?: boolean
 className?: string
}> = ({ checked, onClick, className }) => (
 <BtnAnch style={{ fontSize: '19px' }} className={className} onClick={onClick}>
  {' '}
  <i className={`far fa-${checked ? 'check-circle' : 'circle'}`} />{' '}
 </BtnAnch>
)

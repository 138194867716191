import { Card, Table, Tag, Tooltip } from 'antd'
import { BtnAnch } from 'components/elements/global'
import { TSSBlockList } from 'components/shared/Common'
import { baseTime } from 'lib'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { TDashboardLeadActivity } from 'types'
import { capitalizeFirstLetter } from 'utils/functions'

const RecentLead: FC<{
 loading?: boolean
 data?: TDashboardLeadActivity[]
}> = ({ data, loading }) => {
 const navigate = useNavigate()

 return (
  <Card loading={loading} className="table-card mt-3">
   <h4 className="table-ttl">Recent Lead Activity</h4>
   <Table
    rowKey="id"
    pagination={false}
    dataSource={data}
    size="small"
    columns={[
     {
      title: 'Lead name',
      width: 250,
      dataIndex: 'name',
      render: (_e, r) => (
       <BtnAnch onClick={() => navigate(`#lead-details/${r.lead.id}/profile`)}>
        <Tooltip
         placement="topLeft"
         className="dashboard-table-items"
         title={capitalizeFirstLetter(r.lead?.name)}
        >
         {capitalizeFirstLetter(r.lead?.name)}
        </Tooltip>
       </BtnAnch>
      ),
     },
     {
      title: 'Phone',
      dataIndex: 'phone_number',
      width: 200,
      render: (val, r) => (
       <div
        className={`${
         r.lead?.phone_number?.label === 'Bad' ? 'bad-label' : ''
        } dashboard-table-items`}
       >
        {r.lead?.phone_number?.number}
       </div>
      ),
     },
     {
      title: 'Email',
      dataIndex: 'email_address',
      width: 200,
      render: (val, r) => (
       <Tooltip
        placement="topLeft"
        className="dashboard-table-items"
        title={r.lead?.email_address?.email}
       >
        <div
         className={r?.lead.email_address?.label === 'Bad' ? 'bad-label' : ''}
        >
         {r.lead?.email_address?.email}
        </div>
       </Tooltip>
      ),
     },
     {
      title: 'Last Activity',
      width: 200,
      dataIndex: 'activity',
      render: (_, r) => (
       <BtnAnch
        onClick={() => navigate(`#lead-details/${r.lead.id}/timeline/all`)}
        className="last-activity"
       >
        <Tooltip
         placement="topLeft"
         className="dashboard-table-items"
         title={r.activity}
        >
         {r.activity}
        </Tooltip>
       </BtnAnch>
      ),
     },
     {
      title: 'Time',
      width: 200,
      dataIndex: 'created_at',
      render: (_e, r) => (
       <div className="dashboard-table-items">
        {baseTime(r.created_at).fromNow()}
       </div>
      ),
     },
     {
      title: 'Stage',
      width: 200,
      dataIndex: 'stages',
      render: (_e, r) =>
       r.lead?.stages?.name && (
        <div style={{ width: '180px' }}>
         <TSSBlockList
          type="lead"
          mode="stage"
          typeId={r.id ?? ''}
          data={[{ name: r.lead?.stages?.name }]}
          tooltiOptions={{
           getPopupContainer: (e) =>
            e?.parentNode?.parentNode?.parentNode?.parentNode as any,
          }}
         />
        </div>
       ),
     },
     {
      title: 'Source',
      width: 200,
      dataIndex: 'sources',
      render: (_e, r) =>
       r.lead?.sources?.name && (
        <div style={{ width: '180px' }}>
         <TSSBlockList
          type="lead"
          mode="source"
          typeId={r.id ?? ''}
          data={[{ name: r.lead?.sources?.name }]}
          tooltiOptions={{
           getPopupContainer: (e) =>
            e?.parentNode?.parentNode?.parentNode?.parentNode as any,
          }}
         />
        </div>
       ),
     },
    ]}
   />
  </Card>
 )
}

export default RecentLead

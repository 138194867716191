import { Form, Input, Modal, Select } from 'antd'
import BaseTDPicker from 'components/elements/BaseComponents/BaseTDPicker'
import {
 TAddLeadEventBody,
 useAddLeadEventMutation,
 useUpdateLeadEventMutation,
} from 'features/leads'
import { baseTime } from 'lib'

import { FC, useEffect, useMemo } from 'react'
import { taskTypes } from 'static-data'
import { TMode } from 'types'
import { eventReminder } from 'utils/functions'
import SelectLeadBlock from '../LoadMoreBlocks/SelectLeadBlock'

const formInit: TAddLeadEventBody = {
 title: '',
 date: '',
 reminder: null,
}

const AddTaskModal: FC<{
 onSuccess?: () => void
 editData?: TAddLeadEventBody
 mode?: TMode
 leadId?: any
 onCancel?: () => void
 visible?: boolean
}> = ({ onCancel, visible, leadId, mode = 'ADD', editData, onSuccess }) => {
 // eslint-disable-next-line @typescript-eslint/no-unused-vars
 // eslint-disable-next-line no-empty-pattern

 const [addTask, { isLoading: addLoading }] = useAddLeadEventMutation()
 const [updateLeadEvent, { isLoading: updateLoading }] =
  useUpdateLeadEventMutation()

 const [form] = Form.useForm<TAddLeadEventBody>()
 const dateValue = Form.useWatch('scheduled_at', form) as baseTime.Moment

 const handleSubmit = async () => {
  const values = await form.validateFields()
  const postData: TAddLeadEventBody = {
   ...values,
   date: baseTime(values.scheduled_at).format('YYYY-MM-DD'),
   time: baseTime(values.scheduled_at).format('HH:mm:ss'),
   lead_id: values.lead_id ?? leadId,
   feed: 'task',
  }
  if (mode === 'EDIT') {
   await updateLeadEvent({
    ...postData,
    id: editData?.id,
   }).unwrap()
   onSuccess?.()
   form.resetFields()
   onCancel?.()
  } else {
   await addTask(postData).unwrap()
   form.resetFields()
   onSuccess?.()
   onCancel?.()
  }
 }

 const eventRemiderList = useMemo(() => eventReminder(dateValue), [dateValue])

 useEffect(() => {
  if (mode === 'EDIT' && editData) {
   form.setFieldsValue({
    title: editData.title,
    type: editData.type,
    reminder: editData.reminder,
    scheduled_at: baseTime(`${editData.date} ${editData.time}`),
    lead_id: editData.lead_id,
   })
  } else {
   form.resetFields()
  }
  return () => {
   form.resetFields()
  }
 }, [editData, form, mode])

 return (
  <Modal
   forceRender
   confirmLoading={mode === 'EDIT' ? updateLoading : addLoading}
   onOk={handleSubmit}
   okText="Save"
   cancelButtonProps={{
    className: 'ant-btn ant-btn-default ant-btn-secondary secondary-btn',
   }}
   onCancel={onCancel}
   visible={visible}
   title={`${mode === 'EDIT' ? 'Edit' : 'Add'} Task`}
  >
   <Form form={form} layout="vertical" initialValues={formInit}>
    {!leadId && (
     <Form.Item
      name="lead_id"
      rules={[{ required: true, message: 'Lead name is required' }]}
      label="Lead name"
     >
      <SelectLeadBlock formMode={mode} />
     </Form.Item>
    )}
    <Form.Item
     name="title"
     rules={[{ required: true, message: 'Title is required' }]}
     label="Title"
    >
     <Input placeholder="Enter Title" />
    </Form.Item>
    <Form.Item
     name="type"
     label="Type"
     rules={[{ required: true, message: 'Type is required' }]}
    >
     <Select placeholder="Select Type" options={taskTypes} />
    </Form.Item>
    <Form.Item
     name="scheduled_at"
     label="Date"
     rules={[{ required: true, message: 'Date is required' }]}
    >
     <BaseTDPicker disablePastTime />
    </Form.Item>
    {eventRemiderList && eventRemiderList.length > 0 && (
     <Form.Item name="reminder" label="Set Reminder">
      <Select placeholder="Set Reminder" options={eventRemiderList} />
     </Form.Item>
    )}
   </Form>
  </Modal>
 )
}

export default AddTaskModal

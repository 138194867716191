/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

import { Form, Input, UploadFile } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import {
 TSendMailBody,
 useSendEmailLeadMutation,
 useSendScheduleEmailLeadMutation,
 useSendScheduleEmailProMutation,
} from 'features/leads'
import { useSendListingEmailMutation } from 'api/baseApi'
import { useGetEmailTemplateByIdQuery } from 'features/settings'
import useAppControl from 'hooks/useAppControl'
import useAuth from 'hooks/useAuth'
import { useGlobal } from 'raducer/global'
import { FC, useEffect, useMemo, useState } from 'react'
import {
 convert2FormDataEmail,
 convetUrl2FileList,
} from 'components/shared/SendEmailForm/functions'
import EmailSubjectInput from 'components/shared/Common/EmailSubjectInput'
import SWToBarTag from 'components/elements/SWToBarTag/SWToBarTag'
import SWToBar from 'components/elements/SWToBar/SWToBar'
import { globalToaster } from 'components/elements/global'
import { SendWidgetProps } from '../index'
import SendWidgetLayout from '../Layout'
import EmailTextEditor from '../_components/EMailTextEditor'

type EmailSendWidgetProps = SendWidgetProps

const EmailSendWidget: FC<EmailSendWidgetProps> = ({
 onSendSuccess,
 mode,

 type,
 wFrom,
}) => {
 const { user } = useAuth()

 const initialValues: TSendMailBody = {
  to: [],
  from: user?.twilio_email,
  body: '',
 }

 const { global } = useGlobal()
 const { sendWidget, updateSendEmailWidget, onSuccessEmail } = useAppControl()

 const replyData = sendWidget?.replyData
 const forwardData = sendWidget?.forwardData
 const {
  widgetFrom,
  selectAll,
  filterInfo,
  unSelected,
  to,
  propFullInfo,
  showMoreLabel,
  extraBody,
  propertyData,
  proListingType,
  disableBody,
  customtSelectAll,
 } = sendWidget || {}

 const [attachmentFile, setAttachmentFile] = useState<UploadFile[]>([])

 const [templateId, setTemplateId] = useState<any>()

 const [scheduleEmail, { isLoading: sendScheduleEmailLoading }] =
  useSendScheduleEmailLeadMutation()
 const [SendScheduleEmailPro, { isLoading: SendScheduleEmailProLoading }] =
  useSendScheduleEmailProMutation()
 const [sendEmail, { isLoading: sendEmailLoading }] = useSendEmailLeadMutation()
 const [sendlistingEmail, { isLoading: ListEmailLoading }] =
  useSendListingEmailMutation()

 const {
  data: templateData,
  isLoading,
  isFetching,
 } = useGetEmailTemplateByIdQuery({ id: templateId }, { skip: !templateId })

 const [sendMailForm] = Form.useForm<TSendMailBody>()
 const watch = Form.useWatch([], sendMailForm)

 const attachmentCount = useMemo(
  () => attachmentFile.length,
  [attachmentFile.length],
 )
 const productCount = useMemo(
  () => propFullInfo?.length || 0,
  [propFullInfo?.length],
 )

 const valueToField = useMemo(() => {
  if (type === 'tag') {
   return (watch?.to as any)?.map?.((i: any) => i?.id)?.join(',')
  }
  if (Array.isArray(watch?.to)) {
   return watch?.to?.join(',')
  }
  return watch?.to
 }, [widgetFrom, mode, watch])

 const isSelectAll = useMemo(() => {
  if (customtSelectAll !== undefined) {
   return customtSelectAll !== null
  }
  return selectAll ?? false
 }, [customtSelectAll, selectAll])

 const handleClickSend = async (time?: any, date?: any) => {
  // console.log(time, date, 'time date')
  const { to: toF, ...values } = await sendMailForm.validateFields()
  // console.log(values, 'values', widgetFrom)

  if (isSelectAll !== true && toF?.length === 0) {
   globalToaster({
    type: 'error',
    content: 'Add at least one recipient',
   })
  } else {
   const formData = convert2FormDataEmail({
    ...values,

    //  ...(!selectAll
    //   ? {
    //      to:
    //       mode === 'REPLY'
    //        ? widgetFrom === 'lead'
    //          ? Array.isArray(values.to)
    //            ? values.to.join(',')
    //            : values?.to
    //          : to
    //        : widgetFrom === 'lead'
    //        ? Array.isArray(values.to)
    //          ? (values?.to as any)?.join(',')
    //          : values?.to
    //        : (values?.to as any)?.map((i: any) => i?.id)?.join(','),
    //     }
    //   : {}),
    ...(!isSelectAll ||
    (selectAll && !showMoreLabel) ||
    (isSelectAll && !showMoreLabel)
     ? {
        to: valueToField,
       }
     : {}),
    attachment: attachmentFile,
    ...(propFullInfo && propFullInfo.length > 0
     ? {
        property: propFullInfo?.map((i) => ({
         id: i?.slug,
         type: i?.listing_type,
        })),
        listing_type: proListingType,
       }
     : {}),
    ...(time || date ? { time, date } : {}),
    type,
    ...(propFullInfo ? { prop_full_info: propFullInfo } : {}),
    temp_id: templateData?.id,
    ...(selectAll && showMoreLabel ? { select_all: selectAll } : {}),
    ...(customtSelectAll && showMoreLabel ? { customtSelectAll } : {}),
    ...(unSelected && isSelectAll && showMoreLabel
     ? { unselected: unSelected }
     : {}),
    ...(filterInfo && isSelectAll && showMoreLabel
     ? { filter_info: filterInfo }
     : {}),
    ...extraBody,
   })
   //  const xhr = new XMLHttpRequest()
   //  xhr.open('POST', '/abc-mail', true)
   //  xhr.send(formData)
   if (propFullInfo && propFullInfo?.length > 0) {
    time || date
     ? await SendScheduleEmailPro(formData).unwrap()
     : await sendlistingEmail(formData).unwrap()
   } else {
    time || date
     ? await scheduleEmail(formData).unwrap()
     : await sendEmail(formData).unwrap()
   }
   onSendSuccess?.()
   onSuccessEmail?.()
  }
 }

 useEffect(() => {
  if (
   widgetFrom === 'listing' &&
   proListingType === 'preconstruction' &&
   (propFullInfo?.length ?? 0) > 1
  ) {
   sendMailForm.setFieldsValue({
    body: global?.listing_mail_content?.preconstruction_multiple,
   })
  }
 }, [sendWidget?.propFullInfo])

 useEffect(() => {
  if (templateData) {
   sendMailForm.setFieldsValue({
    subject: templateData.subject ?? '',
    body: `${templateData.body ?? ''}`,
    attachment: convetUrl2FileList(templateData.attachment),
   })
  }
 }, [templateData])

 useEffect(() => {
  if (proListingType === 'preconstruction' && (propFullInfo?.length ?? 0) > 1) {
   sendMailForm.setFieldsValue({
    body: global?.listing_mail_content?.preconstruction_multiple,
   })
  }
 }, [proListingType, propFullInfo])

 useEffect(() => {
  if (
   (!propFullInfo || (propFullInfo && propFullInfo.length === 0)) &&
   user?.signature
  ) {
   sendMailForm.setFieldsValue({
    body: user.signature,
   })
  }
 }, [user?.signature])

 useEffect(() => {
  if (to && mode !== 'FORWARD') {
   sendMailForm.setFieldsValue({
    to,
   })
  }
 }, [to])

 useEffect(() => {
  if (replyData && mode === 'REPLY') {
   sendMailForm.setFieldsValue({
    subject: `${replyData.subject}`,
    to: replyData?.to,
   })
  } else if (mode === 'FORWARD' && forwardData) {
   sendMailForm.setFieldsValue({
    subject: `${forwardData.subject}`,
    attachment: convetUrl2FileList(forwardData.attachment),
   })
   setAttachmentFile(convetUrl2FileList(forwardData.attachment))
  }
  return () => {
   sendMailForm.resetFields()
  }
 }, [mode, forwardData, replyData])

 //  const ToField = useMemo(() => {
 //   if (type === 'tag') {
 //    return <SWToBarTag type="email" />
 //   }
 //   return <SWToBar type="email" />
 //  }, [to, type])

 const toFieldValue = useMemo(() => {
  if (type === 'lead') {
   return Array.isArray(watch?.to)
    ? { to: watch?.to?.join(',') ?? [] }
    : { to: watch?.to }
  }
  return {
   to: (watch?.to as [])?.map((i: any) => i?.id)?.join(',') ?? [],
  }
 }, [type, watch])

 return (
  <SendWidgetLayout
   type={sendWidget?.type}
   proData={sendWidget?.propFullInfo}
   onUpdateProData={(val: any) => {
    updateSendEmailWidget({ propFullInfo: val })
   }}
   loading={isLoading || isFetching}
   onSchedule={(time, date) => handleClickSend(time, date)}
   scheduleLoading={SendScheduleEmailProLoading || sendScheduleEmailLoading}
   submitLoading={ListEmailLoading || sendEmailLoading}
   onSend={() => handleClickSend()}
   productCount={productCount}
   attachment={{
    count: attachmentCount,
    onChange: (v) => setAttachmentFile(v),
    value: attachmentFile,
   }}
   previewData={{
    body: watch?.body,
    subject: watch?.subject,
    from: user?.twilio_email,
    type,
    ...(propFullInfo && widgetFrom === 'listing'
     ? { prop_full_info: propFullInfo }
     : {}),
    ...(propFullInfo && propFullInfo?.length > 0 && widgetFrom === 'listing'
     ? {
        listing_type: proListingType,
        property: JSON.stringify(
         propFullInfo?.map((i) => ({
          id: i?.slug,
          type: i?.listing_type,
         })),
        ),
       }
     : {}),
    ...toFieldValue,
   }}
   widgetFrom={widgetFrom}
   from="Email"
   handleValidation={() => {
    if (
     !toFieldValue?.to ||
     (toFieldValue?.to && toFieldValue?.to.length === 0)
    ) {
     globalToaster({ type: 'error', content: 'Enter To Input' })
     return false
    }
    if (!watch?.subject) {
     globalToaster({ type: 'error', content: 'Enter Subject' })
     return false
    }
    return true
   }}
  >
   <div className="sw-form-section">
    <Form initialValues={initialValues} form={sendMailForm}>
     <Form.Item name="from" label="From">
      <Input readOnly />
     </Form.Item>

     <FormItem
      label="To"
      name="to"
      // rules={[
      //  {
      //   required: !selectAll,
      //   message: `${type === 'lead' ? 'Email' : 'Tag'} is required`,
      //  },
      // ]}
     >
      {/* {ToField} */}
      {type === 'tag' ? <SWToBarTag type="email" /> : <SWToBar type="email" />}
     </FormItem>

     <FormItem
      name="subject"
      // rules={[{ required: true, message: 'Subject is Required' }]}
     >
      <EmailSubjectInput
       mergeField={mode !== 'REPLY'}
       readOnly={mode === 'REPLY'}
      />
     </FormItem>
     {/* {disableBody?.disable && disableBody?.content ? ( */}
     {proListingType === 'preconstruction' && propFullInfo?.length === 1 ? (
      <div
       className="d-flex align-items-center justify-content-center w-100 mt-5"
       //  style={{ height: '200px' }}
      >
       <p style={{ fontSize: '15px', fontWeight: '500' }}>
        The Pre-con single listing emails will be shared as a pre-designed
        template. The compose option will not be available for such emails.
        Please use the preview option to view the template
       </p>
      </div>
     ) : (
      <FormItem name="body" className="sw-texteditor">
       <EmailTextEditor
        emailTemplate={widgetFrom !== 'listing'}
        onSelectTemplate={(id) => setTemplateId(id)}
        //  precondisable={precondisable}
       />
      </FormItem>
     )}
    </Form>
   </div>
  </SendWidgetLayout>
 )
}

export default EmailSendWidget

import { Route, Routes } from 'react-router-dom'
import { FC } from 'react'
// import Add from './Add'
// import Edit from './Edit'
import List from './List'
// import View from './View'

export const ActionPlanRoutes: FC = () => {
 return (
  <Routes>
   <Route path="" element={<List />} />
   {/* <Route path="add" element={<Add />} /> */}
   {/* <Route path="edit/:id" element={<Edit />} />
   <Route path="view/:id" element={<View />} /> */}
  </Routes>
 )
}

import { Modal } from 'antd'
import { LoadMoreSelect } from 'components/elements/Form'
import { TSelectAllB, TSelectAllP } from 'features/leads'
import useFetchPage from 'hooks/useFetchPage'
import { FC, useMemo, useState } from 'react'
import { useShareActionPlanMutation } from '../api'

const ShareAP: FC<
 TSelectAllP & {
  onCancel?: () => void
  visible?: boolean
  selectedKeys?: any[]
 }
> = ({
 visible,
 onCancel,
 selectAll,
 filterInfo,
 selectedKeys,
 unSelected,
}) => {
 const [searchKey, setSearch] = useState('')
 const [realtors, setRealtors] = useState([])

 const [shareActionPlan, { isLoading: shareActionLoading }] =
  useShareActionPlanMutation()

 const { flatData, isLoading, hasMore, fetchMore } = useFetchPage<{
  text?: string
  id?: any
 }>('search-realtor', {
  method: 'post',
  body: useMemo(
   () => ({ search: searchKey, type: 'action_plan' }),
   [searchKey],
  ),
 })

 const onSubmit = async () => {
  const body: TSelectAllB = {}
  if (selectAll) {
   body.select_all = selectAll
   body.unselected = unSelected
   body.filter_info = filterInfo
  }

  await shareActionPlan?.({
   ...body,
   ...(selectAll ? {} : { actionplan_id: JSON.stringify(selectedKeys) }),
   realtor: JSON.stringify(realtors),
  }).unwrap()
  onCancel?.()
  setRealtors([])
 }
 return (
  <Modal
   confirmLoading={shareActionLoading}
   okButtonProps={{ disabled: !realtors.length }}
   onOk={onSubmit}
   okText="Submit"
   onCancel={onCancel}
   visible={visible}
   title="Share Action Plan"
  >
   <LoadMoreSelect
    hasMore={hasMore}
    loading={isLoading}
    fetchMore={fetchMore}
    onSearch={setSearch}
    onChange={(val) => setRealtors(val as any)}
    placeholder="Search Realtor"
    mode="multiple"
    data={
     flatData ? flatData?.map((v) => ({ label: v.text, value: v.id })) : []
    }
   />
  </Modal>
 )
}

export default ShareAP

import { BaseButton } from 'components/elements/BaseButton'
import { BtnAnch } from 'components/elements/global'
import React, { FC, ReactNode } from 'react'

const SimpleBlockItem: FC<{
 actions?: ReactNode[]
 onClose?: () => void
 text?: any
 onDelete?: () => void
 onEdit?: () => void
 contentClassName?: string
 call?: boolean
 showSMS?: boolean
 onSendSMS?: () => void
 showEmail?: boolean
 onSendEmail?: () => void
}> = ({
 text,
 onDelete,
 onEdit,
 onClose,
 actions,
 contentClassName = '',
 call,
 showSMS,
 onSendSMS,
 onSendEmail,
 showEmail,
}) => (
 <div className="item-block-li">
  <span className={`content ${contentClassName}`}>{text}</span>
  <span className="action">
   {actions}
   {call && (
    <BaseButton buttonStyle="outline" size="small" buttonType="primary">
     <a href={`tel:${text}`}>
      <i className="fas fa-phone" />
     </a>
    </BaseButton>
   )}
   {showSMS && (
    <BaseButton
     buttonStyle="outline"
     onClick={onSendSMS}
     size="small"
     buttonType="primary"
     icon={<i className="fas fa-comment-alt" />}
    />
   )}
   {showEmail && (
    <BaseButton
     buttonStyle="outline"
     onClick={onSendEmail}
     size="small"
     buttonType="primary"
     icon={<i className="fas fa-envelope" />}
    />
   )}
   {onEdit && (
    <BaseButton
     buttonStyle="outline"
     onClick={onEdit}
     size="small"
     buttonType="primary"
     icon={<i className="fas fa-pencil-alt" />}
    />
   )}
   {onDelete && (
    <BaseButton
     buttonStyle="outline"
     buttonType="danger"
     onClick={onDelete}
     size="small"
     danger
     icon={<i className="fas fa-trash" />}
    />
   )}
   {onClose && (
    <BaseButton
     buttonStyle="outline"
     buttonType="danger"
     onClick={onClose}
     size="small"
     danger
     icon={<i className="fas fa-times" />}
    />
   )}
  </span>
 </div>
)
export default SimpleBlockItem

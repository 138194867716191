import { UploadFile } from 'antd/lib/upload/interface'
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import {
 TForwardData,
 TReplyData,
 TSendMailBody,
 TSendMailPro,
} from 'features/leads'
import { baseTime } from 'lib'
import { getImgData } from 'utils/functions'

export const forwardContent = ({
 date,
 from,
 to,
 subject,
 body,
 fromName,
 toName,
}: TForwardData): string => {
 return `
 <br />
 <br />
 <br />
 <br />
 <hr/>
============ Forwarded message ============ <br />
From: ${fromName} &#x3c;${from}&#x3e; <br />
To: ${toName}  &#x3c;${to}}&#x3e; <br />
Date: ${date ? baseTime(date).format('lll') : ''} <br />
Subject: ${subject} <br />
============ Forwarded message ============ <br />

${body}

    `
}

export const replyContent = ({
 date,
 to,
 toName,
 body,
}: TReplyData): string => {
 return `
 <br />
 <br />
 <br />
 <br />
 <hr/>
 ---- On ${date ? baseTime(date).format('lll') : ''}  <strong>${
  toName ?? ''
 }</strong>   &#x3c;${to}&#x3e; wrote ---- ${body}
    `
}

// export const replyContent1 = ({ date, to, toName }: TReplyData): string => {
//  return `
//     <br />
//     <br />
//     <br />
//     <br />
//     <hr/>
//     ---- On ${date ? baseTime(date).format('lll') : ''}  <strong>${
//   toName ?? ''
//  }</strong>   &#x3c;${to}&#x3e; wrote ----
//        `
// }

type TSendMailBodyU = TSendMailBody & {
 unselected?: any[]
}

export const convert2FormDataEmail = (values: TSendMailBodyU): FormData => {
 const tempAt: any[] = []
 const formData = new FormData()
 let key: keyof TSendMailBodyU

 for (key in values) {
  if (
   Object.prototype.hasOwnProperty.call(values, key) &&
   key !== 'temp_attachment'
  ) {
   if (key === 'attachment') {
    const element = values[key]
    element?.forEach((v, i) => {
     if (v.type === 'temp') {
      tempAt.push(v.url)
      //   formData.append('temp_attachment', `${temAtch?.join(',')}`)
     } else if (v.originFileObj) {
      formData.append(`attachment[${i}]`, v.originFileObj)
     }
    })
   } else if (key === 'unselected') {
    const element = values[key]
    element?.forEach((v, i) => {
     formData.append(`unselected[${i}]`, `${v}`)
    })
   } else if (key === 'property') {
    const pro = values[key] as TSendMailPro[]
    if (pro && pro.length > 0) {
     formData.append('property', JSON.stringify(pro))
    }
   } else if (key === 'prop_full_info') {
    formData.append('prop_full_info', JSON.stringify(values[key]))
   } else {
    values[key] && formData.append(`${key}`, `${values[key]}`)
   }
  }
 }
 if (tempAt && tempAt.length > 0) {
  formData.append('temp_attachment', `${tempAt?.join(',')}`)
 }
 return formData
}

export const convetUrl2FileList = (array?: any[]): UploadFile[] => {
 if (array && array.length > 0) {
  return array?.map((ta, taIndex) => ({
   uid: `temp-${taIndex}`,
   name: ta ? getImgData?.(ta)?.name : 'temp',
   url: ta,
   type: 'temp',
   thumbUrl: ta,
  }))
 }
 return []
}

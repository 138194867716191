import { Menu, MenuProps, Input, Dropdown, message } from 'antd'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import { BtnAnch, globalToaster } from 'components/elements/global'
import { useGetTextTemplateNamesQuery } from 'features/settings'
import { useGlobal } from 'raducer/global'
import { FC, useRef, useMemo } from 'react'

export const SSWInputArea: FC<{
 template?: boolean
 onChange?: (val?: any) => void
 value?: string
 onSelectTemp?: (val?: any) => void
 preconDisable?: boolean
}> = ({
 value = '',
 onSelectTemp,
 onChange,
 template = false,
 preconDisable,
}) => {
 const { data: textTemplates } = useGetTextTemplateNamesQuery(null, {
  skip: !template,
 })
 const inputRef = useRef<TextAreaRef>(null)
 const { global } = useGlobal()

 const templateMenus = useMemo(
  () =>
   textTemplates?.map((v) => ({
    label: v.name,
    key: v.id,
    onClick: () => onSelectTemp?.(v.id),
   })),
  [textTemplates],
 )

 const mergeMenus = useMemo(
  () =>
   global && global.body_mergefield && global.body_mergefield.length > 0
    ? global.body_mergefield.map((v) => ({
       label: v.name,
       key: v.id,
       onClick: () => {
        const ref = inputRef?.current
        ref?.focus()
        const start = value?.substring(
         0,
         ref?.resizableTextArea?.textArea.selectionEnd || 0,
        )
        const end = value?.substring(
         ref?.resizableTextArea?.textArea.selectionEnd || 0,
        )
        const text = start + v.id + end
        if (text.length <= 320) {
         onChange?.(text)
        } else {
         //  message.error('no')
        }
       },
      }))
    : [],
  [global, value],
 )

 return (
  <div className="ssw-text-area">
   <ul className={`sm-menubar ${preconDisable && 'disable'}`}>
    {template ? (
     <li>
      <BtnAnch
       onClick={() => {
        if (templateMenus?.length === 0) {
         globalToaster({ type: 'error', content: 'No SMS template available!' })
        }
       }}
      >
       <Dropdown
        trigger={['click']}
        overlay={
         <Menu selectable style={{ maxHeight: '300px', overflow: 'auto' }}>
          {templateMenus?.map((item, index) => (
           <Menu.Item onClick={item?.onClick} key={index} eventKey={item?.key}>
            {item?.label}
           </Menu.Item>
          ))}
         </Menu>
        }
       >
        <BtnAnch>Templates</BtnAnch>
       </Dropdown>
      </BtnAnch>
     </li>
    ) : null}

    <li>
     <Dropdown
      trigger={['click']}
      overlay={
       <Menu selectable style={{ maxHeight: '300px', overflow: 'auto' }}>
        {mergeMenus?.map((item, index) => (
         <Menu.Item onClick={item?.onClick} key={index} eventKey={item?.key}>
          {item?.label}
         </Menu.Item>
        ))}
       </Menu>
      }
     >
      <BtnAnch>Merge Field</BtnAnch>
     </Dropdown>
    </li>
   </ul>
   <Input.TextArea
    showCount
    maxLength={320}
    placeholder="Enter Message"
    ref={inputRef}
    onChange={(e) => onChange?.(e.target.value)}
    value={value}
    rows={6}
    disabled={preconDisable && true}
   />
  </div>
 )
}

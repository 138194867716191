import { Tag } from 'antd'
import { BaseButton } from 'components/elements/BaseButton'
import { globalConfirm } from 'components/elements/global'
import {
 useControlActionPlanMutation,
 useWithdrawActionPlanLeadMutation,
} from 'features/action-plan'
import { TPlanStatus, TPlanStatusPost } from 'features/leads/types'
import React, { FC, useCallback, useState } from 'react'

const ActionPlanBlockItem: FC<{
 id?: any
 leadId?: string
 status?: TPlanStatus
 text?: string
 isTransferred?: boolean
}> = ({ text, status = 'Pause', leadId, id, isTransferred }) => {
 const [actionControl] = useControlActionPlanMutation()
 const [newStatus, setStatus] = useState(status)
 const [deleteBulkActionPlan] = useWithdrawActionPlanLeadMutation()

 const handleCLickControl = useCallback(
  async (sts: TPlanStatusPost) => {
   globalConfirm({
    content: `Are you sure want to ${sts} this?`,
    onOk: async () => {
     await actionControl({
      lead_id: [leadId],
      action: sts,
      plan_id: id,
     }).unwrap()

     if (sts === 'Resume' || sts === 'Restart') {
      setStatus('Running')
     } else if (sts === 'Stop') {
      setStatus('Completed')
     } else if (sts === 'Pause') {
      setStatus('Pause')
     }

     //  setStatus(
     //   sts === 'Resume' || sts === 'Restart'
     //    ? 'Running'
     //    : sts === 'Stop'
     //    ? 'Completed'
     //    : 'Pause',
     //  )
    },
   })
  },
  [actionControl, id, leadId],
 )
 const DeleteActionPlan = () => {
  globalConfirm({
   content: 'Are you sure you want to delete the actionplan?',
   onOk: async () => {
    await deleteBulkActionPlan({
     lead_id: leadId,
     actionplan_id: id,
    }).unwrap()
   },
  })
 }

 return (
  <div className="item-block-li">
   <span className="content">{text}</span>
   <span>{status === 'Completed' ? <Tag color="red">{status}</Tag> : ''}</span>

   <span className="action" style={{ width: '140px' }}>
    {isTransferred && (
     <>
      {(newStatus === 'Running' || newStatus === 'Pause') && (
       <BaseButton
        onClick={() =>
         handleCLickControl(newStatus === 'Running' ? 'Pause' : 'Resume')
        }
        buttonStyle="outline"
        size="small"
        buttonType="success"
        icon={
         <i
          className={`fas fa-${newStatus === 'Running' ? 'pause' : 'play'}`}
         />
        }
       />
      )}
      {(newStatus === 'Running' || newStatus === 'Pause') && (
       <BaseButton
        onClick={() => handleCLickControl('Stop')}
        buttonStyle="outline"
        size="small"
        buttonType="warning"
        icon={<i className="fas fa-stop" />}
       />
      )}
      {(newStatus === 'Completed' || newStatus === 'Stop') && (
       <BaseButton
        buttonStyle="outline"
        size="small"
        onClick={() => handleCLickControl('Restart')}
        buttonType="info"
        icon={<i className="fas fa-undo" />}
       />
      )}

      <BaseButton
       buttonStyle="outline"
       size="small"
       onClick={DeleteActionPlan}
       buttonType="danger"
       icon={<i className="fas fa-trash" />}
      />
     </>
    )}
   </span>
  </div>
 )
}

export default ActionPlanBlockItem

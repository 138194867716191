import ListContentLayout from 'layout/ListContentLayout'
import React, { FC } from 'react'
import { PropertyList } from 'components/shared/Property'
import useFetchListing from 'hooks/useFetchListing'
import ProListingActionBlock from '../components/ProListingActionBlock'
import ProListingHeader from '../components/ProListingHeader'
import { TPropertyListing } from '../types'
import { useProListing } from '../provider/ProListingProvider'

const PreConListing: FC = () => {
 const { filterParams } = useProListing()
 const { flatData, isLoading, fetchMore, hasMore } =
  useFetchListing<TPropertyListing>('get-pre-condos', {
   params: `api_version=v3&${filterParams}`,
   listingType: 'preconstruction',
  })
 return (
  <ListContentLayout
   actions={<ProListingActionBlock />}
   title="Pre-Construction Listings"
   classNames={{ header: 'flex-wrap' }}
  >
   <div className="prolisting-page">
    <ProListingHeader />
    <PropertyList
     data={flatData}
     fetchMore={fetchMore}
     hasMore={hasMore}
     isLoading={isLoading}
     selection
     isPreCon
    />
   </div>
  </ListContentLayout>
 )
}

export default PreConListing

// import { Col, InputNumber, Row, Slider } from 'antd'
// import { FilterType } from 'features/property/routes/List'
// import { FC, useEffect, useState } from 'react'
// import { listingPriceRangerStep } from 'utils/functions'

// const PropertyPriceRanger: FC<{
//  min?: number
//  max?: number
//  step?: number
//  onChange?: (val?: { min?: any; max?: any }) => void
//  value?: { max?: number; min?: number }
//  filterState?: FilterType
// }> = ({ max = 1000, min = 0, onChange, value, step = 1000, filterState }) => {
//  const [minPrice, setMinPrice] = useState<any>(min ?? 0)
//  const [maxPrice, setMaxPrice] = useState<any>(max)
//  useEffect(() => {
//   if (value?.min) {
//    setMinPrice(value?.min)
//   }
//   if (value?.max) {
//    setMaxPrice(value?.max)
//   }
//  }, [value?.max, value?.min])

//  return (
//   <div className="price-ranger-filter">
//    <Row gutter={[15, 15]}>
//     <Col span={12}>
//      <InputNumber
//       disabled
//       value={`$ ${Number(minPrice ?? 0).toFixed()}`}
//       className="w-100"
//      />
//     </Col>
//     <Col span={12}>
//      <InputNumber
//       disabled
//       value={max == maxPrice ? 'Max' : `$ ${Number(maxPrice ?? 0).toFixed()}`}
//       className="w-100"
//      />
//     </Col>
//     <Col span={24}>
//      <Slider
//       //   step={listingPriceRangerStep(
//       //    filterState?.listing_type,
//       //    Number(maxPrice) - Number(minPrice),
//       //   )}
//       step={step}
//       marks={{
//        [min]: 'Min',
//        [max]: 'Max',
//       }}
//       value={[Number(minPrice ?? 0), Number(maxPrice ?? 0)]}
//       className="my-0"
//       //   tipFormatter={(value) => {
//       //    return value
//       //   }}
//       onChange={(val) => {
//        //    sliderChange?.(val)
//        onChange?.({ min: val[0], max: val[1] < max ? val[1] : '' })
//        setMinPrice(val[0])
//        setMaxPrice(val[1])
//       }}
//       min={min}
//       max={max}
//       range
//      />
//     </Col>
//    </Row>
//   </div>
//  )
// }

// export default PropertyPriceRanger

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import { Col, InputNumber, Row, Slider } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
// import { TListingStatus } from 'types'
import { utils } from 'utils'
import {
 convertPercentageToPrice,
 convertPriceToPercentage,
} from 'utils/functions'
// import { convertPercentageToPrice, convertPriceToPercentage } from './functions'

const PropertyPriceRanger: FC<{
 rangeMode?: 'Sale' | 'Lease'
 min?: number
 max?: number
 step?: number
 onChange?: (val?: { min?: any; max?: any }) => void
 value?: { max?: number; min?: number } | any
}> = ({ onChange, value, rangeMode = 'Sale' }) => {
 const [minPrice, setMinPrice] = useState<any>()
 const [maxPrice, setMaxPrice] = useState<any>()

 const range = useMemo(
  () =>
   rangeMode === 'Lease' ? { min: 0, max: 10000 } : { min: 0, max: 5000000 },
  [rangeMode],
 )
 const convetPer = useCallback(
  (val: number) => {
   return convertPriceToPercentage(val, rangeMode)
  },
  [rangeMode],
 )

 useEffect(() => {
  if (value || range.min) {
   setMinPrice(value?.min || range.min)
  }
 }, [value?.min, value, range.min])

 useEffect(() => {
  setMaxPrice(value?.max !== '' ? value?.max : range.max)
 }, [value?.max, range.max])

 const markes = useMemo(() => {
  if (rangeMode === 'Lease') {
   return {
    [convetPer(1000)]: '1K',
    [convetPer(5000)]: '5K',
    [convetPer(3000)]: '3K',
   }
  }
  return {
   [convetPer(200000)]: '200K',
   [convetPer(550000)]: '550K',
   [convetPer(950000)]: '950K',
   [convetPer(2000000)]: '2M',
   [convetPer(4000000)]: '4M',
  }
 }, [convetPer, rangeMode])

 const handleChangeRange = useCallback(
  (num: number[]) => {
   const minVal =
    num[0] > num[1] ? num[1] : convertPercentageToPrice(num[0], rangeMode)
   const maxVal = convertPercentageToPrice(num[1], rangeMode)
   if (minVal < maxVal) {
    onChange?.({ min: minVal, max: maxVal })
    setMaxPrice(maxVal)
    setMinPrice(minVal)
   }
  },
  [rangeMode],
 )

 return (
  <div>
   <Row gutter={[15, 15]}>
    <Col span={12}>
     <InputNumber
      min="0"
      max={`${maxPrice - 1}`}
      onChange={(v) => {
       const minVal = Number(v) > range.max ? range.max : v
       setMinPrice(v)
       onChange?.({ max: maxPrice, min: minVal })
      }}
      value={utils.pricefy(Number(minPrice ?? 0).toFixed())}
      className="w-100"
      controls={false}
     />
    </Col>
    <Col span={12}>
     <InputNumber
      min="0"
      onChange={(v) => {
       const maxVal = Number(v) > range.max ? range.max : v
       setMaxPrice(v)
       onChange?.({ max: maxVal, min: minPrice })
      }}
      value={utils.pricefy(Number(maxPrice ?? 0).toFixed())}
      //   value={`${
      //    maxPrice == 5000000 && rangeMode === 'Sale'
      //     ? 'Max'
      //     : maxPrice == 10000 && rangeMode === 'Lease'
      //     ? 'Max'
      //     : utils.pricefy(Number(maxPrice ?? 0).toFixed())
      //   }`}
      className="w-100"
      max={`${range.max}`}
      controls={false}
     />
    </Col>
    <Col span={24}>
     <Slider
      marks={{
       [convertPriceToPercentage(range.min, rangeMode)]: 'Min',
       ...markes,
       [convertPriceToPercentage(range.max, rangeMode)]: 'Max',
      }}
      value={[
       convertPriceToPercentage(minPrice, rangeMode),
       convertPriceToPercentage(maxPrice, rangeMode),
      ]}
      className="my-0"
      onChange={handleChangeRange}
      max={rangeMode === 'Lease' ? 70 : 40}
      min={0}
      range
      //   tooltip={}
      tooltipVisible={false}
     />
    </Col>
   </Row>
  </div>
 )
}

export default PropertyPriceRanger

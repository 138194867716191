import InnerContentLayout from 'layout/InnerContentLayout'
import React, { FC } from 'react'
import ActionPlanForm from '../components/ActionPlanForm'
import ActionFormProvider from '../providers/ActionFormProvider'

const Add: FC<{ onCancel: () => void }> = ({ onCancel }) => (
 //  <InnerContentLayout title="Add Action Plan">
 <ActionFormProvider>
  <ActionPlanForm onCancel={onCancel} />
 </ActionFormProvider>
 //  </InnerContentLayout>
)

export default Add
